"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmenityCategoryTypes = void 0;
var AmenityCategoryTypes;
(function (AmenityCategoryTypes) {
    AmenityCategoryTypes["alcoholics_beverages"] = "Alcoholics Beverages";
    AmenityCategoryTypes["beverages"] = "Beverages";
    AmenityCategoryTypes["food"] = "Food";
    AmenityCategoryTypes["gift"] = "Gift";
    AmenityCategoryTypes["snacks"] = "Snacks";
    AmenityCategoryTypes["utils"] = "Utils";
    AmenityCategoryTypes["other"] = "Other";
})(AmenityCategoryTypes = exports.AmenityCategoryTypes || (exports.AmenityCategoryTypes = {}));
