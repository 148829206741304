"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteStore = void 0;
var mobx_1 = require("mobx");
var moment = __importStar(require("moment"));
var RoutesPoint_cs_1 = require("../../cs/booking/RoutesPoint.cs");
var RouteStore = /** @class */ (function () {
    //#endregion
    function RouteStore() {
        var _this = this;
        this.setRoutesPoint = function (value) {
            if (value === void 0) { value = []; }
            _this.routesPoint = value;
        };
        this.setShowMap = function (value) {
            _this.showMap = value;
        };
        // TODO optimize this method
        this.setBookingRoutePoint = function (item, index) {
            try {
                _this.routesPoint[index] = item;
            }
            catch (e) {
                console.log("BookingAddRoutePointException: ".concat(JSON.stringify(e)));
            }
        };
        //#endregion
        //#region GETTERS
        this.getBookingStartRoutePoint = function () {
            return _this.routesPoint.shift();
        };
        this.getBookingEndRoutePoint = function () {
            return _this.routesPoint.pop();
        };
        //#endregion
        //#region METHODS
        this.resetData = function () {
            _this.setRoutesPoint(_this.getNewRoutePoints(2));
            if (_this.map) {
                _this.map.resetMapAllMarkers();
                _this.map.resetDirectionsDisplay();
            }
        };
        this.shiftPickupDropoff = function (callback) {
            if (callback === void 0) { callback = null; }
            var start = _this.getBookingStartRoutePoint();
            var end = _this.getBookingEndRoutePoint();
            _this.routesPoint.unshift(end);
            _this.routesPoint.push(start);
            if (callback !== null) {
                callback();
            }
        };
        this.addNewRoutesPoint = function () {
            _this.routesPoint[_this.routesPoint.length - 1] = __assign(__assign({}, _this.routesPoint[_this.routesPoint.length - 1]), { flightDepartureTimeDropoff: '', flightArrivalTimeDropoff: '' });
            var newRoutesPoint = __assign({}, new RoutesPoint_cs_1.RoutesPoint({ _id: _this.getId() }));
            _this.routesPoint.push(newRoutesPoint);
        };
        this.getId = function () {
            ++_this.itemIdx;
            var id = moment.default(new Date()).format('YYYYMMDDhhmmssSSSS') + "".concat(_this.itemIdx);
            var pos = Math.pow(10, id.toString().length % 3);
            id = id + "".concat(pos > 0 ? pos : '');
            return window.btoa(id.toString());
        };
        this.getNewRoutePoints = function (quantity) {
            if (quantity === void 0) { quantity = 1; }
            var list = [];
            while (quantity !== 0) {
                list.push(__assign({}, new RoutesPoint_cs_1.RoutesPoint({ _id: _this.getId() })));
                --quantity;
            }
            return list;
        };
        this.getPickupAsAvailabilityInput = function (pickup_time) {
            var _a = __assign({}, _this.routesPoint[0]), current_location = _a.drop_location, pickup_latitude = _a.drop_latitude, pickup_longitude = _a.drop_longitude, iataPickup = _a.iataDropoff, cityNamePickup = _a.cityNameDropoff, cityCodePickup = _a.cityCodeDropoff, postalCodePickup = _a.postalCodeDropoff, airlineCodePickup = _a.airlineCodeDropoff, isAirportPickup = _a.isAirportDropoff, flightNumberPickup = _a.flightNumberDropoff, placeIdPickup = _a.placeIdDropoff, flightArrivalTimePickup = _a.flightArrivalTimeDropoff, flightDepartureTimePickup = _a.flightDepartureTimeDropoff;
            return {
                current_location: current_location,
                pickup_latitude: pickup_latitude,
                pickup_longitude: pickup_longitude,
                iataPickup: iataPickup,
                cityNamePickup: cityNamePickup,
                cityCodePickup: cityCodePickup,
                postalCodePickup: postalCodePickup,
                airlineCodePickup: airlineCodePickup,
                isAirportPickup: isAirportPickup,
                flightNumberPickup: flightNumberPickup,
                placeIdPickup: placeIdPickup,
                flightArrivalTimePickup: flightArrivalTimePickup,
                flightDepartureTimePickup: flightDepartureTimePickup,
                pickup_time: pickup_time,
            };
        };
        this.getDropOffAsAvailabilityInput = function () {
            var _a = __assign({}, _this.routesPoint[_this.routesPoint.length - 1]), drop_location = _a.drop_location, drop_latitude = _a.drop_latitude, drop_longitude = _a.drop_longitude, iataDropoff = _a.iataDropoff, cityNameDropoff = _a.cityNameDropoff, cityCodeDropoff = _a.cityCodeDropoff, postalCodeDropoff = _a.postalCodeDropoff, airlineCodeDropoff = _a.airlineCodeDropoff, isAirportDropoff = _a.isAirportDropoff, flightNumberDropoff = _a.flightNumberDropoff, placeIdDropoff = _a.placeIdDropoff, flightArrivalTimeDropoff = _a.flightArrivalTimeDropoff, flightDepartureTimeDropoff = _a.flightDepartureTimeDropoff;
            return {
                drop_location: drop_location,
                drop_latitude: drop_latitude,
                drop_longitude: drop_longitude,
                iataDropoff: iataDropoff,
                cityNameDropoff: cityNameDropoff,
                cityCodeDropoff: cityCodeDropoff,
                postalCodeDropoff: postalCodeDropoff,
                airlineCodeDropoff: airlineCodeDropoff,
                isAirportDropoff: isAirportDropoff,
                flightNumberDropoff: flightNumberDropoff,
                placeIdDropoff: placeIdDropoff,
                flightArrivalTimeDropoff: flightArrivalTimeDropoff,
                flightDepartureTimeDropoff: flightDepartureTimeDropoff,
            };
        };
        this.getHalfWayPointsAsAvailabilityInput = function () {
            var routesPoints = __spreadArray([], routeStore.routesPoint, true);
            routesPoints.shift();
            routesPoints.pop();
            return routesPoints.map(function (point) {
                var drop_location = point.drop_location, drop_latitude = point.drop_latitude, drop_longitude = point.drop_longitude, iataDropoff = point.iataDropoff, cityNameDropoff = point.cityNameDropoff, cityCodeDropoff = point.cityCodeDropoff, postalCodeDropoff = point.postalCodeDropoff, airlineCodeDropoff = point.airlineCodeDropoff, isAirportDropoff = point.isAirportDropoff, flightNumberDropoff = point.flightNumberDropoff, placeIdDropoff = point.placeIdDropoff;
                return {
                    drop_location: drop_location,
                    drop_latitude: drop_latitude,
                    drop_longitude: drop_longitude,
                    iataDropoff: iataDropoff,
                    cityNameDropoff: cityNameDropoff,
                    cityCodeDropoff: cityCodeDropoff,
                    postalCodeDropoff: postalCodeDropoff,
                    airlineCodeDropoff: airlineCodeDropoff,
                    isAirportDropoff: isAirportDropoff,
                    flightNumberDropoff: flightNumberDropoff,
                    placeIdDropoff: placeIdDropoff,
                };
            });
        };
        this.updateAirline = function (field, code) {
            var position = field === 'airlineCodeDropoff' ? _this.routesPoint.length - 1 : 0;
            var point = _this.routesPoint[position];
            _this.routesPoint[position] = __assign(__assign({}, point), { airlineCodeDropoff: code });
        };
        this.updateFlightNumber = function (field, flightNumber) {
            var position = field === 'flightNumberDropoff' ? _this.routesPoint.length - 1 : 0;
            var point = _this.routesPoint[position];
            _this.routesPoint[position] = __assign(__assign({}, point), { flightNumberDropoff: flightNumber });
        };
        this.removeRoutesPoint = function (index) {
            if (_this.routesPoint.length === 2 && index <= 1) {
                _this.setBookingRoutePoint(__assign({}, new RoutesPoint_cs_1.RoutesPoint({ _id: _this.getId() })), index);
            }
            else {
                _this.routesPoint.splice(index, 1);
            }
        };
        this.clearIntermediateRoutesPoints = function () {
            var start = _this.getBookingStartRoutePoint();
            var end = _this.getBookingEndRoutePoint();
            _this.setRoutesPoint([start, end]);
        };
        this.clearDepartureArrivalTime = function () {
            _this.setBookingRoutePoint(__assign(__assign({}, _this.routesPoint[0]), { flightDepartureTimeDropoff: '', flightArrivalTimeDropoff: '' }), 0);
            _this.setBookingRoutePoint(__assign(__assign({}, _this.routesPoint[_this.routesPoint.length - 1]), { flightDepartureTimeDropoff: '', flightArrivalTimeDropoff: '' }), _this.routesPoint.length - 1);
        };
        this.showMap = false;
        this.itemIdx = 0;
        this.routesPoint = this.getNewRoutePoints(2);
        this._fromView = 'DISPATCH';
    }
    Object.defineProperty(RouteStore.prototype, "hasRoutePoints", {
        //#region SETTERS
        get: function () {
            return this.routesPoint.length >= 2;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RouteStore.prototype, "map", {
        get: function () {
            return this._map;
        },
        set: function (value) {
            this._map = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RouteStore.prototype, "fromView", {
        get: function () {
            return this._fromView;
        },
        set: function (value) {
            this._fromView = value;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.shallow
    ], RouteStore.prototype, "routesPoint", void 0);
    __decorate([
        mobx_1.observable
    ], RouteStore.prototype, "showMap", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "setRoutesPoint", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "setShowMap", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "setBookingRoutePoint", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "getBookingStartRoutePoint", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "getBookingEndRoutePoint", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "shiftPickupDropoff", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "addNewRoutesPoint", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "updateAirline", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "updateFlightNumber", void 0);
    __decorate([
        mobx_1.action
    ], RouteStore.prototype, "removeRoutesPoint", void 0);
    return RouteStore;
}());
exports.RouteStore = RouteStore;
var routeStore = new RouteStore();
exports.default = routeStore;
