"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var React = __importStar(require("react"));
var mobx_1 = require("mobx");
var mobx_react_1 = require("mobx-react");
var layout_helpers_1 = require("../../helpers/layout.helpers");
require("./UploadAvatar.less");
var react_i18next_1 = require("react-i18next");
var UploadAvatar = /** @class */ (function (_super) {
    __extends(UploadAvatar, _super);
    function UploadAvatar(props) {
        var _this_1 = _super.call(this, props) || this;
        _this_1.normFile = function (e) {
            if (Array.isArray(e)) {
                return e;
            }
            return e && e.fileList;
        };
        _this_1.beforeUpload = function (file) {
            var t = _this_1.props.t;
            var isJPG = file.type === "image/jpeg";
            var isPNG = file.type === "image/png";
            if (!isJPG && !isPNG) {
                antd_1.message.error(t("manage_fleets.You can only upload JPG or PNG file"));
            }
            var isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                antd_1.message.error(t("manage_fleets.Image must smaller than 2MB"));
            }
            return (isJPG || isPNG) && isLt2M;
        };
        _this_1.nemt_check = function (value) {
            _this_1.setIsNemt(value);
            _this_1.props.onChangeNemt(value);
        };
        _this_1.validateRequire = function (rule, value, callback) {
            var _this = _this_1;
            setTimeout(function () {
                if (_this.props.isRequired &&
                    (String(_this.props.form.getFieldValue(_this.props.propertyName)) ===
                        "" ||
                        !_this.props.form.getFieldValue(_this.props.propertyName))) {
                    callback([
                        new Error(_this_1.props.t("manage_fleets.Please select any jpg, jpeg or png picture")),
                    ]);
                }
                else {
                    callback();
                }
            }, 3000);
        };
        _this_1.handleChange = function (info) {
            if (info.file.status === "uploading") {
                _this_1.setState({ loading: true });
                return;
            }
            if (info.file.status === "done") {
                // Get this url from response in real world.
                _this_1.getBase64(info.file.originFileObj, function (imageUrl) {
                    var _a;
                    _this_1.props.handleUpdatePicture(imageUrl);
                    _this_1.props.form.setFields((_a = {},
                        _a["upload_".concat(_this_1.props.propertyName)] = {
                            errors: [],
                        },
                        _a));
                    _this_1.setState({
                        loading: false,
                    });
                });
            }
        };
        _this_1.changeUser = function (i) {
            var _a;
            var disableAvatar = _this_1.props.disabled ? _this_1.props.disabled : false;
            if (!disableAvatar) {
                var img = _this_1.props.isAvatar ? "src/images/avatars/avatar_".concat(i, ".png") : "src/images/car_".concat(i, ".png");
                _this_1.props.form.setFields((_a = {},
                    _a["upload_".concat(_this_1.props.propertyName)] = {
                        errors: [],
                    },
                    _a));
                _this_1.setState({
                    loading: false,
                });
                _this_1.convertToBase64(img, function (image) {
                    _this_1.props.handleUpdatePicture(image);
                });
            }
        };
        _this_1.isNemt = false;
        _this_1.state = {
            loading: false,
        };
        return _this_1;
    }
    UploadAvatar.prototype.setIsNemt = function (value) {
        this.isNemt = value;
    };
    UploadAvatar.prototype.getBase64 = function (img, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    UploadAvatar.prototype.convertToBase64 = function (imgPath, callback) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", imgPath, true);
        xhr.responseType = "blob";
        xhr.onload = function (e) {
            console.log(this.response);
            var reader = new FileReader();
            reader.onload = function (event) {
                callback(event.target.result);
            };
            var file = this.response;
            reader.readAsDataURL(file);
        };
        xhr.send();
    };
    UploadAvatar.prototype.render = function () {
        var _this_1 = this;
        var FormItem = antd_1.Form.Item;
        this.props.checkedNempt
            ? this.setIsNemt(this.props.checkedNempt)
            : this.setIsNemt(false);
        var layout = this.props.formItemLayout || {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
        };
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var t = this.props.t;
        var uploadButton = (React.createElement("div", null,
            React.createElement(antd_1.Icon, { type: this.state.loading ? "loading" : "plus" }),
            React.createElement("div", { className: "ant-upload-text" }, t("manage_fleets.Upload"))));
        var imageUrl = this.props.profile_picture;
        var images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        var fileUpStyle = this.props.fileUploadStyle || {};
        return (React.createElement("div", { className: "upload-avatar" },
            React.createElement(antd_1.Row, null,
                React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([8, 8])),
                    React.createElement(FormItem, null, getFieldDecorator(this.props.propertyName || "profile_picture", {
                        initialValue: imageUrl,
                    })(React.createElement(antd_1.Input, { type: "hidden" }))),
                    React.createElement(FormItem, __assign({ style: __assign({}, fileUpStyle) }, layout, { label: this.props.label, extra: this.props.extra }), getFieldDecorator(this.props.propertyName
                        ? "upload_".concat(this.props.propertyName)
                        : "upload", {
                        getValueFromEvent: this.normFile,
                        valuePropName: "fileList",
                        rules: [
                            {
                                validator: this.validateRequire,
                            },
                        ],
                    })(React.createElement(antd_1.Upload, { name: this.props.propertyName ? this.props.propertyName : "avatar", listType: "picture-card", className: "avatar-uploader", showUploadList: false, beforeUpload: this.beforeUpload, onChange: this.handleChange, disabled: this.props.disabled ? this.props.disabled : false }, imageUrl ? (React.createElement("img", { src: imageUrl, alt: "avatar", style: { width: 70, height: 70 } })) : (uploadButton))))),
                React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([16, 16])),
                    React.createElement("div", { className: "avatar-list" },
                        React.createElement("h3", null, t(this.props.isAvatar ? "manage_fleets.Driver Images" : "manage_fleets.Fleet Images")),
                        React.createElement("div", { className: "list-images" }, images.map(function (i) {
                            return (React.createElement("a", { key: i, onClick: function () { return _this_1.changeUser(i); } },
                                React.createElement(antd_1.Avatar, { className: "image", src: _this_1.props.isAvatar ? "src/images/avatars/avatar_".concat(i, ".png") : "src/images/car_".concat(i, ".png") })));
                        })))))));
    };
    __decorate([
        mobx_1.observable
    ], UploadAvatar.prototype, "isNemt", void 0);
    __decorate([
        mobx_1.action
    ], UploadAvatar.prototype, "setIsNemt", null);
    UploadAvatar = __decorate([
        mobx_react_1.observer
    ], UploadAvatar);
    return UploadAvatar;
}(React.Component));
exports.default = (0, react_i18next_1.withNamespaces)("pages")(UploadAvatar);
