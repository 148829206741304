"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyCommission = void 0;
var CompanyCommission = /** @class */ (function () {
    function CompanyCommission(item) {
        if (item === void 0) { item = {}; }
        var commissionablePercent = item.commissionablePercent, driverCommission = item.driverCommission, driverCommissionPercent = item.driverCommissionPercent, dispatcherCommission = item.dispatcherCommission, dispatcherCommissionPercent = item.dispatcherCommissionPercent, dispatcherCommissionMarkUp = item.dispatcherCommissionMarkUp, dispatcherCommissionMarkUpPercent = item.dispatcherCommissionMarkUpPercent, dispatcherCommissionMarkUpPercentType = item.dispatcherCommissionMarkUpPercentType, rezgloCommission = item.rezgloCommission, rezgloCommissionPercent = item.rezgloCommissionPercent, rezgloCommissionMarkUp = item.rezgloCommissionMarkUp, rezgloCommissionMarkUpPercent = item.rezgloCommissionMarkUpPercent, rezgloCommissionMarkUpPercentType = item.rezgloCommissionMarkUpPercentType, serviceChargeCommission = item.serviceChargeCommission, serviceChargeCommissionPercent = item.serviceChargeCommissionPercent;
        this.commissionablePercent = commissionablePercent;
        this.driverCommission = driverCommission || false;
        this.driverCommissionPercent = driverCommissionPercent;
        this.dispatcherCommission = dispatcherCommission || false;
        this.dispatcherCommissionPercent = dispatcherCommissionPercent;
        this.dispatcherCommissionMarkUp = dispatcherCommissionMarkUp;
        this.dispatcherCommissionMarkUpPercent = dispatcherCommissionMarkUpPercent;
        this.dispatcherCommissionMarkUpPercentType = dispatcherCommissionMarkUpPercentType;
        this.rezgloCommission = rezgloCommission || false;
        this.rezgloCommissionPercent = rezgloCommissionPercent;
        this.rezgloCommissionMarkUp = rezgloCommissionMarkUp;
        this.rezgloCommissionMarkUpPercent = rezgloCommissionMarkUpPercent;
        this.rezgloCommissionMarkUpPercentType = rezgloCommissionMarkUpPercentType;
        this.serviceChargeCommission = serviceChargeCommission || false;
        this.serviceChargeCommissionPercent = serviceChargeCommissionPercent;
    }
    return CompanyCommission;
}());
exports.CompanyCommission = CompanyCommission;
