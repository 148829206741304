"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var franchise_model_1 = require("./franchise.model");
var apollo_boost_1 = require("apollo-boost");
var ModelModel = /** @class */ (function (_super) {
    __extends(ModelModel, _super);
    function ModelModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ModelModel.prototype.manageList = function (filter, franchiseIds) {
        var schema = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      query($filter: ListModelInput, $franchiseIds: [Int]) {\n        PublicListModel(filter: $filter, franchiseIds: $franchiseIds) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            model_status\n            model_name\n            model_size\n            hasPerMiles\n            franchise {\n              ...Franchise\n            }\n            hasCampaign\n            amountSuitcases\n            passengerAmountSuitcases\n            faresFixed {\n              _id\n            }\n            hasFixed\n            orderWeight\n          }\n        }\n      }\n      ", "\n    "], ["\n      query($filter: ListModelInput, $franchiseIds: [Int]) {\n        PublicListModel(filter: $filter, franchiseIds: $franchiseIds) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            model_status\n            model_name\n            model_size\n            hasPerMiles\n            franchise {\n              ...Franchise\n            }\n            hasCampaign\n            amountSuitcases\n            passengerAmountSuitcases\n            faresFixed {\n              _id\n            }\n            hasFixed\n            orderWeight\n          }\n        }\n      }\n      ", "\n    "])), franchise_model_1.FranchiseFragment);
        return this.onboardingQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    ModelModel.prototype.list = function (filter, franchiseIds) {
        var schema = (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      query($filter: JsonParser, $franchiseIds: [Int], $token: String) {\n        PublicListModelCompany(filter: $filter, franchiseIds: $franchiseIds, token: $token) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            model_name\n            model_status\n          }\n        }\n      }\n    "], ["\n      query($filter: JsonParser, $franchiseIds: [Int], $token: String) {\n        PublicListModelCompany(filter: $filter, franchiseIds: $franchiseIds, token: $token) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            model_name\n            model_status\n          }\n        }\n      }\n    "])));
        return this.publicQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    ModelModel.prototype.show = function (id) {
        var schema = (0, apollo_boost_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      query($id: Float!, $token: String) {\n        PublicGetModel(_id: $id, token: $token) {\n          model_size\n          hasPerMiles\n          hasFixed\n          franchise {\n            ...Franchise\n          }\n          amountSuitcases\n          passengerAmountSuitcases\n          _id\n          model_name\n          model_status\n          model_thumb_image\n          model_thumb_act_image\n          model_image\n          android_focus_model_image\n          android_unfocus_model_image\n          ios_focus_model_image\n          ios_unfocus_model_image\n          ios_top_view_image\n          android_top_view_image\n        }\n      }\n      ", "\n    "], ["\n      query($id: Float!, $token: String) {\n        PublicGetModel(_id: $id, token: $token) {\n          model_size\n          hasPerMiles\n          hasFixed\n          franchise {\n            ...Franchise\n          }\n          amountSuitcases\n          passengerAmountSuitcases\n          _id\n          model_name\n          model_status\n          model_thumb_image\n          model_thumb_act_image\n          model_image\n          android_focus_model_image\n          android_unfocus_model_image\n          ios_focus_model_image\n          ios_unfocus_model_image\n          ios_top_view_image\n          android_top_view_image\n        }\n      }\n      ", "\n    "])), franchise_model_1.FranchiseFragment);
        return this.publicQuery(schema, { id: id });
    };
    ModelModel.prototype.store = function (input, franchiseIds) {
        var schema = "\n    mutation($input: ModelInput!, $franchiseIds: [Int]) {\n      CreateModel(input: $input, franchiseIds: $franchiseIds ){\n          _id\n          model_size\n          hasPerMiles\n          hasFixed\n          model_name\n          model_status\n      }\n    }\n  ";
        return this.mutate(schema, { input: input, franchiseIds: franchiseIds });
    };
    ModelModel.prototype.update = function (input, franchiseIds) {
        var schema = "\n    mutation($input: UpdateModelInput!, $franchiseIds: [Int]){\n      UpdateModel(input: $input, franchiseIds: $franchiseIds){\n        pageNumber\n        pageSize\n      }\n    }\n  ";
        return this.mutate(schema, { input: input, franchiseIds: franchiseIds });
    };
    ModelModel.prototype.updateBulk = function (input, filter) {
        var schema = "\n      mutation ($input: UpdateModelBulkInput!, $filter: ListModelInput!){\n        UpdateModelBulk(input: $input, filter: $filter){\n          count\n        }\n      }\n    ";
        return this.mutate(schema, { input: input, filter: filter });
    };
    ModelModel.prototype.makeACopy = function (id_origin) {
        var schema = "\n    mutation($id_origin: Float!){\n      CopyModel(id_origin:$id_origin){\n        _id\n      }\n    }\n    ";
        return this.mutate(schema, { id_origin: id_origin });
    };
    return ModelModel;
}(BaseModel_1.BaseModel));
var modelModels = new ModelModel();
exports.default = modelModels;
var templateObject_1, templateObject_2, templateObject_3;
