"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var AmenityModel = /** @class */ (function (_super) {
    __extends(AmenityModel, _super);
    function AmenityModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AmenityModel.prototype.list = function (config) {
        var schema = "\n      query($filter:JsonParser){\n  PublicAmenityList(filter:$filter){\n    pageNumber\n    pageSize\n    count\n    items {\n      _id\n      key:_id\n      amenity_image\n      amenity_category\n      amenity_name\n      status\n      description\n      amenity_image\n      minimum_amount\n      maximum_amount\n      is_nemt\n      amenity_fare\n      is_default\n      __typename\n    }\n    __typename\n  }\n}";
        return this.onboardingQuery(schema, config);
    };
    AmenityModel.prototype.list_nemt_default = function (config) {
        var schema = "query($filter:JsonParser,$filternemt:JsonParser){\n  PublicAmenityList(filter:$filter){\n    pageNumber\n    pageSize\n    count\n    items {\n      _id\n      key:_id\n      amenity_category\n      amenity_name\n      amenity_image\n      description\n      minimum_amount\n      maximum_amount\n      is_nemt\n      amenity_fare\n    }\n  },\n  AmenityListNemt:PublicAmenityList(filter:$filternemt){\n    pageNumber\n    pageSize\n    count\n    items {\n      _id\n      amenity_category\n      amenity_name\n      amenity_image\n      description\n      minimum_amount\n      maximum_amount\n      is_nemt\n      amenity_fare\n    }\n  }\n}";
        return this.onboardingQuery(schema, config);
    };
    AmenityModel.prototype.store = function (input) {
        var schema = "\n        mutation ($input: AmenityInput!){\n            AmenityStorage: PublicAmenityStorage(input: $input){\n                _id\n            }\n        }\n    ";
        return this.onboardingMutation(schema, { input: input });
    };
    AmenityModel.prototype.get = function (input) {
        var schema = "\n        mutation ($input: JsonParser){\n             AmenityGet(input:$input){\n                        _id\n                        amenity_image\n                        amenity_category\n                        amenity_name\n                        description\n                        minimum_amount\n                        maximum_amount\n                        is_nemt\n                        is_default\n                        amenity_fare\n                        status\n               }                   \n        }\n    ";
        return this.mutate(schema, { input: input });
    };
    AmenityModel.prototype.updateBulk = function (filter, data) {
        var schema = "\n        mutation ($filter: JsonParser, $data: JsonParser){\n          AmenityUpdate(filter: $filter, data: $data){\n            count\n          }\n        }\n      ";
        return this.mutate(schema, { filter: filter, data: data });
    };
    AmenityModel.prototype.export = function (format, filter) {
        var schema = "\n      query($format: CoreDocumentFormatEnum!, $filter:  JsonParser!){\n        AmenityListExport(format: $format, filter: $filter){\n        url\n        }\n      }\n    ";
        return this.query(schema, { format: format, filter: filter });
    };
    AmenityModel.prototype.showCountryStateCity = function (input) {
        var schema = "\n    query($input: Int!){\n      CompanyGet(input: $input){\n         _id\n         code\n          companydetails{\n            countryObj{\n              country_name\n            }\n            cityObj{\n                city_name\n              }\n            stateObj{\n              state_name\n            } \n          }    \n      }\n    }\n      ";
        return this.query(schema, { input: input });
    };
    return AmenityModel;
}(BaseModel_1.BaseModel));
var amenityModel = new AmenityModel();
exports.default = amenityModel;
