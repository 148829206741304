"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTimeFormatEnum = void 0;
var DateTimeFormatEnum;
(function (DateTimeFormatEnum) {
    DateTimeFormatEnum["Y_M_D_H_I_S"] = "Y_M_D_H_I_S";
    DateTimeFormatEnum["YMD_HIS"] = "YMD_HIS";
    DateTimeFormatEnum["D_M_Y_H_I_S"] = "D_M_Y_H_I_S";
    DateTimeFormatEnum["DMY_HIS"] = "DMY_HIS";
    DateTimeFormatEnum["DdM_Y_H_I_S_A"] = "DdM_Y_H_I_S_A";
})(DateTimeFormatEnum = exports.DateTimeFormatEnum || (exports.DateTimeFormatEnum = {}));
