"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var semantic_ui_react_1 = require("semantic-ui-react");
var antd_1 = require("antd");
require("./SiteOptions.less");
var mobx_react_1 = require("mobx-react");
var react_i18next_1 = require("react-i18next");
var SiteOptions = (0, mobx_react_1.inject)(function (stores) { return ({
    onboardingStore: stores.onboardingCompanyStore,
}); })(function (_a) {
    var onboardingStore = _a.onboardingStore, lng = _a.lng;
    return (React.createElement(antd_1.Row, { className: "rzg-site-options" },
        React.createElement(semantic_ui_react_1.Menu, { icon: true, vertical: true, color: "grey" },
            React.createElement(semantic_ui_react_1.Dropdown, { item: true, icon: React.createElement(semantic_ui_react_1.Icon, { size: 'large', name: 'language' }) },
                React.createElement(semantic_ui_react_1.Dropdown.Menu, { direction: "left" },
                    React.createElement(semantic_ui_react_1.Dropdown.Item, { active: lng == 'en', flag: "gb", text: 'English', onClick: function () { onboardingStore.setSiteLanguage('en'); } }),
                    React.createElement(semantic_ui_react_1.Dropdown.Item, { active: lng == 'es', flag: "es", text: 'Spanish', onClick: function () { onboardingStore.setSiteLanguage('es'); } }))))));
});
exports.default = (0, react_i18next_1.withNamespaces)('pages')(SiteOptions);
