"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var React = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var styles = require("./UploadImage.scss");
var UploadImage = /** @class */ (function (_super) {
    __extends(UploadImage, _super);
    function UploadImage(props) {
        var _this = _super.call(this, props) || this;
        _this.normFile = function (e) {
            if (Array.isArray(e)) {
                return e;
            }
            return e && e.fileList;
        };
        _this.beforeUpload = function (file) {
            var t = _this.props.t;
            var isJPG = file.type === "image/jpeg";
            var isPNG = file.type === "image/png";
            if (!isJPG && !isPNG) {
                antd_1.message.error(t("manage_fleets.You can only upload JPG or PNG file"));
            }
            var isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                antd_1.message.error(t("manage_fleets.Image must smaller than 2MB"));
            }
            return (isJPG || isPNG) && isLt2M;
        };
        _this.handleChange = function (info) {
            if (info.file.status === "uploading") {
                _this.setState({ loading: true });
                return;
            }
            if (info.file.status === "done") {
                // Get this url from response in real world.
                _this.getBase64(info.file.originFileObj, function (imageUrl) {
                    _this.props.handleUpdatePicture(String(imageUrl));
                    _this.setState({
                        loading: false,
                    });
                });
            }
        };
        _this.validateRequire = function (rule, value, callback) {
            var t = _this.props.t;
            setTimeout(function () {
                if (_this.props.isRequired &&
                    String(_this.props.form.getFieldValue(_this.props.propertyName)) === "") {
                    callback([
                        new Error(t("manage_fleets.Please select any jpg, jpeg or png picture")),
                    ]);
                }
                else {
                    callback();
                }
            }, 2000);
        };
        _this.state = {
            loading: false,
        };
        return _this;
    }
    UploadImage.prototype.getBase64 = function (img, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    UploadImage.prototype.render = function () {
        var FormItem = antd_1.Form.Item;
        var formItemLayoutUpload = this.props.formItemLayout || {
            labelCol: { span: 16 },
            wrapperCol: { span: 8 },
        };
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var t = this.props.t;
        var uploadButton = (React.createElement("div", null,
            React.createElement(antd_1.Icon, { type: this.state.loading ? "loading" : "plus" }),
            React.createElement("div", { className: styles["ant-upload-text"] }, t("manage_fleets.Upload"))));
        var imageUrl = this.props.image;
        return (React.createElement("div", { className: styles[this.props.isRequired ? "upload-avatar" : ""] },
            React.createElement(FormItem, null, getFieldDecorator(this.props.propertyName, {
                initialValue: imageUrl,
            })(React.createElement(antd_1.Input, { type: "hidden" }))),
            React.createElement(FormItem, __assign({}, formItemLayoutUpload, { label: this.props.label, extra: this.props.extra }), getFieldDecorator("upload_".concat(this.props.propertyName), {
                getValueFromEvent: this.normFile,
                valuePropName: "fileList",
                rules: [
                    {
                        validator: this.validateRequire,
                    },
                ],
            })(React.createElement(antd_1.Upload, { name: this.props.propertyName, listType: "picture-card", className: "avatar-uploader", showUploadList: false, beforeUpload: this.beforeUpload, onChange: this.handleChange }, imageUrl ? (React.createElement("img", { src: imageUrl, style: { width: 55 }, alt: "avatar" })) : (uploadButton))))));
    };
    return UploadImage;
}(React.Component));
exports.default = (0, react_i18next_1.withNamespaces)("pages")(UploadImage);
