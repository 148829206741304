"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUUID = exports.getKeyGraphqlResponse = exports.convertToBase64 = exports.isNullOrUndefinedOrEmpty = exports.isNullOrUndefinedOrZero = exports.isNullOrUndefined = exports.formatCountryCodePhone = exports.redirectToRezglo = exports.capitalize = exports.fieldLimit = exports.SUBSCRIBED_COMPANY_ADMIN_URL = exports.COMPANY_ADMIN_URL = void 0;
exports.COMPANY_ADMIN_URL = process.env.COMPANY_ADMIN_URL;
exports.SUBSCRIBED_COMPANY_ADMIN_URL = process.env.SUBSCRIBED_COMPANY_ADMIN_URL;
var fieldLimit = function (field, limit) {
    return field && field.toString().length > limit ? field.toString().substring(0, limit - 4) + '...' : field;
};
exports.fieldLimit = fieldLimit;
function capitalize(s) {
    if (typeof s !== 'string')
        return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
}
exports.capitalize = capitalize;
function redirectToRezglo(url) {
    return exports.COMPANY_ADMIN_URL + '/?redirect_to=' + url;
}
exports.redirectToRezglo = redirectToRezglo;
var formatCountryCodePhone = function (country_code) {
    return country_code ? (country_code.includes('+') ? country_code.substr(1) : country_code) : '1';
};
exports.formatCountryCodePhone = formatCountryCodePhone;
var isNullOrUndefined = function (value) {
    return value === null || value === undefined;
};
exports.isNullOrUndefined = isNullOrUndefined;
var isNullOrUndefinedOrZero = function (value) {
    return value === null || value === undefined || value === 0;
};
exports.isNullOrUndefinedOrZero = isNullOrUndefinedOrZero;
var isNullOrUndefinedOrEmpty = function (value) {
    return value === null || value === undefined || value === '';
};
exports.isNullOrUndefinedOrEmpty = isNullOrUndefinedOrEmpty;
var convertToBase64 = function (imgPath, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", imgPath, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
        console.log(this.response);
        var reader = new FileReader();
        reader.onload = function (event) {
            callback(event.target.result);
        };
        var file = this.response;
        reader.readAsDataURL(file);
    };
    xhr.send();
};
exports.convertToBase64 = convertToBase64;
var getKeyGraphqlResponse = function (data) {
    return data[Object.keys(data)[0]];
};
exports.getKeyGraphqlResponse = getKeyGraphqlResponse;
var generateUUID = function () {
    var d = new Date().getTime(); //Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) { //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        }
        else { //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};
exports.generateUUID = generateUUID;
