"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
var franchise_model_1 = require("./franchise.model");
var ZonesModel = /** @class */ (function (_super) {
    __extends(ZonesModel, _super);
    function ZonesModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ZonesModel.prototype.list = function (filter, franchiseIds) {
        var schema = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      query($filter: ZoneListInput, $franchiseIds: [Int], $token: String) {\n        PublicZoneList(filter: $filter, franchiseIds: $franchiseIds, token: $token) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            name\n            status\n            franchise {\n              ...Franchise\n            }\n            custom_data\n            fleetRouteZone {\n              coordinates\n            }\n          }\n        }\n      }\n      ", "\n    "], ["\n      query($filter: ZoneListInput, $franchiseIds: [Int], $token: String) {\n        PublicZoneList(filter: $filter, franchiseIds: $franchiseIds, token: $token) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            name\n            status\n            franchise {\n              ...Franchise\n            }\n            custom_data\n            fleetRouteZone {\n              coordinates\n            }\n          }\n        }\n      }\n      ", "\n    "])), franchise_model_1.FranchiseFragment);
        return this.publicQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    ZonesModel.prototype.show = function (id) {
        var schema = "\n      query($id: String!){\n      ZoneGet(_id: $id){\n        _id\n        name\n        custom_data\n        fleetRouteZone {\n          coordinates\n        }\n      }\n    }";
        return this.query(schema, { id: id });
    };
    ZonesModel.prototype.store = function (input) {
        var schema = "\n      mutation($input: ZoneInput!, $token: String){\n        PublicZoneCreate(input: $input, token: $token){\n          _id\n      }\n    }";
        return this.publicMutation(schema, { input: input });
    };
    ZonesModel.prototype.update = function (input) {
        var schema = "\n    mutation($input: ZoneUpdateInput!, $token: String){\n      PublicZoneUpdate(input: $input, token: $token){\n        _id\n      }\n    }";
        return this.publicMutation(schema, { input: input });
    };
    ZonesModel.prototype.bulkUpdate = function (filter, data, filterAfter) {
        var schema = "\n     mutation($filter: ZoneBulkUpdateFilterInput!, $data: ZoneBulkUpdateDataInput!, $filterAfter: ZoneBulkUpdateFilterAfterInput){\n      ZoneBulkUpdate(filter: $filter, data:$data, filterAfter:$filterAfter){\n        count\n      }\n    }";
        return this.mutate(schema, { filter: filter, data: data, filterAfter: filterAfter });
    };
    return ZonesModel;
}(BaseModel_1.BaseModel));
var zonesModel = new ZonesModel();
exports.default = zonesModel;
var templateObject_1;
