"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firstLevelColumns = void 0;
var columns_helpers_1 = require("../../../../../shared/helpers/columns.helpers");
require("../ManageFleetList.less");
var styles = require('../ManageFleetList.less');
var firstLevelColumns = function (t) {
    return [
        {
            title: t('manage_fleets.Status'),
            dataIndex: 'taxi_status',
            key: 'taxi_status',
            render: function (taxi_status) { return (0, columns_helpers_1.renderStatusColumns)(taxi_status, t); },
            className: 'first-level',
            width: '7%',
        },
        {
            title: t('manage_fleets.SNo'),
            dataIndex: '_id',
            key: '_id',
            sorter: true,
            render: function (number) { return (0, columns_helpers_1.renderColumns)(number); },
            className: 'first-level',
        },
        {
            title: t('manage_fleets.Fleet No'),
            dataIndex: 'taxi_no',
            render: function (taxi_no) { return (0, columns_helpers_1.renderColumns)(taxi_no); },
            sorter: true,
            className: 'first-level',
        },
        {
            title: t('manage_fleets.Fleet model'),
            dataIndex: 'model_name',
            sorter: true,
            render: function (model_name) { return (0, columns_helpers_1.renderColumns)(model_name); },
            className: 'first-level',
        },
        {
            title: 'Nemt',
            dataIndex: 'is_nemt',
            sorter: true,
            className: 'first-level',
            render: function (is_nemt) { return (0, columns_helpers_1.renderNemtColumns)(is_nemt); },
        },
    ];
};
exports.firstLevelColumns = firstLevelColumns;
