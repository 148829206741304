"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var React = __importStar(require("react"));
var TextAreaText = /** @class */ (function (_super) {
    __extends(TextAreaText, _super);
    function TextAreaText(props) {
        var _this = _super.call(this, props) || this;
        _this.validateAddress = function (rule, value, callback) {
            if (value != undefined) {
                var newValue = value.replace(/(^\s*)|(\s*$)/gi, '');
                newValue = newValue.replace(/[ ]{2,}/gi, ' ');
                newValue = newValue.replace(/\n /, '\n');
                var length_1 = newValue ? newValue.split(' ').length : 0;
                if (length_1 > _this.props.cantWords) {
                    callback([new Error(_this.props.message)]);
                }
                else {
                    callback();
                }
            }
        };
        _this.state = {
            loading: false,
        };
        return _this;
    }
    TextAreaText.prototype.render = function () {
        var FormItem = antd_1.Form.Item;
        var TextArea = antd_1.Input.TextArea;
        var getFieldDecorator = this.props.form.getFieldDecorator;
        return (React.createElement("div", { className: 'rg-text-area' },
            React.createElement(FormItem, __assign({ hasFeedback: true, label: this.props.label }, this.props.formItemLayout), getFieldDecorator(this.props.propertyName, {
                initialValue: this.props.initial,
                rules: [
                    {
                        message: this.props.message,
                        required: this.props.isRequired || true,
                    },
                    {
                        validator: this.validateAddress,
                    },
                ],
            })(React.createElement(TextArea, { onChange: this.props.onChange, className: 'rg-text-address', placeholder: this.props.placeholder })))));
    };
    return TextAreaText;
}(React.Component));
exports.default = TextAreaText;
