"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var antd_1 = require("antd");
var AutocompleteStore_1 = __importStar(require("../../../stores/AutocompleteStore"));
require("./Single.less");
var RoutesPoint_cs_1 = require("../../../cs/booking/RoutesPoint.cs");
var Option = antd_1.Select.Option;
var openNotificationWithIcon = function (type, message, description) {
    antd_1.notification[type]({
        message: message,
        description: description,
    });
};
var Single = /** @class */ (function (_super) {
    __extends(Single, _super);
    function Single(props) {
        var _this = _super.call(this, props) || this;
        //#endregion
        //#region METHODS
        _this.drawPlaces = function () {
            var _a = _this.store, localPlaces = _a.localPlaces, apiPlaces = _a.apiPlaces, selectFirstDefault = _a.selectFirstDefault, setSelectFirstDefault = _a.setSelectFirstDefault;
            var count = 0;
            var localsLength = localPlaces.length - 1;
            if (selectFirstDefault && localsLength !== -1) {
                setTimeout(function () {
                    var _a;
                    setSelectFirstDefault();
                    var strg = JSON.stringify(__assign(__assign({}, localPlaces[0]), { local: true, index: 0 }));
                    _this.props.form.setFieldsValue((_a = {}, _a[_this.props.propertyName] = strg, _a));
                }, 600);
            }
            var idsLocals = localPlaces.map(function (value) { return value.gMapPlaceId; });
            var apiToShow = apiPlaces.filter(function (value) { return idsLocals.indexOf(value.gMapPlaceId) === -1; });
            return __spreadArray([], apiToShow.map(function (value, index) {
                ++count;
                return (React.createElement(Option, { value: "".concat(JSON.stringify(__assign(__assign({}, value), { local: false, index: index }))), key: "".concat(value.googleDescription, " ").concat(count), title: "".concat(value.googleDescription) },
                    React.createElement("div", { className: "rg-autocomplete-option" },
                        React.createElement("span", { className: "rg-autocomplete-option-address-api" }, value.googleDescription))));
            }), true);
        };
        _this.searchPredictions = function () {
            var _a = _this.store, loadingApi = _a.loadingApi, setLoadingApi = _a.setLoadingApi, setApiPlaces = _a.setApiPlaces;
            if (!loadingApi) {
                setLoadingApi(true);
                AutocompleteStore_1.default
                    .getApiPredictions(_this.textSearch)
                    .then(function (resultApi) {
                    resultApi.length && setApiPlaces(resultApi);
                    setLoadingApi();
                    // console.log(`EndSearchingApi...`);
                })
                    .catch(function (err) {
                    console.log("Exception getting api predictions: ", JSON.stringify(err));
                    setLoadingApi();
                });
            }
        };
        //#endregion
        //#region EVENTS
        _this.onSearch = function (e) {
            var me = _this;
            me.textSearch = e;
            clearTimeout(me.timeOutId);
            me.timeOutId = setTimeout(function () {
                me.textSearch && _this.searchPredictions();
            }, 400);
            var onSearch = _this.props.onSearch;
            onSearch && onSearch(_this.textSearch);
        };
        _this.onChange = function (e) {
            // console.log(`onChange: `, e);
            var onChange = _this.props.onChange;
            onChange && onChange(e);
        };
        _this.onFocus = function () {
            // console.log(moment().diff(moment()));
        };
        _this.onBlur = function () {
            clearTimeout(_this.timeOutId);
        };
        _this.onSelect = function (e) {
            var t = _this.props.t;
            var setLoadingData = _this.store.setLoadingData;
            // TODO get all geocode dataaddress
            // console.log(`ONSELECT: `, JSON.stringify(e));
            setLoadingData(true);
            var _a = _this.props, onSelect = _a.onSelect, onLoadRoutePointData = _a.onLoadRoutePointData;
            if (onSelect) {
                onSelect(e);
                // console.log(`OnSelectAutocomplete: `, JSON.stringify(e));
                var _b = JSON.parse(e), localization_1 = _b.localization, iataCode_1 = _b.iataCode, local_1 = _b.local, gMapPlaceId = _b.gMapPlaceId, types = _b.types, internalDescription_1 = _b.internalDescription, googleDescription_1 = _b.googleDescription;
                AutocompleteStore_1.default
                    .getPlaceDetails(gMapPlaceId, t)
                    .then(function (result) {
                    var routesPoint = new RoutesPoint_cs_1.RoutesPoint(result);
                    routesPoint = local_1
                        ? __assign(__assign({}, routesPoint), {
                            internalDescription: internalDescription_1,
                            iataDropoff: iataCode_1,
                            isAirportDropoff: !!iataCode_1,
                            drop_latitude: localization_1.coordinates[1],
                            drop_longitude: localization_1.coordinates[0],
                            drop_location: googleDescription_1 || internalDescription_1,
                        }) : __assign(__assign({}, routesPoint), { drop_location: googleDescription_1 });
                    if (routesPoint.isAirportDropoff && !routesPoint.iataDropoff) {
                        AutocompleteStore_1.default
                            .getAirportIataCode(routesPoint.drop_latitude, routesPoint.drop_longitude, t)
                            .then(function (value) {
                            setLoadingData();
                            routesPoint = __assign(__assign({}, routesPoint), { iataDropoff: value });
                            onLoadRoutePointData && onLoadRoutePointData(routesPoint);
                        })
                            .catch(function (err) {
                            setLoadingData();
                            console.log("Exception getting airport Data: ", JSON.stringify(err));
                            openNotificationWithIcon('error', 'Internal Services', err);
                            onLoadRoutePointData && onLoadRoutePointData({ error: { description: err, type: 'EXCEPTION' } });
                        });
                    }
                    else {
                        setLoadingData();
                        onLoadRoutePointData && onLoadRoutePointData(routesPoint);
                    }
                })
                    .catch(function (err) {
                    setLoadingData();
                    console.log('Exception getting place Details: ', err);
                    if (err === 'REQUEST_DENIED') {
                        openNotificationWithIcon('error', 'Internal Services', 'Request deniedPlease contact system admins');
                    }
                    else {
                        openNotificationWithIcon('error', 'Internal Services', err);
                    }
                    onLoadRoutePointData && onLoadRoutePointData({ error: { description: err, type: 'EXCEPTION' } });
                });
            }
            else {
                setLoadingData();
            }
        };
        _this.onRemove = function (e) {
            var _a = _this.props, onRemove = _a.onRemove, propertyName = _a.propertyName;
            onRemove && onRemove(propertyName);
        };
        _this._store = _this.props.autocompleteStore || new AutocompleteStore_1.AutocompleteStore();
        _this._timeOutId = setTimeout(function () { }, 100);
        return _this;
    }
    Object.defineProperty(Single.prototype, "textSearch", {
        //#region SETTERS
        get: function () {
            return this._textSearch;
        },
        set: function (value) {
            this._textSearch = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Single.prototype, "timeOutId", {
        get: function () {
            return this._timeOutId;
        },
        set: function (value) {
            this._timeOutId = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Single.prototype, "store", {
        get: function () {
            return this._store;
        },
        set: function (value) {
            this._store = value;
        },
        enumerable: false,
        configurable: true
    });
    //#endregion
    Single.prototype.render = function () {
        var FormItem = antd_1.Form.Item;
        var _a = this, drawPlaces = _a.drawPlaces, store = _a.store;
        var _b = this.props, form = _b.form, label = _b.label, value = _b.value, propertyName = _b.propertyName, required = _b.required, placeholder = _b.placeholder, formItemLayout = _b.formItemLayout, formItemStyle = _b.formItemStyle, requiredMsg = _b.requiredMsg, rules = _b.rules, formItemClassName = _b.formItemClassName, disabled = _b.disabled, openMenu = _b.openMenu, additionalOptions = _b.additionalOptions;
        var loadingLocal = store.loadingLocal, loadingApi = store.loadingApi, loadingData = store.loadingData;
        var layout = formItemLayout || {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (React.createElement(FormItem, __assign({ hasFeedback: true, label: label }, layout, { 
            // style={formItemStyle}
            className: "rg-autocomplete-component-single ".concat(formItemClassName) }),
            React.createElement(antd_1.Select, __assign({ id: propertyName, placeholder: placeholder || '', optionFilterProp: "key", getPopupContainer: function (triggerNode) { return triggerNode.parentElement; }, showSearch: true, loading: loadingLocal || loadingApi || loadingData, filterOption: function () { return true; }, allowClear: !(loadingLocal || loadingApi || loadingData), defaultActiveFirstOption: false, clearIcon: React.createElement(antd_1.Icon, { type: "close", onClick: this.onRemove }), onSearch: this.onSearch, onChange: this.onChange, onSelect: this.onSelect, onFocus: this.onFocus, onBlur: this.onBlur }, (additionalOptions ? additionalOptions : {})), drawPlaces())));
    };
    Single = __decorate([
        mobx_react_1.observer
    ], Single);
    return Single;
}(React.Component));
exports.default = Single;
