"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGraphqlErrorMessage = exports.getSchemaType = void 0;
var lodash_1 = require("lodash");
var getSchemaType = function (gqlSchema, hasInnerToken) {
    if (hasInnerToken === void 0) { hasInnerToken = false; }
    var client = hasInnerToken ? 'publicClient' : 'onboardingClient';
    var operation = gqlSchema.schema.definitions[0].operation;
    return {
        resolveType: operation === 'query' ? 'query' : 'mutation',
        functionType: operation === 'query' ? 'query' : 'mutate',
        client: client,
    };
};
exports.getSchemaType = getSchemaType;
var getGraphqlErrorMessage = function (error) {
    if (error && error.graphQLErrors && error.graphQLErrors[0]) {
        var message = error.graphQLErrors[0].message;
        return message || undefined;
    }
    if (error && (0, lodash_1.get)(error, 'errors.length', false)) {
        var message = error.errors[0].message;
        return message || undefined;
    }
    return undefined;
};
exports.getGraphqlErrorMessage = getGraphqlErrorMessage;
