"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.prototype.getQueryString = function () {
        var pairs = location.search.slice(1).split('&');
        var result = {};
        pairs.forEach(function (pair) {
            var pairSplited = pair.split('=');
            result[pairSplited[0]] = decodeURIComponent(pairSplited[1] || '');
        });
        return JSON.parse(JSON.stringify(result));
    };
    Utils.prototype.sortAlphabetically = function (arrayToSort, fieldName, direction) {
        if (fieldName === void 0) { fieldName = null; }
        if (direction === void 0) { direction = 'ASC'; }
        return arrayToSort.sort(function (a, b) {
            var compareValueA = fieldName && fieldName in a ? a[fieldName] : a;
            var compareValueB = fieldName && fieldName in b ? b[fieldName] : b;
            if (compareValueA > compareValueB)
                return direction === 'ASC' ? 1 : -1;
            if (compareValueB > compareValueA)
                return direction === 'ASC' ? -1 : 1;
            return 0;
        });
    };
    Utils.prototype.getQueryNumber = function () {
        return parseInt(this.getQueryString());
    };
    return Utils;
}());
var utils = new Utils();
exports.default = utils;
