"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultItemLayout = exports.cl = exports.colLayout = exports.itl = exports.itemLayout = void 0;
var itemLayout = function (labelCol, wrapperCol) {
    return {
        labelCol: {
            xl: { span: labelCol && labelCol[0] ? labelCol[0] : 7 },
            lg: { span: labelCol && labelCol[1] ? labelCol[1] : 24 },
            md: { span: labelCol && labelCol[2] ? labelCol[2] : 24 },
            sm: { span: labelCol && labelCol[3] ? labelCol[3] : 24 },
            xs: { span: labelCol && labelCol[4] ? labelCol[4] : 24 },
        },
        wrapperCol: {
            xl: { span: wrapperCol && wrapperCol[0] ? wrapperCol[0] : 17 },
            lg: { span: wrapperCol && wrapperCol[1] ? wrapperCol[1] : 23 },
            md: { span: wrapperCol && wrapperCol[2] ? wrapperCol[2] : 24 },
            sm: { span: wrapperCol && wrapperCol[3] ? wrapperCol[3] : 24 },
            xs: { span: wrapperCol && wrapperCol[4] ? wrapperCol[4] : 24 },
        },
    };
};
exports.itemLayout = itemLayout;
exports.itl = exports.itemLayout;
var colLayout = function (span) {
    var isNumber = typeof span === 'number';
    return !isNumber
        ? {
            xl: span ? span[0] : 7,
            lg: span && span[1] ? span[1] : 24,
            md: span && span[2] ? span[2] : 24,
            sm: span && span[3] ? span[3] : 24,
            xs: span && span[4] ? span[4] : 24,
        }
        : {
            span: span || 12,
        };
};
exports.colLayout = colLayout;
exports.cl = exports.colLayout;
exports.defaultItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 10 },
        lg: { span: 6 },
        xl: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
        md: { span: 12 },
        lg: { span: 15 },
        xl: { span: 17 },
    },
};
