"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeDiffTrip = exports.validateDates = exports.propsAntdDateComponent = exports.monthlyRange = exports.substractDay = exports.format = exports.formatDate2 = exports.formatDate1 = void 0;
var moment = require("moment");
exports.formatDate1 = "MM-DD-YYYY HH:mm:ss A";
exports.formatDate2 = "MM-DD-YYYY";
var format = function (date, format) {
    if (format === void 0) { format = 'YYYY-MM-DD HH:mm:ss'; }
    if (typeof date === 'string') {
        date = moment(date);
    }
    return date ? date.format(format) : '';
};
exports.format = format;
var substractDay = function (date, numberDay) {
    return date
        ? moment(date)
            .subtract(numberDay, 'day')
            .endOf('day')
        : moment();
};
exports.substractDay = substractDay;
exports.monthlyRange = {
    firstDayMonth: (0, exports.format)(moment()
        .startOf('month')
        .set({ hours: 0, minute: 0 })),
    endDayMonth: (0, exports.format)(moment()
        .endOf('month')
        .set({ hours: 23, minute: 59, seconds: 0 })),
};
exports.propsAntdDateComponent = {
    showTime: {
        use12Hours: true,
        format: 'h:mm A',
        onAmPmChange: function () { },
    },
    allowClear: false,
    format: 'MM-DD-YYYY hh:mm:ss A',
    style: { width: '100%' },
    getCalendarContainer: function (triggerNode) { return triggerNode.parentElement; },
};
var validateDates = function (dates) {
    var startDate = dates.startDate, endDate = dates.endDate;
    return [
        {
            validator: function (rule, value, callback) {
                setTimeout(function () {
                    if (startDate && endDate) {
                        if (moment(startDate) > moment(endDate)) {
                            callback([new Error('The from Date/Time cannot be greather than the End Date/Time')]);
                        }
                        else {
                            callback();
                        }
                    }
                    callback();
                }, 500);
            },
        },
    ];
};
exports.validateDates = validateDates;
var timeDiffTrip = function (firstDate, secondDate) {
    if (secondDate === void 0) { secondDate = moment(); }
    var cantMin = firstDate.diff(secondDate, 'minutes');
    var cantHour = firstDate.diff(secondDate, 'hour');
    return "".concat(cantHour, " hour(s) and ").concat(cantMin - (60 * cantHour), " min(s)");
};
exports.timeDiffTrip = timeDiffTrip;
