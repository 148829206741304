"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var AirlineModel = /** @class */ (function (_super) {
    __extends(AirlineModel, _super);
    function AirlineModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AirlineModel.prototype.list = function (config) {
        var schema = "\n    query($filter: AirlinesListInput!){\n      AirlinesList(filter: $filter){\n        items {\n          _id\n          fs\n          name\n          iata\n        }\n      }\n    }";
        return this.query(schema, config);
    };
    return AirlineModel;
}(BaseModel_1.BaseModel));
var airlineModel = new AirlineModel();
exports.default = airlineModel;
