"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedFare = void 0;
var FixedFareType_enum_1 = require("../../enums/FixedFareType.enum");
// import * as get from 'lodash/get';
var FixedFare = /** @class */ (function () {
    function FixedFare(item) {
        if (item === void 0) { item = {}; }
        var _id = item._id, pointA = item.pointA, pointB = item.pointB, zonePickup = item.zonePickup, zoneDropoff = item.zoneDropoff, minimumPersons = item.minimumPersons, maximumPersons = item.maximumPersons, oneWayFare = item.oneWayFare, roundTripFare = item.roundTripFare, fareId = item.fareId, modelId = item.modelId, campaign = item.campaign, isByPerson = item.isByPerson, isPerZone = item.isPerZone, fixedFareType = item.fixedFareType, franchise = item.franchise;
        this._id = _id || undefined;
        this.pointAId = pointA ? pointA._id : '';
        this.pointBId = pointB ? pointB._id : '';
        this.zonePickupId = zonePickup ? zonePickup._id : '';
        this.zoneDropoffId = zoneDropoff ? zoneDropoff._id : '';
        this.minimumPersons = minimumPersons || '';
        this.maximumPersons = maximumPersons || '';
        this.oneWayFare = oneWayFare || '';
        this.roundTripFare = roundTripFare;
        this.fareId = fareId || undefined;
        this.modelId = modelId || undefined;
        this.campaignId = campaign ? campaign._id : '';
        this.isByPerson = isByPerson ? isByPerson : false;
        this.isPerZone = isPerZone ? isPerZone : false;
        this.fixedFareType = fixedFareType || FixedFareType_enum_1.FixedFareType.PER_ROUTE_ZONES;
        this.franchise_id = franchise ? franchise._id : undefined;
    }
    return FixedFare;
}());
exports.FixedFare = FixedFare;
