"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var mobx_react_1 = require("mobx-react");
var antd_1 = require("antd");
require("../FaresWizardStepFour.less");
var layout_helpers_1 = require("../../../../../../shared/helpers/layout.helpers");
var decorator_helpers_1 = require("../../../../../../shared/helpers/decorator.helpers");
var semantic_ui_react_1 = require("semantic-ui-react");
var Provider_1 = require("../../../../../../shared/context/Provider");
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer(props) {
        return _super.call(this, props) || this;
    }
    Footer.prototype.render = function () {
        var _this = this;
        var t = this.props.t;
        return [
            React.createElement(semantic_ui_react_1.Divider, { fitted: true, className: "divider-inner-wizard" }),
            React.createElement(antd_1.Row, { className: "mt-2 mb-2 t-right" },
                React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24, 24])),
                    React.createElement("div", null,
                        this.props.faresWizardStore.current > 0 && (React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "right", className: "previous-button", onClick: this.props.faresWizardStore.wizardPrev },
                            React.createElement(semantic_ui_react_1.Icon, { name: "arrow left" }),
                            t("component.Previous"))),
                        this.props.faresWizardStore.current <
                            this.props.faresWizardStore.steps.length - 1 && (React.createElement(semantic_ui_react_1.Button, { primary: true, icon: true, labelPosition: "right", className: "next-button", onClick: function () { return _this.props.onSubmit(_this.context.state); } },
                            t("component.Next"),
                            React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))),
                        this.props.faresWizardStore.current ===
                            this.props.faresWizardStore.steps.length - 1 && (React.createElement(semantic_ui_react_1.Button, { primary: true, icon: true, labelPosition: "right", onClick: function () { return _this.props.onSubmit(_this.context.state); } },
                            t("component.Done"),
                            React.createElement(semantic_ui_react_1.Icon, { name: "check" }))))))
        ];
    };
    Footer.contextType = Provider_1.CustomContext;
    Footer = __decorate([
        (0, decorator_helpers_1.injectI18n)("pages"),
        (0, decorator_helpers_1.injectForm)({ withRef: true }),
        (0, mobx_react_1.inject)("onboardingCompanyStore", "faresWizardStore"),
        mobx_react_1.observer
    ], Footer);
    return Footer;
}(React.Component));
exports.Footer = Footer;
exports.default = (0, react_1.memo)(Footer);
