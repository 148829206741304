"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var Provider_1 = require("../Provider/Provider");
var layout_helpers_1 = require("../../../shared/helpers/layout.helpers");
require("./VoucherHeader.less");
require("../VoucherContainer.less");
var Voucher_enum_1 = require("../../../shared/enums/Voucher.enum");
var semantic_ui_react_1 = require("semantic-ui-react");
var layout_hooks_1 = require("../../../shared/hooks/layout.hooks");
var Skeleton_1 = require("../../../shared/components/Skeleton/Skeleton");
var date_helpers_1 = require("../../../shared/helpers/date.helpers");
var voucher_helpers_1 = require("../Utils/voucher.helpers");
var voucher_icon_1 = require("../Utils/voucher.icon");
var VoucherHeader = (0, react_1.memo)(function () {
    var _a, _b, _c, _d;
    var _e = (0, Provider_1.useVoucherContext)(), booking = _e.booking, voucherType = _e.voucherType;
    var _f = (0, layout_hooks_1.useResponsive)(), isMobile = _f.isMobile, isIphone = _f.isIphone, isResponsive = _f.isResponsive;
    var title = (0, react_1.useMemo)(function () {
        switch (voucherType) {
            case Voucher_enum_1.VoucherType.TRANSFER:
                return "Transfer - Voucher";
            case Voucher_enum_1.VoucherType.ROUND_TRIP:
                return "Round-Trip - Voucher";
            case Voucher_enum_1.VoucherType.MULTI_TRIP:
                return "Multi-Trip - Voucher";
        }
    }, [voucherType]);
    return (react_1.default.createElement(antd_1.Row, null,
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([6, 8, 10, 24, 24]), { className: isMobile ? 'rz-text-center rz-my-logo' : '' }),
            react_1.default.createElement(Skeleton_1.SkeletonImage, { condition: !!booking },
                react_1.default.createElement("img", { className: "rz-logo", src: booking === null || booking === void 0 ? void 0 : booking.companyLogo }))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([18, 16, 24, 24]), { className: "rz-text-end rz-center-title" }),
            react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                react_1.default.createElement("h2", { className: "rz-voucher-type" }, title),
                react_1.default.createElement("h5", { className: "rz-voucher-id" },
                    "ID: ", booking === null || booking === void 0 ? void 0 :
                    booking._id))),
        react_1.default.createElement(antd_1.Divider, { className: "rz-header-divider" }),
        react_1.default.createElement(antd_1.Row, null,
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12, 12, 10, 10, 24])),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title header-title" }, "Passenger Name:"),
                    react_1.default.createElement("h3", { className: "rz-title-primary" },
                        " ", (_a = booking === null || booking === void 0 ? void 0 : booking.passenger) === null || _a === void 0 ? void 0 :
                        _a.name))),
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12, 12, 14, 14, 24]), { className: "".concat(!isMobile ? 'rz-text-end rz-top-header' : 'rz-top-header-r') }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(voucher_icon_1.VoucherPhoneIcon, { className: "rz-phone-dispatcher" }),
                    react_1.default.createElement("span", { className: !isIphone ? 'rz-phone-number' : 'rz-phone-number-i5' }, (0, voucher_helpers_1.phoneNumberMask)((_b = booking === null || booking === void 0 ? void 0 : booking.passenger) === null || _b === void 0 ? void 0 : _b.phone))))),
        react_1.default.createElement(antd_1.Row, { className: "rz-row-details" },
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([7, 7]), { className: "rz-column-padding ".concat(!isResponsive ? 'rz-border-r' : '', " rz-border-b rz-h-50px") }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title" }, "Fleet Type:"),
                    react_1.default.createElement("h3", { className: "rz-title-primary" },
                        " ", (_c = booking === null || booking === void 0 ? void 0 : booking.model) === null || _c === void 0 ? void 0 :
                        _c.model_name))),
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([10, 10]), { className: "rz-column-padding ".concat(!isResponsive ? 'rz-border-r rz-column-pl' : '', " rz-border-b rz-h-50px") }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title" },
                        "Meet and Greet:",
                        react_1.default.createElement("span", { className: "rz-title-primary rz-header-left-text" }, (booking === null || booking === void 0 ? void 0 : booking.meetAndGreet) || "No Included")),
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title rz-mt-m" },
                        "Wheelchair Lift Ramp:",
                        react_1.default.createElement("span", { className: "rz-title-primary rz-header-left-text" }, (booking === null || booking === void 0 ? void 0 : booking.wheelchairLiftRamp) ? "Included" : "No Included")))),
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([7, 7]), { className: "rz-column-padding ".concat(!isResponsive ? 'rz-column-pl' : '', " rz-border-b rz-h-50px") }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title" }, "Trip Confirmed On:"),
                    react_1.default.createElement("h3", { className: "rz-title-primary" },
                        " ",
                        (0, date_helpers_1.format)(booking === null || booking === void 0 ? void 0 : booking.createdate, date_helpers_1.formatDate2)))),
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([7, 7]), { className: "rz-column-padding ".concat(!isResponsive ? 'rz-border-r' : '', " rz-border-b rz-h-50px") }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title" }, "Payment Method:"),
                    react_1.default.createElement("h3", { className: "rz-title-primary rz-text-capitalize" }, voucher_helpers_1.paymentMethod === null || voucher_helpers_1.paymentMethod === void 0 ? void 0 : voucher_helpers_1.paymentMethod[booking === null || booking === void 0 ? void 0 : booking.payment_type]))),
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([10, 10]), { className: "rz-column-padding ".concat(!isResponsive ? 'rz-border-r rz-column-pl' : '', " rz-border-b rz-h-50px") }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title" }, "Fare Type:"),
                    react_1.default.createElement("h3", { className: "rz-title-primary rz-text-capitalize" }, (_d = booking === null || booking === void 0 ? void 0 : booking.fleetFareType) === null || _d === void 0 ? void 0 : _d.replace("_", " ").toLowerCase()))),
            react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([7, 7]), { className: "rz-column-padding ".concat(!isResponsive ? 'rz-column-pl' : '', " rz-border-b rz-h-50px") }),
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h6', className: "rz-column-title" }, "Invoice No.:"),
                    react_1.default.createElement("h3", { className: "rz-title-primary" }, booking === null || booking === void 0 ? void 0 : booking.invoice))))));
});
exports.default = VoucherHeader;
