"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var CountryModels = /** @class */ (function (_super) {
    __extends(CountryModels, _super);
    function CountryModels() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CountryModels.prototype.publicList = function (config) {
        var schema = "\n    query{\n      CountryList{\n        _id\n        country_name\n        currency_code\n        iso_country_code\n        telephone_code\n      }\n    }";
        return this.query(schema, config);
    };
    CountryModels.prototype.phoneCodeList = function (config) {
        // const schema = `
        // query ($input:SearchCountryInput){
        //   GetCountries(input: $input){
        //     _id
        //     country_name
        //     telephone_code
        //   }
        // }`;
        var schema = "\n    query{\n      CountryList{\n        _id\n        country_name\n        telephone_code\n        iso_country_code\n      }\n    }";
        return this.query(schema, config);
    };
    return CountryModels;
}(BaseModel_1.BaseModel));
var countryModel = new CountryModels();
exports.default = countryModel;
