"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var antd_1 = require("antd");
var InputText = /** @class */ (function (_super) {
    __extends(InputText, _super);
    function InputText(props) {
        var _this = _super.call(this, props) || this;
        _this.validateField = function (rule, value, callback) {
            setTimeout(function () {
                if (!_this.props.allCharacters) {
                    var expR = /^[A-Za-zÁÉÍÓÚáéíóú.'ñ Ñ ]+$/;
                    if (value !== '' && value !== undefined && value !== null && !value.match(expR)) {
                        callback([new Error('Accepts only letters!')]);
                    }
                    else {
                        var newValue = value.replace(/(^\s*)|(\s*$)/gi, '');
                        newValue = newValue.replace(/[ ]{2,}/gi, ' ');
                        newValue = newValue.replace(/\n /, '\n');
                        var length_1 = newValue ? newValue.length : 0;
                        if (length_1 > _this.props.validateLength) {
                            callback([new Error("String of up to ".concat(_this.props.validateLength, " letters!"))]);
                        }
                        else {
                            callback();
                        }
                    }
                }
                else {
                    if (value !== '' && value !== undefined && value !== null) {
                        var newValue = value.replace(/(^\s*)|(\s*$)/gi, '');
                        newValue = newValue.replace(/[ ]{2,}/gi, ' ');
                        newValue = newValue.replace(/\n /, '\n');
                        var length_2 = newValue ? newValue.length : 0;
                        if (length_2 >= _this.props.validateLength) {
                            callback([new Error("String of up to ".concat(_this.props.validateLength, " letters!"))]);
                        }
                        else {
                            callback();
                        }
                    }
                }
                callback();
            }, 300);
        };
        return _this;
    }
    InputText.prototype.render = function () {
        var FormItem = antd_1.Form.Item;
        var _a = this.props, form = _a.form, label = _a.label, value = _a.value, propertyName = _a.propertyName, required = _a.required, maxlength = _a.maxlength, placeholder = _a.placeholder, formItemLayout = _a.formItemLayout, formItemStyle = _a.formItemStyle, size = _a.size, requiredMsg = _a.requiredMsg, rules = _a.rules, disabled = _a.disabled;
        var layout = formItemLayout || {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                span: 8,
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
                span: 10,
            },
        };
        var getFieldDecorator = form.getFieldDecorator;
        return (React.createElement(FormItem, __assign({ hasFeedback: true, label: label }, layout, { style: formItemStyle }), getFieldDecorator(propertyName, {
            initialValue: value,
            rules: [
                {
                    message: requiredMsg || "Please input ".concat(label, "!"),
                    required: required,
                },
                {
                    validator: this.validateField,
                },
            ],
        })(React.createElement(antd_1.Input, { placeholder: placeholder, onChange: this.props.onChange, size: size, disabled: disabled }))));
    };
    return InputText;
}(React.Component));
exports.default = InputText;
