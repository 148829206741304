"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fare = void 0;
var FareCompanyStatus_enum_1 = require("../../enums/FareCompanyStatus.enum");
var Fare_enum_1 = require("../../enums/Fare.enum");
var Fare = /** @class */ (function () {
    function Fare(item) {
        if (item === void 0) { item = {}; }
        var model_size = item.model_size, hasPerMiles = item.hasPerMiles, hasFixed = item.hasFixed, hasPerHour = item.hasPerHour, fare_status = item.fare_status, fare_id = item.fare_id, company = item.company, model = item.model, fareId = item.fareId, amountSuitcases = item.amountSuitcases, passengerAmountSuitcases = item.passengerAmountSuitcases;
        var fareType = undefined;
        if (hasPerMiles !== undefined && hasFixed !== undefined) {
            fareType = [];
            if (hasPerMiles) {
                fareType.push(Fare_enum_1.FaresTypes.PER_MILES_KM);
            }
            if (hasFixed) {
                fareType.push(Fare_enum_1.FaresTypes.FIXED);
            }
            if (hasPerHour) {
                fareType.push(Fare_enum_1.FaresTypes.PER_HOURS);
            }
        }
        this.model_id = model ? model._id : '';
        this.model_size = model_size || '';
        this.hasPerMiles = hasPerMiles || true;
        this.hasFixed = hasFixed || false;
        this.hasPerHour = hasPerHour || false;
        this.fareType = fareType || [Fare_enum_1.FaresTypes.PER_MILES_KM];
        this.fare_status = fare_status || FareCompanyStatus_enum_1.FareCompanyStatus.ACTIVE;
        this.fare_id = fare_id || undefined;
        this.company_cid = company ? company._id : '';
        this.fareId = fareId || '';
        this.amountSuitcases = amountSuitcases;
        this.passengerAmountSuitcases = passengerAmountSuitcases;
    }
    return Fare;
}());
exports.Fare = Fare;
