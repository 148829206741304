"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = exports.twoDecimal = void 0;
var string_helpers_1 = require("./string.helpers");
var twoDecimal = function (number) {
    var t = !(0, string_helpers_1.isNullOrUndefined)(number) ? number.toString() : 0;
    var regex = /(\d*.\d{0,2})/;
    return t.match(regex)[0];
};
exports.twoDecimal = twoDecimal;
var formatNumber = function (number, decPlaces) {
    if (decPlaces === void 0) { decPlaces = 0; }
    return (+number).toFixed(decPlaces).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};
exports.formatNumber = formatNumber;
