"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var LandingStore = /** @class */ (function () {
    function LandingStore() {
        this.isVisible = true;
        this.View = 'BecomeParner';
        this.independentDriverCheck = false;
    }
    LandingStore.prototype.setIsVisible = function (visible) {
        this.isVisible = visible;
    };
    LandingStore.prototype.setIsView = function (View) {
        this.View = View;
    };
    LandingStore.prototype.setIsIndependentDriverCheck = function (sending) {
        this.independentDriverCheck = sending;
    };
    __decorate([
        mobx_1.observable
    ], LandingStore.prototype, "isVisible", void 0);
    __decorate([
        mobx_1.observable
    ], LandingStore.prototype, "View", void 0);
    __decorate([
        mobx_1.observable
    ], LandingStore.prototype, "independentDriverCheck", void 0);
    __decorate([
        mobx_1.action
    ], LandingStore.prototype, "setIsVisible", null);
    __decorate([
        mobx_1.action
    ], LandingStore.prototype, "setIsView", null);
    __decorate([
        mobx_1.action
    ], LandingStore.prototype, "setIsIndependentDriverCheck", null);
    return LandingStore;
}());
var landingStore = new LandingStore();
exports.default = landingStore;
