"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
var franchise_model_1 = require("./franchise.model");
var RouteModel = /** @class */ (function (_super) {
    __extends(RouteModel, _super);
    function RouteModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RouteModel.prototype.list = function (filter, franchiseIds) {
        var schema = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      query($filter: ListRoutePointInput, $franchiseIds: [Int], $token: String) {\n        PublicListRoutePoints(filter: $filter, franchiseIds: $franchiseIds, token: $token) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            name\n            location\n            franchise {\n              ...Franchise\n            }\n            coordinate\n            placeId\n            status\n            isAirport\n            iata\n            cityName\n            cityCode\n            postalCode\n          }\n        }\n      }\n      ", "\n    "], ["\n      query($filter: ListRoutePointInput, $franchiseIds: [Int], $token: String) {\n        PublicListRoutePoints(filter: $filter, franchiseIds: $franchiseIds, token: $token) {\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            name\n            location\n            franchise {\n              ...Franchise\n            }\n            coordinate\n            placeId\n            status\n            isAirport\n            iata\n            cityName\n            cityCode\n            postalCode\n          }\n        }\n      }\n      ", "\n    "])), franchise_model_1.FranchiseFragment);
        return this.publicQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    RouteModel.prototype.show = function (id) {
        var schema = (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      query($id: String!) {\n        PublicGetRoutePoint(input: $id) {\n          _id\n          name\n          location\n          coordinate\n          placeId\n          franchise {\n            ...Franchise\n          }\n          status\n          isAirport\n          iata\n          cityName\n          cityCode\n          postalCode\n        }\n      }\n      ", "\n    "], ["\n      query($id: String!) {\n        PublicGetRoutePoint(input: $id) {\n          _id\n          name\n          location\n          coordinate\n          placeId\n          franchise {\n            ...Franchise\n          }\n          status\n          isAirport\n          iata\n          cityName\n          cityCode\n          postalCode\n        }\n      }\n      ", "\n    "])), franchise_model_1.FranchiseFragment);
        return this.onboardingQuery(schema, { id: id });
    };
    RouteModel.prototype.store = function (input) {
        var schema = "\n      mutation($input: CreateRoutePointInput!, $token: String){\n      PublicCreateRoutePoint(input: $input, token: $token){\n        _id\n      }\n    }\n    ";
        return this.publicMutation(schema, { input: input });
    };
    RouteModel.prototype.update = function (input) {
        var schema = "\n    mutation($input: UpdateRoutePointInput!, $token: String){\n      PublicUpdateRoutePoint(input: $input, token: $token){\n        items {\n          _id,\n          name,\n          location\n        }\n      }\n    }\n    ";
        return this.publicMutation(schema, { input: input });
    };
    RouteModel.prototype.massUpdate = function (input, data, filter) {
        var schema = "\n     mutation($input: ListRoutePointInput!, $data: UpdateRoutePointsInput!, $filter:  ListRoutePointInput){\n      UpdateBulkRoutePoint(input: $input, data:$data, filter:$filter){\n        pageNumber\n        pageSize\n        count\n      }\n    }";
        return this.mutate(schema, { input: input, data: data, filter: filter });
    };
    RouteModel.prototype.exportTo = function (format, filter) {
        var schema = "\n       query($format: CoreDocumentFormatEnum!, $filter: ListRoutePointInput!){\n        RoutePointListExport(format: $format, filter: $filter){\n            url\n          }\n        }\n   ";
        return this.query(schema, { format: format, filter: filter });
    };
    return RouteModel;
}(BaseModel_1.BaseModel));
var routeModel = new RouteModel();
exports.default = routeModel;
var templateObject_1, templateObject_2;
