"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.showChangeStatusConfirm = exports.travelStatusColumnTranslation = exports.fieldsTranslations = exports.statusTypeTranslations = exports.handleCatchValidationModel = exports.openNotificationWithIcon = void 0;
var antd_1 = require("antd");
var openNotificationWithIcon = function (type, message, description, duration, placement) {
    if (duration === void 0) { duration = 10; }
    if (placement === void 0) { placement = 'topRight'; }
    antd_1.notification[type]({
        message: message,
        description: description,
        duration: duration,
        placement: placement,
    });
};
exports.openNotificationWithIcon = openNotificationWithIcon;
var handleCatchValidationModel = function (error, form, tryMessagePopup, t) {
    if (tryMessagePopup === void 0) { tryMessagePopup = true; }
    var _a = error.graphQLErrors[0], apiValidation = _a.validation, others = __rest(_a, ["validation"]);
    if (typeof apiValidation === 'object') {
        var validation = Object.entries(apiValidation);
        validation.forEach(function (_a) {
            var _b;
            var key = _a[0], value = _a[1];
            if (form) {
                form.setFields((_b = {}, _b[key] = { errors: value.map(function (message) { return new Error(message); }) }, _b));
            }
            if (tryMessagePopup) {
                value.forEach(function (errorMessage) {
                    (0, exports.openNotificationWithIcon)('error', t ? t('notification.Save Failed') : 'Save Failed', errorMessage);
                });
            }
        });
    }
    else {
        (0, exports.openNotificationWithIcon)('error', t ? t('notification.Save Failed') : 'Save Failed', t ? t("notification.Internal Error") : 'Internal Error.');
    }
};
exports.handleCatchValidationModel = handleCatchValidationModel;
var statusTypeTranslations = function (status, t) {
    switch (status) {
        case 'ACTIVE':
        case 'A':
            return t ? t("status.Active") : 'Active';
        case 'BLOCK':
        case 'BUSY':
        case 'B':
        case 'D':
            return t ? t("status.Block") : 'Block';
        case 'INACTIVE':
        case 'I':
            return t ? t("status.Inactive") : 'Inactive';
        case 'TRASH':
        case 'T':
            return t ? t("status.Trash") : 'Trash';
        case 'PENDING_APPROVAL':
        case 'PENDING':
        case 'P':
            return t ? t("status.Pending") : 'Pending';
        case 'UNASSIGNED':
        case 'U':
            return t ? t("status.Unassigned") : 'Unassigned';
    }
};
exports.statusTypeTranslations = statusTypeTranslations;
var fieldsTranslations = function (field, t) {
    switch (field) {
        case 'name':
        case 'Name':
            return t ? t("common_field.Name") : 'Name';
    }
    return field;
};
exports.fieldsTranslations = fieldsTranslations;
var travelStatusColumnTranslation = function (status, t) {
    switch (status) {
        case 'COMPLETE':
        case 'Complete':
            return t ? t("status.Complete") : 'Complete';
        case 'IN_PROGRESS':
        case 'In Progress':
            return t ? t("manageDispatch.In Progress") : 'In Progress';
        case 'CANCELLED':
        case 'Cancelled':
            return t ? t("status.Cancelled") : 'Cancelled';
        case 'TEST_CANCELLED':
        case 'Test Canceled':
            return t ? t("status.Test Canceled") : 'Test Canceled';
        case 'START_TO_PICKUP':
        case 'Start to Pickup':
            return t ? t("manageDispatch.Start To Pickup") : 'Start to Pickup';
        case 'WAITING_FOR_PAYMENT':
        case 'Waiting for Payment':
            return t ? t("manageDispatch.Waiting for Payment") : 'Waiting for Payment';
        case 'WAITING_FOR_PAYMENT':
        case 'Waiting for Payment':
            return t ? t("manageDispatch.Waiting for Payment") : 'Waiting for Payment';
        case 'REASSIGN':
        case 'Reassign':
            return t ? t("manageDispatch.Reassign") : 'Reassign';
        case 'WAITING_FOR_RESPONSE':
        case 'Waiting for Return':
            return t ? t("manageDispatch.Waiting for Return") : 'Waiting for Return';
        case 'TRIP_CONFIRMED':
        case 'Trip Confirmed':
            return t ? t("manageDispatch.Trip Confirmed") : 'Trip Confirmed';
        case 'WAITING_FOR_THE_RETURN_TRIP':
        case 'Waiting for the Return Trip':
            return t ? t("manageDispatch.Waiting for return trip") : 'Waiting for return trip';
        case 'ASSIGNED':
        case 'Assigned':
            return t ? t("status.Assigned") : 'Assigned';
        case 'ASSIGN':
        case 'Assign':
            return t ? t("status.Assign") : 'Assign';
        case 'TRIP_CANCELLED':
        case 'Trip Cancelled':
            return t ? t("manageDispatch.Trip Cancelled") : 'Trip Cancelled';
        case 'ALL':
        case 'All':
            return t ? t("component.All") : 'All';
        case 'CANCELLED_BY_DRIVER':
        case 'Cancelled By Driver':
            return t ? t("manageDispatch.Cancelled By Driver") : 'Cancelled By Driver';
    }
};
exports.travelStatusColumnTranslation = travelStatusColumnTranslation;
var showChangeStatusConfirm = function (status, callback, t) {
    var message = "";
    var successMessage = "";
    switch (status) {
        case 'ACTIVE':
        case 'A':
            message = t("status.Are you sure want to Activate Request(s)?");
            successMessage = t("status.Checked requests have been changed to activated status");
            break;
        case 'BLOCK':
        case 'BUSY':
        case 'B':
        case 'D':
            message = t("status.Are you sure want to Block Request(s)?");
            successMessage = t("status.Checked requests have been changed to blocked status");
            break;
        case 'INACTIVE':
        case 'I':
            message = t("status.Are you sure want to Inactive Request(s)?");
            successMessage = t("status.Checked requests have been changed to inactive status");
            break;
        case 'TRASH':
        case 'T':
            message = t("status.Are you sure want to TRASH Request(s)?");
            successMessage = t("status.Checked requests have been changed to deleted status");
            break;
        case 'PENDING_APPROVAL':
        case 'PENDING':
        case 'P':
            message = t("status.Are you sure want to Pending Approval Request(s)?");
            successMessage = t("status.Checked requests have been changed to pending status");
            break;
        case 'UNASSIGNED':
        case 'U':
            message = t("status.Are you sure want to Unassigned Request(s)?");
            successMessage = t("status.Checked requests have been changed to unassigned status");
            break;
    }
    antd_1.Modal.confirm({
        title: message,
        okText: t('status.Yes'),
        okType: 'danger',
        cancelText: t('status.No'),
        onOk: function () {
            callback(status, successMessage);
        },
    });
};
exports.showChangeStatusConfirm = showChangeStatusConfirm;
