"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToMilesOrKM = exports.RowSelectComponent = exports.renderOptionsByEntity = exports.renderFlagsOptions = exports.renderOptionsByEnum = exports.selectDefaultAntdOptions = void 0;
var React = __importStar(require("react"));
var antd_1 = require("antd");
var enum_helpers_1 = require("./enum.helpers");
var number_helpers_1 = require("./number.helpers");
var FormItem = antd_1.Form.Item;
var Option = antd_1.Select.Option;
var formRowLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
    },
};
exports.selectDefaultAntdOptions = React.createElement(Option, { value: "" }, "-Select-");
var renderOptionsByEnum = function (sameEnum, hasSelectDefaultOption) {
    if (hasSelectDefaultOption === void 0) { hasSelectDefaultOption = true; }
    var renderOption = (0, enum_helpers_1.getValues)(sameEnum).map(function (element) {
        return React.createElement(Option, { value: element },
            " ",
            (0, enum_helpers_1.formatValues)(element));
    });
    if (hasSelectDefaultOption && renderOption)
        renderOption.unshift(exports.selectDefaultAntdOptions);
    return renderOption;
};
exports.renderOptionsByEnum = renderOptionsByEnum;
var renderFlagsOptions = function (item) {
    if (item) {
        var address = 'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + item.data.iso_country_code + '.svg';
        return (React.createElement("p", { style: { textAlign: 'left', display: 'contents' } },
            React.createElement("img", { style: { width: 24, marginRight: 8 }, src: address }),
            item.name));
    }
    else {
        console.log('Error rendering flags in the selector');
    }
};
exports.renderFlagsOptions = renderFlagsOptions;
var renderOptionsByEntity = function (entities, options, includeDefault) {
    if (includeDefault === void 0) { includeDefault = true; }
    var value = options.value, text = options.text;
    var renderOption = entities
        ? entities.map(function (entity) {
            return React.createElement(Option, { value: entity[value] },
                " ",
                entity[text]);
        })
        : null;
    if (includeDefault && renderOption)
        renderOption.unshift(exports.selectDefaultAntdOptions);
    return renderOption;
};
exports.renderOptionsByEntity = renderOptionsByEntity;
var RowSelectComponent = function (props) {
    var getFieldDecorator = props.form.getFieldDecorator, formItemLayout = props.formItemLayout, label = props.label, fieldName = props.fieldName, initialValue = props.initialValue, style = props.style, onChange = props.onChange;
    var layout = formItemLayout || formRowLayout;
    return (React.createElement(FormItem, __assign({}, layout, { label: label }), getFieldDecorator(fieldName, {
        initialValue: initialValue,
    })(React.createElement(antd_1.Select, { style: style, onChange: onChange },
        React.createElement(Option, { value: 25 }, "25"),
        React.createElement(Option, { value: 50 }, "50"),
        React.createElement(Option, { value: 75 }, "75"),
        React.createElement(Option, { value: 100 }, "100"),
        React.createElement(Option, { value: 1000000 }, "All")))));
};
exports.RowSelectComponent = RowSelectComponent;
var convertToMilesOrKM = function (value, currentUnit) {
    var isMiles = currentUnit === "miles";
    return (0, number_helpers_1.formatNumber)(value * (isMiles ? 1.609 : 0.621371), 3);
};
exports.convertToMilesOrKM = convertToMilesOrKM;
