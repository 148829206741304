"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var antd_1 = require("antd");
var semantic_ui_react_1 = require("semantic-ui-react");
var string_helpers_1 = require("../../../../shared/helpers/string.helpers");
require("./ThankYou.less");
var OnboardingIcons_1 = require("../../../../shared/components/Icons/OnboardingIcons");
var react_i18next_1 = require("react-i18next");
var ThankYou = /** @class */ (function (_super) {
    __extends(ThankYou, _super);
    function ThankYou(props) {
        var _this = _super.call(this, props) || this;
        _this.handleRedirectToLicense = function () {
            var is_subscribed = _this.props.onboardingCompanyStore.company.contact.is_subscribed;
            var redirectURL;
            if (is_subscribed) {
                var companyName = window.location.pathname.split('/')[2];
                redirectURL = string_helpers_1.SUBSCRIBED_COMPANY_ADMIN_URL + "/".concat(companyName, "/admin/rg/rezglo_license");
            }
            else {
                redirectURL = string_helpers_1.COMPANY_ADMIN_URL + "?redirect_to=rg/rezglo_license";
            }
            window.open(redirectURL);
        };
        _this.handleClickLoginButton = function () {
            var is_subscribed = _this.props.onboardingCompanyStore.company.contact.is_subscribed;
            var redirectURL;
            if (is_subscribed) {
                var companyName = window.location.pathname.split('/')[2];
                redirectURL = string_helpers_1.SUBSCRIBED_COMPANY_ADMIN_URL + "/".concat(companyName, "/admin/login");
            }
            else {
                redirectURL = string_helpers_1.COMPANY_ADMIN_URL;
            }
            window.open(redirectURL);
        };
        return _this;
    }
    ThankYou.prototype.render = function () {
        var _a = this.props.onboardingCompanyStore, setFinished = _a.setFinished, company = _a.company, is_subscribed = _a.company.contact.is_subscribed;
        var t = this.props.t;
        return [
            React.createElement(antd_1.Row, { type: "flex", align: "bottom" },
                React.createElement(antd_1.Col, { span: 24 },
                    React.createElement(semantic_ui_react_1.Segment, { placeholder: true, basic: true },
                        React.createElement(semantic_ui_react_1.Header, { icon: true },
                            React.createElement(semantic_ui_react_1.Icon, { name: "check circle outline", color: "green", size: "huge" }),
                            React.createElement("div", { className: "successfully-description" },
                                React.createElement("div", null,
                                    t("component.Thank you"),
                                    React.createElement("strong", null, company.companydetails.company_name)),
                                React.createElement("div", null, t("component.You have Successfully Completed your")),
                                React.createElement("div", null, t("component.Setup")))),
                        React.createElement(semantic_ui_react_1.Segment.Inline, null,
                            React.createElement(semantic_ui_react_1.Button, { primary: true, icon: true, size: "huge", labelPosition: "right", className: "buy-subscription-button", onClick: this.handleRedirectToLicense },
                                t("manageCompany.See Subscriptions"),
                                React.createElement(semantic_ui_react_1.Icon, { name: "shop" })),
                            React.createElement(antd_1.Tooltip, { title: t("component.Here you can buy Dispatch module"), placement: "right" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "question circle", style: {
                                        verticalAlign: "top",
                                        marginLeft: 2,
                                        position: "absolute",
                                    } }))),
                        React.createElement(OnboardingIcons_1.RezgloTaxiStop, { style: { margin: "8px 370pt 15px 0" } }),
                        React.createElement(semantic_ui_react_1.Segment.Inline, null,
                            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", onClick: function () {
                                    setFinished(false);
                                } },
                                React.createElement(semantic_ui_react_1.Icon, { name: "arrow left" }),
                                t("status.Back")),
                            React.createElement(semantic_ui_react_1.Button, { primary: true, onClick: this.handleClickLoginButton }, t("status.Login")),
                            !is_subscribed &&
                                React.createElement("span", { className: "continue-free-label" },
                                    t("component.Continue for FREE"),
                                    React.createElement(antd_1.Tooltip, { title: t("component.Here you can add fares"), placement: "top" },
                                        React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }))))))),
        ];
    };
    ThankYou = __decorate([
        (0, mobx_react_1.inject)("onboardingCompanyStore"),
        mobx_react_1.observer
    ], ThankYou);
    return ThankYou;
}(React.Component));
exports.default = (0, react_i18next_1.withNamespaces)("pages")(ThankYou);
