"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FleetBookingStatusEnum = void 0;
var FleetBookingStatusEnum;
(function (FleetBookingStatusEnum) {
    FleetBookingStatusEnum["ALL"] = "ALL";
    FleetBookingStatusEnum["ACTIVE"] = "ACTIVE";
    FleetBookingStatusEnum["BUSY"] = "BUSY";
    FleetBookingStatusEnum["FREE"] = "FREE";
    FleetBookingStatusEnum["IDLE"] = "IDLE";
    FleetBookingStatusEnum["UNASSIGNED"] = "UNASSIGNED";
})(FleetBookingStatusEnum = exports.FleetBookingStatusEnum || (exports.FleetBookingStatusEnum = {}));
