"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEndScroll = void 0;
var react_1 = require("react");
var useEndScroll = function () {
    var _a = (0, react_1.useState)(false), isEndScroll = _a[0], setIsEndScroll = _a[1];
    var handleScroll = function (event) {
        var target = event.target;
        if (Math.ceil(target.scrollHeight - target.scrollTop) === target.clientHeight) {
            setIsEndScroll(true);
        }
    };
    return { isEndScroll: isEndScroll, handleScroll: handleScroll };
};
exports.useEndScroll = useEndScroll;
