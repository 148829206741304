"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecificTime = void 0;
var React = __importStar(require("react"));
var antd_1 = require("antd");
require("../FaresWizardStepFour.less");
var layout_helpers_1 = require("../../../../../../shared/helpers/layout.helpers");
var react_1 = require("react");
var Provider_1 = require("../../../../../../shared/context/Provider");
var date_helpers_1 = require("../../../../../../shared/helpers/date.helpers");
var moment = __importStar(require("moment"));
var decorator_helpers_1 = require("../../../../../../shared/helpers/decorator.helpers");
var SpecificTime = /** @class */ (function (_super) {
    __extends(SpecificTime, _super);
    function SpecificTime(props) {
        var _this = _super.call(this, props) || this;
        _this.getFormatHour = function (value) {
            return value ? moment.default("".concat((0, date_helpers_1.format)(moment.default(), 'YYYY-MM-DD'), " ").concat(value)) : undefined;
        };
        _this.state = {
            is12Hours: true,
        };
        return _this;
    }
    SpecificTime.prototype.componentDidMount = function () {
        var _a = this.context, peak_rate_hour_format = _a.state.peak_rate_hour_format, setState = _a.setState;
        var is12Hours = peak_rate_hour_format === '12h';
        this.setState({ is12Hours: is12Hours });
        setState({ peak_rate_hour_format: is12Hours ? "12h" : "24h" });
    };
    SpecificTime.prototype.render = function () {
        var _this = this;
        var _a = this.context, _b = _a.state, peak_rate_hour_time_from = _b.peak_rate_hour_time_from, peak_rate_hour_time_to = _b.peak_rate_hour_time_to, setState = _a.setState;
        var is12Hours = this.state.is12Hours;
        var _c = this.props, form = _c.form, t = _c.t;
        return (React.createElement(antd_1.Row, { type: "flex" },
            React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([6, 6]), { className: "timer-title" }),
                t("manageFare.Specific Time"),
                " "),
            React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([18, 18])),
                React.createElement(antd_1.Row, null,
                    React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12, 12])),
                        React.createElement(antd_1.Form.Item, __assign({ label: t("manageFare.From") }, (0, layout_helpers_1.itl)([6, 6], [18, 18])), form.getFieldDecorator("peak_rate_hour_time_from", {
                            initialValue: this.getFormatHour(peak_rate_hour_time_from),
                        })(React.createElement(antd_1.TimePicker, { placeholder: t("manageFare.Select time"), style: { width: "auto" }, use12Hours: is12Hours, format: "h:mm:ss A", onChange: function (rateHourFrom) {
                                return setState({
                                    peak_rate_hour_time_from: (0, date_helpers_1.format)(rateHourFrom, "hh:mm")
                                });
                            }, addon: function () { return (React.createElement(antd_1.Button, { size: "small", type: "primary", onClick: function () {
                                    _this.setState({ is12Hours: !is12Hours });
                                    setState({
                                        peak_rate_hour_format: !is12Hours ? '12h' : '24h'
                                    });
                                } },
                                is12Hours ? 24 : 12,
                                " \u00A0 Hours")); } })))),
                    React.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12, 12])),
                        React.createElement(antd_1.Form.Item, __assign({ label: t("manageFare.To") }, (0, layout_helpers_1.itl)([6, 6], [18, 18])), form.getFieldDecorator("peak_rate_hour_time_to", {
                            initialValue: this.getFormatHour(peak_rate_hour_time_to),
                        })(React.createElement(antd_1.TimePicker, { placeholder: t("manageFare.Select time"), style: { width: "auto" }, use12Hours: is12Hours, format: "h:mm:ss A", onChange: function (rateHourTo) {
                                return setState({
                                    peak_rate_hour_time_to: (0, date_helpers_1.format)(rateHourTo, "hh:mm")
                                });
                            }, addon: function () { return (React.createElement(antd_1.Button, { size: "small", type: "primary", onClick: function () {
                                    _this.setState({ is12Hours: !is12Hours });
                                    setState({
                                        peak_rate_hour_format: !is12Hours ? '12h' : '24h'
                                    });
                                } },
                                is12Hours ? 24 : 12,
                                " \u00A0 Hours")); } }))))))));
    };
    SpecificTime.contextType = Provider_1.CustomContext;
    SpecificTime = __decorate([
        (0, decorator_helpers_1.injectI18n)("pages")
    ], SpecificTime);
    return SpecificTime;
}(React.Component));
exports.SpecificTime = SpecificTime;
exports.default = (0, react_1.memo)(SpecificTime);
