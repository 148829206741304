"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firstLevelColumns = void 0;
var columns_helpers_1 = require("../../../../../shared/helpers/columns.helpers");
var firstLevelColumns = function (t) {
    return [
        {
            title: t('status.Status'),
            dataIndex: 'status',
            key: 'driverStatus',
            render: function (driverStatus) { return (0, columns_helpers_1.renderStatusColumns)(driverStatus, t); },
            className: 'first-level',
            width: '7%',
        },
        {
            title: 'S.No',
            dataIndex: '_id',
            key: '_id',
            sorter: true,
            render: function (number) { return (0, columns_helpers_1.renderColumns)(number); },
            className: 'first-level',
        },
        {
            title: t('common_field.Name'),
            dataIndex: 'name',
            sorter: true,
            render: function (name) { return (0, columns_helpers_1.renderColumns)(name); },
            className: 'first-level',
        },
        {
            title: t('manage_fleets.Email'),
            dataIndex: 'email',
            sorter: true,
            render: function (email) { return (0, columns_helpers_1.renderColumns)(email); },
            className: 'first-level',
        },
        {
            title: t('manage_fleets.Phone'),
            dataIndex: 'phone',
            sorter: true,
            align: 'center',
            render: function (phone) { return (0, columns_helpers_1.renderColumns)(phone); },
            className: 'first-level',
        },
        {
            title: t('manageDrivers.Photo'),
            dataIndex: 'photo',
            align: 'center',
            sorter: true,
            render: function (photo) { return (0, columns_helpers_1.renderPhoto)(photo); },
            className: 'first-level',
        },
    ];
};
exports.firstLevelColumns = firstLevelColumns;
