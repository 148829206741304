"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleAvailability = void 0;
var CompanyNameType_enum_1 = require("../../enums/CompanyNameType.enum");
var VehicleAvailability = /** @class */ (function () {
    function VehicleAvailability(item) {
        if (item === void 0) { item = {}; }
        var _id = item._id, key = item.key, searchId = item.searchId, companyName = item.companyName, companyLogo = item.companyLogo, fleetId = item.fleetId, luggage = item.luggage, animalsNumber = item.animalsNumber, childrenCategory1 = item.childrenCategory1, childrenCategory2 = item.childrenCategory2, childrenCategory3 = item.childrenCategory3, companyNameType = item.companyNameType, modelImage = item.modelImage, notesPickup = item.notesPickup, notesRoundTrip = item.notesRoundTrip, approx_fare = item.approx_fare, description = item.description, peopleNumber = item.peopleNumber, maxAmountSuitcases = item.maxAmountSuitcases, modelSize = item.modelSize, companyTax = item.companyTax, approx_duration_sec = item.approx_duration_sec, approx_distance = item.approx_distance, driverName = item.driverName, driverPhone = item.driverPhone, modelName = item.modelName, fleetNo = item.fleetNo, fleetSpeed = item.fleetSpeed, pickup_time = item.pickup_time, company_id = item.company_id, modelId = item.modelId, currentLatitude = item.currentLatitude, currentLongitude = item.currentLongitude, driverRating = item.driverRating, driverStatus = item.driverStatus, isByPerson = item.isByPerson, wheelchairLiftRampAccess = item.wheelchairLiftRampAccess, meetAndGreet = item.meetAndGreet, rideTracking = item.rideTracking, roundTripFare = item.roundTripFare, minQuantityPersons = item.minQuantityPersons, maxQuantityPersons = item.maxQuantityPersons, fleetPickupDistance = item.fleetPickupDistance, maxLuggagePerPassenger = item.maxLuggagePerPassenger;
        this._id = _id || undefined;
        this.key = key || undefined;
        this.searchId = searchId;
        this.companyName = companyName;
        this.companyLogo = companyLogo;
        this.fleetId = fleetId;
        this.luggage = luggage;
        this.animalsNumber = animalsNumber;
        this.childrenCategory1 = childrenCategory1;
        this.childrenCategory2 = childrenCategory2;
        this.childrenCategory3 = childrenCategory3;
        this.companyNameType = companyNameType || CompanyNameType_enum_1.CompanyNameTypeEnum.REZGLO;
        this.modelImage = modelImage;
        this.notesPickup = notesPickup;
        this.notesRoundTrip = notesRoundTrip;
        this.approx_fare = approx_fare;
        this.description = description;
        this.peopleNumber = peopleNumber;
        this.maxAmountSuitcases = maxAmountSuitcases;
        this.modelSize = modelSize;
        this.companyTax = companyTax;
        this.approx_duration_sec = approx_duration_sec;
        this.approx_distance = approx_distance;
        this.driverName = driverName;
        this.driverPhone = driverPhone;
        this.modelName = modelName;
        this.fleetNo = fleetNo;
        this.fleetSpeed = fleetSpeed;
        this.pickup_time = pickup_time;
        this.company_id = company_id;
        this.modelId = modelId;
        this.currentLatitude = currentLatitude;
        this.currentLongitude = currentLongitude;
        this.driverRating = driverRating;
        this.driverStatus = driverStatus;
        this.isByPerson = isByPerson;
        this.wheelchairLiftRampAccess = wheelchairLiftRampAccess;
        this.meetAndGreet = meetAndGreet;
        this.rideTracking = rideTracking;
        this.roundTripFare = roundTripFare;
        this.minQuantityPersons = minQuantityPersons;
        this.maxQuantityPersons = maxQuantityPersons;
        this.fleetPickupDistance = fleetPickupDistance;
        this.maxLuggagePerPassenger = maxLuggagePerPassenger;
    }
    return VehicleAvailability;
}());
exports.VehicleAvailability = VehicleAvailability;
