"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenseToDriverEnum = exports.StripeSessionStatus = void 0;
var StripeSessionStatus;
(function (StripeSessionStatus) {
    StripeSessionStatus["session_success"] = "success";
    StripeSessionStatus["session_canceled"] = "cancelled";
})(StripeSessionStatus = exports.StripeSessionStatus || (exports.StripeSessionStatus = {}));
var LicenseToDriverEnum;
(function (LicenseToDriverEnum) {
    LicenseToDriverEnum["ASSIGNATIONS"] = "ASSIGNATIONS";
})(LicenseToDriverEnum = exports.LicenseToDriverEnum || (exports.LicenseToDriverEnum = {}));
