"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Amenity = void 0;
var Amenity = /** @class */ (function () {
    function Amenity(attributes) {
        if (attributes === void 0) { attributes = null; }
        if (attributes != null) {
            this._id = attributes._id != undefined ? attributes._id : '';
            this.is_nemt = attributes.is_nemt != undefined ? attributes.is_nemt : '';
            this.amenity_name = attributes.amenity_name != undefined ? attributes.amenity_name : '';
            this.description = attributes.description != undefined ? attributes.description : '';
            this.amenity_category = attributes.amenity_category != undefined ? attributes.amenity_category : '';
            this.amenity_image = attributes.amenity_image != undefined ? attributes.amenity_image : '';
            this.amenity_fare = attributes.amenity_fare != undefined ? attributes.amenity_fare : '';
            this.minimum_amount = attributes.minimum_amount != undefined ? attributes.minimum_amount : '';
            this.maximum_amount = attributes.maximum_amount != undefined ? attributes.maximum_amount : '';
            this.is_default = attributes.is_default != undefined ? attributes.is_default : '';
            this.status = attributes.status != undefined ? attributes.status : 'A';
        }
        else {
            this.is_default = true;
            this.is_nemt = false;
            this.status = 'A';
        }
    }
    return Amenity;
}());
exports.Amenity = Amenity;
