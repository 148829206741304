"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
var CompanyUser_cs_1 = require("./CompanyUser.cs");
var CompanyDetails_cs_1 = require("./CompanyDetails.cs");
var CompanyCommission_cs_1 = require("./CompanyCommission.cs");
var CompanyInfo_cs_1 = require("./CompanyInfo.cs");
var lodash_1 = require("lodash");
var Company = /** @class */ (function () {
    function Company(item) {
        if (item === void 0) { item = {}; }
        var _id = item._id, contact = item.contact, companydetails = item.companydetails, commission = item.commission, companyinfo = item.companyinfo, is_nemt = item.is_nemt, franchise = item.franchise;
        this._id = _id === 0 || _id !== undefined || _id !== null ? _id : undefined;
        this.is_nemt = is_nemt == undefined || !is_nemt ? false : true;
        this.company_name = item.company_name || '';
        this.sms_account_id = item.sms_account_id || '';
        this.contact = __assign({}, new CompanyUser_cs_1.CompanyUser(contact)) || new CompanyUser_cs_1.CompanyUser();
        this.companydetails = __assign({}, new CompanyDetails_cs_1.CompanyDetails(companydetails)) || new CompanyDetails_cs_1.CompanyDetails();
        this.commission =
            commission !== null && commission !== undefined
                ? __assign({}, new CompanyCommission_cs_1.CompanyCommission(commission)) : new CompanyCommission_cs_1.CompanyCommission();
        this.companyinfo = __assign({}, new CompanyInfo_cs_1.CompanyInfo(companyinfo)) || new CompanyInfo_cs_1.CompanyInfo();
        this.franchise_id = (0, lodash_1.get)(franchise, '_id', undefined);
        this.franchise = franchise || undefined;
        this.is_default_for_franchise = (0, lodash_1.get)(item, 'is_default_for_franchise', undefined);
        this.has_stripe_account = (0, lodash_1.get)(item, 'has_stripe_account', false);
    }
    return Company;
}());
exports.Company = Company;
