"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedFareTour = exports.RouteTour = exports.ZoneTour = exports.CompanyTour = exports.FleetTour = exports.DriverTour = exports.FareTour = void 0;
var FareTour = function (t) {
    return [
        {
            selector: '.fare-first-step',
            content: t('onboardingTour.Here you will create the rates for zones'),
        },
        {
            selector: '.fare-add',
            content: t('onboardingTour.Start uploading the prices'),
        },
        {
            selector: '.next-step',
            content: t('onboardingTour.Continue the process when you finish adding at least one fare'),
        },
    ];
};
exports.FareTour = FareTour;
var DriverTour = function (t) {
    return [
        {
            selector: '.driver-second-step',
            content: t('onboardingTour.Here in this section'),
        },
        {
            selector: '.driver-add',
            content: t('onboardingTour.Click on Add Driver'),
        },
        {
            selector: '.back-step',
            content: t('onboardingTour.Go back if you want to continue in the process of the previous step'),
        },
        {
            selector: '.next-step',
            content: t('onboardingTour.Continue the process when you finish adding at least one driver'),
        },
    ];
};
exports.DriverTour = DriverTour;
var FleetTour = function (t) {
    return [
        {
            selector: '.fleet-third-step',
            content: t('onboardingTour.In this section you will add Vehicle specifications'),
        },
        {
            selector: '.fleet-add',
            content: t('onboardingTour.A modal will open and you can add a vehicle to your fleet'),
        },
        {
            selector: '.back-step',
            content: t('onboardingTour.Go back if you want to continue in the process of the previous step'),
        },
        {
            selector: '.next-step',
            content: t('onboardingTour.Continue the process when you finish adding at least one fleet'),
        },
    ];
};
exports.FleetTour = FleetTour;
var CompanyTour = function (t) {
    return [
        {
            selector: '.company-fourth-step',
            content: t('onboardingTour.In this section you can finalize'),
        },
    ];
};
exports.CompanyTour = CompanyTour;
var ZoneTour = function (t) {
    return [
        {
            selector: '.zone-step',
            content: t('onboardingTour.You can add multiple zones and pricing for your zone service'),
        },
        {
            selector: '.zone-name-step',
            content: t('onboardingTour.Add the name you like to use for your zone inside this empty box under the'),
        },
        {
            selector: '.zone-location-step',
            content: t('onboardingTour.Here you can search for your location where you want to add your zone'),
        },
        {
            selector: '.draw-zone-step',
            content: t('onboardingTour.Click on and click while dragging'),
        },
        {
            selector: '.save-zone-step',
            content: t('onboardingTour.Dont forget to save your Zone'),
        },
        {
            selector: '.table-zone-step',
            content: t('onboardingTour.Here you can edit. Just click on the pencil'),
        },
    ];
};
exports.ZoneTour = ZoneTour;
var RouteTour = function (t) {
    return [
        {
            selector: '.route-step',
            content: t('onboardingTour.Different from the zone, here you can add A to B route points for One-way or Round-trip pricing'),
        },
        {
            selector: '.route-form-step',
            content: t('onboardingTour.Here you can input the exact address and name of your location'),
        },
        {
            selector: '.save-route-step',
            content: t('onboardingTour.Dont forget to save your route'),
        },
        {
            selector: '.table-route-step',
            content: t('onboardingTour.Here you can edit your route points'),
        },
    ];
};
exports.RouteTour = RouteTour;
var FixedFareTour = function (t) {
    return [
        {
            selector: '.zone-and-route-fixed-fare-step',
            content: t('onboardingTour.Here you can search the name you entered for your Zone and add pricing'),
        },
        {
            selector: '.route-or-zone-selection-fixed-fare-step',
            content: t('onboardingTour.Select the Start Zone and End Zone'),
        },
        {
            selector: '.form-fixed-fare-step',
            content: t('onboardingTour.Here you add a minimum and maximum passengers per vehicle'),
        },
        {
            selector: '.submit-button-fixed-fare-step',
            content: t('onboardingTour.For faster onboarding, you can Add and Copy zones and Fixed Prices to avoid retyping pricing'),
        },
        {
            selector: '.table-fixed-fare-step',
            content: t('onboardingTour.Here you can search the list to edit your prices for Zones'),
        },
    ];
};
exports.FixedFareTour = FixedFareTour;
