"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findValues = exports.getValues = exports.formatValues = void 0;
var string_helpers_1 = require("./string.helpers");
var formatValues = function (string) {
    return string
        ? string
            .toLocaleLowerCase()
            .replace(/_/g, ' ')
            .replace(/\w\S*/g, function (w) { return w.replace(/^\w/, function (c) { return c.toUpperCase(); }); })
        : '';
};
exports.formatValues = formatValues;
var getValues = function (someEnum) {
    return Object.values(someEnum).filter(function (element) { return typeof element !== 'number'; });
};
exports.getValues = getValues;
var findValues = function (someEnum, value) {
    return !(0, string_helpers_1.isNullOrUndefinedOrEmpty)(value)
        ? (0, exports.formatValues)((0, exports.getValues)(someEnum).find(function (element) { return value === element; }))
        : value;
};
exports.findValues = findValues;
