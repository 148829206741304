"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
var FixedFareModel = /** @class */ (function (_super) {
    __extends(FixedFareModel, _super);
    function FixedFareModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FixedFareModel.prototype.listPoints = function (filter, franchiseIds) {
        var schema = "\n        query ($filter: ListRoutePointInput, $franchiseIds: [Int], $token: String){\n            PublicListRoutePoints(filter: $filter, franchiseIds: $franchiseIds, token: $token){\n              items {\n                _id\n                name\n                location\n              }\n            }\n          }";
        return this.publicQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    FixedFareModel.prototype.listZones = function (filter, franchiseIds) {
        var schema = "\n        query ($filter: ZoneListInput, $franchiseIds: [Int], $token: String){\n          PublicZoneList(filter: $filter, franchiseIds: $franchiseIds, token: $token){\n              items {\n                _id\n                name\n              }\n            }\n          }";
        return this.publicQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    FixedFareModel.prototype.listFare = function (config) {
        var schema = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query($filter: ListFixedFareInput!, $token: String){\n            PublicListFixedFaresCompany (filter: $filter, token: $token){\n              pageNumber\n              pageSize\n              count\n              items {\n                _id\n                pointA {\n                    name\n                }\n                pointB {\n                    name\n                }\n                zonePickup {\n                    name\n                }\n                zoneDropoff {\n                    name\n                }\n                minimumPersons\n                maximumPersons\n                campaign {\n                    _id\n                    name\n                    status\n                }\n                isByPerson\n                fixedFareType\n                oneWayFare\n                roundTripFare \n              }\n            }\n          }"], ["\n        query($filter: ListFixedFareInput!, $token: String){\n            PublicListFixedFaresCompany (filter: $filter, token: $token){\n              pageNumber\n              pageSize\n              count\n              items {\n                _id\n                pointA {\n                    name\n                }\n                pointB {\n                    name\n                }\n                zonePickup {\n                    name\n                }\n                zoneDropoff {\n                    name\n                }\n                minimumPersons\n                maximumPersons\n                campaign {\n                    _id\n                    name\n                    status\n                }\n                isByPerson\n                fixedFareType\n                oneWayFare\n                roundTripFare \n              }\n            }\n          }"])));
        return this.publicQuery(schema, config);
    };
    FixedFareModel.prototype.listModel = function (config) {
        var schema = "\n        query($filter: ListFixedFareModelInput, $token: String){\n            PublicListFixedFaresModel (filter: $filter, token: $token){\n              pageNumber\n              pageSize\n              count\n              items {\n                _id\n                pointA {\n                    name\n                }\n                pointB {\n                    name\n                }\n                zonePickup {\n                    name\n                }\n                zoneDropoff {\n                    name\n                }\n                minimumPersons\n                maximumPersons\n                campaign {\n                    _id\n                    name\n                    status\n                }\n                isByPerson \n                fixedFareType\n                oneWayFare\n                roundTripFare \n              }\n            }\n          }";
        return this.publicQuery(schema, config);
    };
    FixedFareModel.prototype.showFare = function (id) {
        var schema = "\n        query ($id: String!){\n            PublicGetFixedFareCompany(_id: $id ){\n                _id\n                pointA {\n                    _id\n                }\n                pointB {\n                    _id\n                }\n                zonePickup {\n                  _id\n                }\n                zoneDropoff {\n                  _id\n                }\n                minimumPersons\n                maximumPersons\n                oneWayFare\n                roundTripFare\n                campaign {\n                    _id\n                }\n                isByPerson\n                fixedFareType\n            }\n          }";
        return this.onboardingQuery(schema, { id: id });
    };
    FixedFareModel.prototype.showModel = function (id) {
        var schema = "\n        query ($id: String!){\n            GetFixedFareModel(_id: $id ){\n                _id\n                pointA {\n                    _id\n                }\n                pointB {\n                    _id\n                }\n                zonePickup {\n                  _id\n                }\n                zoneDropoff {\n                  _id\n                }\n                minimumPersons\n                maximumPersons\n                oneWayFare\n                roundTripFare\n                campaign {\n                    _id\n                }\n                isByPerson \n                fixedFareType\n            }\n          }";
        return this.query(schema, { id: id });
    };
    FixedFareModel.prototype.storeFare = function (input) {
        var schema = "\n            mutation ($input: CreateFareFixedInput, $token: String){\n                PublicCreateFixedFareCompany(input:$input, token: $token){\n                    minimumPersons\n                    maximumPersons\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.storeModel = function (input) {
        var schema = "\n            mutation ($input: ModelFareFixedInput, $token: String){\n                PublicCreateModelFareFixed(input:$input, token: $token){\n                    minimumPersons\n                    maximumPersons\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.updateFare = function (input) {
        var schema = "\n            mutation ($input: UpdateFareFixedInput!, $token: String){\n                PublicUpdateFixedFareCompany(input: $input, token: $token) {\n                    count\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.updateModel = function (input) {
        var schema = "\n            mutation ($input: UpdateFareFixedInput!, $token: String){\n                PublicUpdateModelFixedFare(input: $input, token: $token) {\n                    count\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.storeModelZone = function (input) {
        var schema = "\n            mutation ($input: ModelFareFixedPerZoneInput, $token: String){\n                PublicModelFixedFarePerZoneCreate(input:$input, token: $token){\n                    minimumPersons\n                    maximumPersons\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.updateModelZone = function (input) {
        var schema = "\n            mutation ($input: UpdateFareFixedZoneInput!, $token: String){\n                PublicModelFixedFarePerZoneUpdate(input: $input, token: $token) {\n                    count\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.storeFareZone = function (input) {
        var schema = "\n            mutation ($input: CreateFareFixedPerZoneInput, $token: String){\n              PublicCompanyFixedFarePerZoneCreate(input: $input, token: $token) {\n                  minimumPersons\n                  maximumPersons\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.updateFareZone = function (input) {
        var schema = "\n            mutation ($input: UpdateFareFixedZoneInput!, $token: String){\n                PublicCompanyFixedFarePerZoneUpdate(input: $input, token: $token) {\n                    count\n                }\n            }\n        ";
        return this.publicMutation(schema, { input: input });
    };
    FixedFareModel.prototype.deleteFare = function (input) {
        var schema = "\n            mutation ($input: ListFixedFareInput!){\n                DeleteFixedFaresCompany: PublicDeleteFixedFaresCompany(input: $input){\n                    count\n                }\n            }\n        ";
        return this.onboardingMutation(schema, { input: input });
    };
    FixedFareModel.prototype.deleteModel = function (input) {
        var schema = "\n            mutation ($input: ListFixedFareModelInput!){\n                DeleteModelFixedFares(input: $input){\n                    count\n                }\n            }\n        ";
        return this.mutate(schema, { input: input });
    };
    return FixedFareModel;
}(BaseModel_1.BaseModel));
var fixedFareModel = new FixedFareModel();
exports.default = fixedFareModel;
var templateObject_1;
