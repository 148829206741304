"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTime = void 0;
var React = __importStar(require("react"));
var moment = __importStar(require("moment"));
var antd_1 = require("antd");
var mobx_react_1 = require("mobx-react");
var Item = antd_1.Form.Item;
var DateTime = /** @class */ (function (_super) {
    __extends(DateTime, _super);
    function DateTime(props) {
        var _this = _super.call(this, props) || this;
        _this.disabledDate = function (current) {
            // TODO include today
            return current && current < moment.default().endOf('day');
        };
        _this.dateFormat = 'YYYY-MM-DD hh:mm:ss';
        return _this;
    }
    DateTime.prototype.render = function () {
        var me = this;
        var _a = me.props, form = _a.form, field = _a.field, placeHolder = _a.placeHolder, disabled = _a.disabled, required = _a.required, onChange = _a.onChange, label = _a.label, disabledDate = _a.disabledDate, rules = _a.rules, itemLayout = _a.itemLayout, className = _a.className, value = _a.value, t = _a.t;
        var getFieldDecorator = form.getFieldDecorator;
        var formItemLayout = itemLayout || {};
        return (React.createElement(Item, __assign({ className: className, label: label }, formItemLayout), getFieldDecorator(field, {
            initialValue: value ? moment.default(value, this.dateFormat) : null,
            rules: __spreadArray([
                {
                    required: required,
                    message: t ? t('component.Please select time and date') : 'Please select time and date',
                }
            ], (rules || []), true),
        })(React.createElement(antd_1.DatePicker, { showTime: { use12Hours: true, format: 'h:mm A', onAmPmChange: function () { } }, format: "MM-DD-YYYY hh:mm:ss A", placeholder: placeHolder || 'Select Time and Date', disabledDate: disabledDate || me.disabledDate, style: { width: '100%' }, disabled: disabled, getCalendarContainer: function (triggerNode) { return triggerNode.parentElement; }, onChange: function (e) {
                onChange && onChange(e);
            } }))));
    };
    DateTime = __decorate([
        mobx_react_1.observer
    ], DateTime);
    return DateTime;
}(React.Component));
exports.DateTime = DateTime;
