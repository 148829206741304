"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Driver = void 0;
var UserGender_enum_1 = require("../../enums/UserGender.enum");
var DriverBrandFor_enum_1 = require("../../enums/DriverBrandFor.enum");
var DriverWagePaymentType_enum_1 = require("../../enums/DriverWagePaymentType.enum");
var CompanyCommissionSubscription_enum_1 = require("../../enums/CompanyCommissionSubscription.enum");
var lodash_1 = require("lodash");
var Driver = /** @class */ (function () {
    function Driver(item) {
        if (item != null) {
            var _id = item._id, name_1 = item.name, lastname = item.lastname, email = item.email, gender = item.gender, dob = item.dob, driver_license_id = item.driver_license_id, driver_license_expire_date = item.driver_license_expire_date, driverLicenseImageUrl = item.driverLicenseImageUrl, driverLicenseImageName = item.driverLicenseImageName, driver_pco_license_number = item.driver_pco_license_number, driver_pco_license_expire_date = item.driver_pco_license_expire_date, driver_insurance_number = item.driver_insurance_number, driver_insurance_expire_date = item.driver_insurance_expire_date, driverInsuranceURL = item.driverInsuranceURL, driverInsuranceImageName = item.driverInsuranceImageName, driver_national_insurance_number = item.driver_national_insurance_number, driver_national_insurance_expire_date = item.driver_national_insurance_expire_date, phone = item.phone, telephone_code = item.telephone_code, phone2 = item.phone2, telephone_code2 = item.telephone_code2, brand_type = item.brand_type, isPayTypeWage = item.isPayTypeWage, commission_subscription = item.commission_subscription, wagePaymentType = item.wagePaymentType, wagePaymentNotes = item.wagePaymentNotes, commissionAdditionalPercent = item.commissionAdditionalPercent, login_city = item.login_city, login_state = item.login_state, login_country = item.login_country, booking_limit = item.booking_limit, address = item.address, photoURL = item.photoURL, company_id = item.company_id, subscriptionId = item.subscriptionId, subscriptionPaymentType = item.subscriptionPaymentType, subscriptionPaymentNotes = item.subscriptionPaymentNotes, zipCode = item.zipCode, languages = item.languages, status_1 = item.status, franchise = item.franchise;
            this._id = _id || undefined;
            this.franchise_id = (0, lodash_1.get)(franchise, '_id', undefined);
            this.name = name_1 || '';
            this.lastname = lastname || '';
            this.email = email || '';
            this.gender = gender || UserGender_enum_1.UserGender.MALE;
            this.dob = dob || '';
            this.driver_license_id = driver_license_id || '';
            this.driver_license_expire_date = driver_license_expire_date || '';
            this.driverLicenseImageUrl = driverLicenseImageUrl || '';
            this.driverLicenseImageName = driverLicenseImageName || '';
            this.driver_pco_license_number = driver_pco_license_number || '';
            this.driver_pco_license_expire_date = driver_pco_license_expire_date || '';
            this.driver_insurance_number = driver_insurance_number || '';
            this.driver_insurance_expire_date = driver_insurance_expire_date || '';
            this.driverInsuranceURL = driverInsuranceURL || '';
            this.driverInsuranceImageName = driverInsuranceImageName || '';
            this.driver_national_insurance_number = driver_national_insurance_number || '';
            this.driver_national_insurance_expire_date = driver_national_insurance_expire_date || '';
            this.phone = phone || '';
            this.telephone_code = telephone_code || '';
            this.phone2 = phone2 || '';
            this.telephone_code2 = telephone_code2 || '+1';
            this.brand_type = brand_type || DriverBrandFor_enum_1.DriverBrandFor.SINGLE;
            this.isPayTypeWage = isPayTypeWage || false;
            this.commission_subscription = commission_subscription || CompanyCommissionSubscription_enum_1.CompanyCommissionSubscriptionEnum.NONE;
            this.wagePaymentType = wagePaymentType || DriverWagePaymentType_enum_1.DriverWagePaymentType.CASH;
            this.wagePaymentNotes = wagePaymentNotes || '';
            this.commissionAdditionalPercent = commissionAdditionalPercent || '';
            this.login_city = login_city || null;
            this.login_state = login_state || null;
            this.login_country = login_country || null;
            this.booking_limit = booking_limit || '';
            this.address = address || '';
            this.photoURL = photoURL || '';
            this.company_id = company_id || null;
            this.subscriptionId = subscriptionId || null;
            this.subscriptionPaymentType = subscriptionPaymentType || null;
            this.subscriptionPaymentNotes = subscriptionPaymentNotes || '';
            this.zipCode = zipCode || null;
            this.languages = languages || null;
            this.status = status_1 || null;
        }
    }
    Driver.getDriversList = function (item) {
        var _id = item._id, status = item.status, name = item.name, email = item.email, company = item.company, phone = item.phone, driver_license_id = item.driver_license_id, photoURL = item.photoURL, commission_subscription = item.commission_subscription, shift_status = item.shift_status, account_balance = item.account_balance, franchise = item.franchise;
        return {
            _id: _id,
            key: _id,
            status: status,
            name: name,
            email: email,
            photo: photoURL,
            company_name: (0, lodash_1.get)(company, 'companydetails.company_name', ''),
            companyContactId: (0, lodash_1.get)(company, 'contact._id', ''),
            companyId: (0, lodash_1.get)(company, '_id', ''),
            phone: phone,
            driver_license_id: driver_license_id,
            commission_subscription: commission_subscription,
            shift_status: shift_status,
            account_balance: account_balance,
            franchise: (0, lodash_1.get)(franchise, 'legal_name', ''),
        };
    };
    return Driver;
}());
exports.Driver = Driver;
