"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyUser = void 0;
var CompanyUser = /** @class */ (function () {
    function CompanyUser(item) {
        if (item === void 0) { item = {}; }
        var _id = item._id, name = item.name, lastname = item.lastname, email = item.email, password = item.password, phone = item.phone, telephone_code = item.telephone_code, status = item.status, is_subscribed = item.is_subscribed;
        this._id = _id || '';
        this.name = name || '';
        this.lastname = lastname || '';
        this.email = email || '';
        this.password = password || '';
        this.phone = phone || '';
        this.telephone_code = telephone_code || '+1';
        this.status = status || 'TMP';
        this.is_subscribed = is_subscribed || false;
    }
    return CompanyUser;
}());
exports.CompanyUser = CompanyUser;
