"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fleet = void 0;
var lodash_1 = require("lodash");
var Fleet = /** @class */ (function () {
    function Fleet(attributes) {
        if (attributes != null) {
            this._id = attributes._id != undefined ? attributes._id : '';
            this.taxi_no = attributes.taxi_no != undefined ? attributes.taxi_no : '';
            this.taxi_model = attributes.taxi_model != undefined ? attributes.taxi_model : '';
            this.taxi_company = attributes.taxi_company != undefined ? attributes.taxi_company : '';
            this.taxi_owner_name = attributes.taxi_owner_name != undefined ? attributes.taxi_owner_name : '';
            this.taxi_manufacturer = attributes.taxi_manufacturer != undefined ? attributes.taxi_manufacturer : '';
            this.taxi_colour = attributes.taxi_colour != undefined ? attributes.taxi_colour : '';
            this.taxi_motor_expire_date =
                attributes.taxi_motor_expire_date != undefined ? attributes.taxi_motor_expire_date : '';
            this.taxi_insurance_number =
                attributes.taxi_insurance_number != undefined ? attributes.taxi_insurance_number : '';
            this.taxi_insurance_expire_date_time =
                attributes.taxi_insurance_expire_date_time != undefined ? attributes.taxi_insurance_expire_date_time : '';
            this.taxi_pco_licence_number =
                attributes.taxi_pco_licence_number != undefined ? attributes.taxi_pco_licence_number : '';
            this.taxi_pco_licence_expire_date =
                attributes.taxi_pco_licence_expire_date != undefined ? attributes.taxi_pco_licence_expire_date : '';
            this.taxi_country = attributes.taxi_country != undefined ? attributes.taxi_country : '';
            this.taxi_state = attributes.taxi_state != undefined ? attributes.taxi_state : '';
            this.taxi_city = attributes.taxi_city != undefined ? attributes.taxi_city : '';
            this.taxi_min_speed = attributes.taxi_min_speed != undefined ? attributes.taxi_min_speed : undefined;
            this.company = attributes.company != undefined ? attributes.company : undefined;
            this.model = attributes.model != undefined ? attributes.model : undefined;
            this.taxi_status = attributes.taxi_status != undefined ? attributes.taxi_status : '';
            this.franchise_id = (0, lodash_1.get)(attributes.franchise, '_id', undefined);
            this.driver = attributes.driver || undefined;
            //only list
            // Chris Added Values missing
            // this.taxi_type = attributes.taxi_type != undefined ? attributes.taxi_type : '';
            // this.taxi_speed = attributes.taxi_speed != undefined ? attributes.taxi_speed : '';
            // this.taxi_capacity = attributes.taxi_capacity != undefined ? attributes.taxi_capacity : '';
            // this.taxi_fare_km = attributes.taxi_fare_km != undefined ? attributes.taxi_fare_km : '';
            // this.taxi_availability = attributes.taxi_availability != undefined ? attributes.taxi_availability : '';
            // --- End --- //
            this.max_luggage = attributes.max_luggage != undefined ? attributes.max_luggage : undefined;
            this.aux_taxi_image = attributes.taxi_image != null ? attributes.taxi_image : '';
            // YES
            this.is_nemt = attributes.is_nemt != null ? attributes.is_nemt : false;
            this.wheelchair_lift_ramp = attributes.wheelchair_lift_ramp != null ? attributes.wheelchair_lift_ramp : false;
            this.allow_transport_animals =
                attributes.allow_transport_animals != null ? attributes.allow_transport_animals : false;
            this.amenities_default = attributes.amenities_default != undefined ? attributes.amenities_default : [];
            this.amenities_default_nemt =
                attributes.amenities_default_nemt != undefined ? attributes.amenities_default_nemt : [];
        }
        else {
            this.is_nemt = false;
            // this.taxi_type = 1;
            // this.taxi_speed = 0;
            // this.taxi_capacity = 0;
            // this.taxi_fare_km = 1;
            this.taxi_availability = 'A';
            this.taxi_status = 'A';
            this.wheelchair_lift_ramp = false;
            this.amenities_default_nemt = [];
            this.amenities_default = [];
        }
    }
    Fleet.getFleetsList = function (item) {
        var _id = item._id, taxi_status = item.taxi_status, taxi_no = item.taxi_no, taxi_company = item.taxi_company, driver = item.driver, franchise = item.franchise, company = item.company, model = item.model, is_nemt = item.is_nemt, companyContactId = item.companyContactId, has_assignation = item.has_assignation, active_assignation = item.active_assignation;
        var company_name = (0, lodash_1.get)(company, 'company_name', '');
        var model_name = (0, lodash_1.get)(model, 'model_name', '');
        return {
            _id: _id,
            key: _id,
            taxi_status: taxi_status,
            taxi_no: taxi_no,
            company_name: company_name,
            model_name: model_name,
            franchise: (0, lodash_1.get)(franchise, 'legal_name', ''),
            is_nemt: is_nemt,
            assignation_id: (0, lodash_1.get)(active_assignation, 'id', 'null'),
            isAssignedToLicense: (0, lodash_1.get)(active_assignation, 'is_used_for_credit', 'false'),
            has_assignation: has_assignation,
            assignFleetData: {
                company: {
                    name: company_name,
                    _id: taxi_company,
                },
                companyContactId: companyContactId,
                driver: driver,
                fleet: item,
                key: _id,
                model: {
                    name: model_name,
                    _id: (0, lodash_1.get)(model, '_id', ''),
                },
                status: 'UNASSIGNED',
            },
        };
    };
    return Fleet;
}());
exports.Fleet = Fleet;
