"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.phoneNumberMask = exports.paymentMethod = void 0;
exports.paymentMethod = {
    1: "Cash",
    2: "Credit Card",
    3: "PAYPAL",
    5: "Wallet",
    6: "Insurance",
    7: "Prepaid",
};
var phoneNumberMask = function (number) {
    if (number === void 0) { number = ""; }
    var usMaskedNumber = number.match(/(\d{3})(\d{3})(\d{4})/);
    return number ? "".concat(usMaskedNumber[1], ".").concat(usMaskedNumber[2], ".").concat(usMaskedNumber[3]) : "-";
};
exports.phoneNumberMask = phoneNumberMask;
