"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
var PerMilesKmFaresModel = /** @class */ (function (_super) {
    __extends(PerMilesKmFaresModel, _super);
    function PerMilesKmFaresModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    //   list(config) {
    //     const schema = `
    //     query($keywords:String, $status:[UserStatusEnum], $userType:[UserTypeEnum],$companiesAdmin:[Int], $pageNumber:Int,$pageSize:Int,$_ids:[Int], $orderBy: [OrderByItemInput]){
    //       SearchUsers( input: {
    //           keywords: $keywords,
    //           status:$status,
    //           _ids:$_ids
    //           user_type:$userType,
    //           companiesAdmin:$companiesAdmin,
    //           page_number:$pageNumber,
    //           page_size: $pageSize,
    //           orderBy: $orderBy
    //       }){
    //         count
    //         items {
    //           _id
    //           name
    //           lastname
    //           email
    //           phone
    //           telephone_code
    //           address
    //           user_type
    //           status
    //           companyAdmin{
    //             _id,
    //             companydetails{
    //               company_name
    //             }
    //           }
    //           countryObj{
    //             _id
    //             country_name
    //           },
    //             stateObj{
    //             state_id
    //             state_name
    //           },
    //           cityObj{
    //             city_id
    //             city_name
    //           }
    //         }
    //        }
    //       }`;
    //     return this.query(schema, config);
    //   }
    PerMilesKmFaresModel.prototype.showFare = function (id) {
        var schema = "\n            query($id: String!, $token: String){\n                PublicGetFareCompany(fareId:$id, token: $token){\n                    fareId\n                    fareMiles {\n                        base_fare\n                        min_km\n                        min_fare\n                        cancellation_fare\n                        km_wise_fare\n                        additional_fare_per_km\n                        below_above_km\n                        below_km\n                        above_km\n                        waiting_time\n                        minutes_fare\n                        night_charge\n                        night_timing_from\n                        night_timing_to\n                        night_fare\n                        evening_charge\n                        evening_timing_from\n                        evening_timing_to\n                        evening_fare\n                    }\n                }\n            }";
        return this.publicQuery(schema, { id: id });
    };
    PerMilesKmFaresModel.prototype.showModel = function (id) {
        var schema = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      query($id: Float, $token: String) {\n        PublicGetModel(_id: $id, token: $token) {\n          _id\n          fareMiles {\n            base_fare\n            min_km\n            min_fare\n            cancellation_fare\n            km_wise_fare\n            additional_fare_per_km\n            below_above_km\n            below_km\n            above_km\n            waiting_time\n            minutes_fare\n            night_charge\n            night_timing_from\n            night_timing_to\n            night_fare\n            evening_charge\n            evening_timing_from\n            evening_timing_to\n            evening_fare\n          }\n        }\n      }\n    "], ["\n      query($id: Float, $token: String) {\n        PublicGetModel(_id: $id, token: $token) {\n          _id\n          fareMiles {\n            base_fare\n            min_km\n            min_fare\n            cancellation_fare\n            km_wise_fare\n            additional_fare_per_km\n            below_above_km\n            below_km\n            above_km\n            waiting_time\n            minutes_fare\n            night_charge\n            night_timing_from\n            night_timing_to\n            night_fare\n            evening_charge\n            evening_timing_from\n            evening_timing_to\n            evening_fare\n          }\n        }\n      }\n    "])));
        return this.publicQuery(schema, { id: id });
    };
    PerMilesKmFaresModel.prototype.storeFare = function (input) {
        var schema = "\n            mutation($input: CreateFareCompanyInput){\n            CreateFareCompany(input: $input){\n                fareId\n            }\n            }\n        ";
        return this.mutate(schema, { input: input });
    };
    PerMilesKmFaresModel.prototype.updateFare = function (input, franchiseIds) {
        var schema = "\n            mutation($input: UpdateFareMilesInput){\n                UpdateFareMilesCompany: PublicUpdateFareMilesCompany(input: $input){\n                  count                  \n                }\n            }\n        ";
        return this.onboardingMutation(schema, { input: input });
    };
    PerMilesKmFaresModel.prototype.updateModel = function (input, franchiseIds) {
        var schema = "\n        mutation ($input: UpdateModelFareMilesInput!, $franchiseIds: [Int]){\n            UpdateModelFareMiles(input: $input, franchiseIds: $franchiseIds){\n              count\n            }\n          }\n        ";
        return this.mutate(schema, { input: input, franchiseIds: franchiseIds });
    };
    return PerMilesKmFaresModel;
}(BaseModel_1.BaseModel));
var perMilesKmFaresModel = new PerMilesKmFaresModel();
exports.default = perMilesKmFaresModel;
var templateObject_1;
