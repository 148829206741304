"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CityFragment = exports.StateFragment = exports.CountryFragment = void 0;
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
exports.CountryFragment = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment CountryFragment on CountryOutput {\n    _id\n    country_name\n    telephone_code\n    default\n    iso_country_code\n  }\n"], ["\n  fragment CountryFragment on CountryOutput {\n    _id\n    country_name\n    telephone_code\n    default\n    iso_country_code\n  }\n"])));
exports.StateFragment = (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment StateFragment on StateOutput {\n    state_id\n    state_name\n    default\n  }\n"], ["\n  fragment StateFragment on StateOutput {\n    state_id\n    state_name\n    default\n  }\n"])));
exports.CityFragment = (0, apollo_boost_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fragment CityFragment on CityOutput {\n    city_id\n    city_name\n    zipcode\n    default\n  }\n"], ["\n  fragment CityFragment on CityOutput {\n    city_id\n    city_name\n    zipcode\n    default\n  }\n"])));
var CountryModels = /** @class */ (function (_super) {
    __extends(CountryModels, _super);
    function CountryModels() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CountryModels.prototype.list = function (config) {
        var schema = "\n    query ($input:SearchCountryInput){\n      GetCountries: PublicGetCountries(input: $input){\n       _id\n       country_name\n       iso_country_code\n       default\n      }\n    }";
        return this.onboardingQuery(schema, config);
    };
    CountryModels.prototype.get_all = function (config) {
        var schema = "query{\n  CountryList: PublicCountryList{\n    _id\n    country_name\n    currency_code\n    iso_country_code\n    telephone_code\n    stateinfo\n  }\n}";
        return this.onboardingQuery(schema, config);
    };
    CountryModels.prototype.phonCodeList = function (config) {
        var schema = "\n    query ($input:SearchCountryInput){\n      GetCountries: PublicGetCountries (input: $input){\n        _id\n        country_name\n        telephone_code\n      }\n    }";
        return this.onboardingQuery(schema, config);
    };
    CountryModels.prototype.statesList = function (config) {
        var schema = "\n    query ($input:SearchCountryInput){\n      GetCountries: PublicGetCountries(input: $input){\n        _id\n        country_name\n        stateinfo {\n          state_id\n          state_name\n          default\n        }\n      }\n    }";
        return this.onboardingQuery(schema, config);
    };
    CountryModels.prototype.citiesList = function (config, stateId) {
        var schema = "\n    query ($input:SearchCountryInput){\n      GetCountries: PublicGetCountries(input: $input){\n       _id\n       country_name\n      stateinfo(state_id:".concat(stateId, ") {\n       state_id\n       state_name\n       cityinfo{\n         city_id\n         city_name\n         default\n       }\n      }\n      }\n    }");
        return this.onboardingQuery(schema, config);
    };
    CountryModels.prototype.publicList = function (config) {
        var schema = "\n    query{\n      CountryList: PublicCountryList{\n        _id\n        country_name\n        currency_code\n        iso_country_code\n        telephone_code\n      }\n    }";
        return this.onboardingQuery(schema, config);
    };
    return CountryModels;
}(BaseModel_1.BaseModel));
var countryModel = new CountryModels();
exports.default = countryModel;
var templateObject_1, templateObject_2, templateObject_3;
