"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
require("./VoucherTripInfo.less");
require("../VoucherContainer.less");
var layout_helpers_1 = require("../../../shared/helpers/layout.helpers");
var semantic_ui_react_1 = require("semantic-ui-react");
var layout_hooks_1 = require("../../../shared/hooks/layout.hooks");
var voucher_hooks_1 = require("../Utils/voucher.hooks");
var Provider_1 = require("../Provider/Provider");
var Skeleton_1 = require("../../../shared/components/Skeleton/Skeleton");
var VoucherTripInfo = (0, react_1.memo)(function () {
    var booking = (0, Provider_1.useVoucherContext)().booking;
    var isResponsive = (0, layout_hooks_1.useResponsive)().isResponsive;
    var _a = (0, voucher_hooks_1.useGetAmenities)(booking === null || booking === void 0 ? void 0 : booking.amenities), loading = _a.loading, amenities = _a.amenities;
    return (react_1.default.createElement(antd_1.Row, null,
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24]), { className: "rz-info-spacing-header" }),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h3', block: true, inverted: true, className: "rz-details-header" }, "For your safety"),
            react_1.default.createElement("div", { className: "rz-info-safety-block" },
                "For your safety we recommend wearing your mask, however it is optional. Our drivers keep the recommended 1,5 meters distance when they greet passengers before the ride and thoroughly disinfect the vehicle after every transfer.",
                react_1.default.createElement("p", { className: "rz-info-spacing-text" }, "Please also keep the recommended distance and remember about the limit of passengers."))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24]), { className: "rz-info-spacing-header" }),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h3', block: true, inverted: true, className: "rz-details-header" }, "Special Notes"),
            react_1.default.createElement("div", { className: "rz-info-safety-block", style: { height: !(booking === null || booking === void 0 ? void 0 : booking.passengerNotes) ? 90 : "auto" } }, booking === null || booking === void 0 ? void 0 : booking.passengerNotes)),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24]), { className: "rz-info-spacing-header" }),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h3', block: true, inverted: true, className: "rz-details-header" }, "Meeting Point"),
            react_1.default.createElement("div", { className: "rz-info-safety-block" },
                "After collecting your luggage, please make your way to the arrival hall using any of the available exits. Your name will be displayed on a ",
                react_1.default.createElement("strong", null, (booking === null || booking === void 0 ? void 0 : booking.companyNameType) || "-"),
                " sign. In case you cannot find your driver please contact him directly using the mobile number sent to you via SMS.")),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24]), { className: "rz-info-spacing-header" }),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h3', block: true, inverted: true, className: "rz-details-header" }, "Amenities"),
            react_1.default.createElement(antd_1.Row, { className: "rz-info-amenity-section" },
                react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !loading }, amenities === null || amenities === void 0 ? void 0 : amenities.map(function (amenity) { return (react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([4, 4, 6, 6, 8]), { key: amenity._id }),
                    react_1.default.createElement("img", { className: "rz-info-amenities-image", key: amenity._id, src: amenity === null || amenity === void 0 ? void 0 : amenity.amenity_image }),
                    react_1.default.createElement("h4", { className: "rz-detail-text rz-display-content rz-text-capitalize" }, amenity === null || amenity === void 0 ? void 0 : amenity.amenity_name))); })))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24]), { className: "rz-info-spacing-header" }),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h3', block: true, inverted: true, className: "rz-details-header" }, "Flight Information"),
            react_1.default.createElement(antd_1.Row, { className: "rz-info-flight-section-row" },
                react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12]), { className: "rz-info-section-padding ".concat(!isResponsive ? 'rz-info-section-border' : "") }),
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-info-flight-text" }, "Flight Information:"),
                    react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                        react_1.default.createElement("div", { className: "rz-info-flight-section-details" },
                            react_1.default.createElement("h4", { className: "rz-detail-text" },
                                "Airline: ",
                                react_1.default.createElement("span", null, (booking === null || booking === void 0 ? void 0 : booking.airlineName) || "-")),
                            react_1.default.createElement("h4", { className: "rz-detail-text" },
                                "Flight Number: ",
                                react_1.default.createElement("span", null, (booking === null || booking === void 0 ? void 0 : booking.flightNumberPickup) || "-"))))),
                react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12]), { className: "rz-info-flight-contact-section" }),
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-info-flight-text" }, "Contact Us:"),
                    react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                        react_1.default.createElement("div", { className: "rz-info-flight-section-details" },
                            react_1.default.createElement("h4", { className: "rz-detail-text" },
                                "Email (24/7): ",
                                react_1.default.createElement("span", { className: "rz-info-flight-contact-text" }, booking === null || booking === void 0 ? void 0 : booking.contactUserEmail)),
                            react_1.default.createElement("h4", { className: "rz-detail-text" },
                                "Phone: ",
                                react_1.default.createElement("span", { className: "rz-info-flight-contact-text" }, booking === null || booking === void 0 ? void 0 : booking.contactUserPhone)))))))));
});
exports.default = VoucherTripInfo;
