"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var SubscriptionPlanModels = /** @class */ (function (_super) {
    __extends(SubscriptionPlanModels, _super);
    function SubscriptionPlanModels() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SubscriptionPlanModels.prototype.list = function (config) {
        var schema = "\n      query($filter: DriverSubscribePlanListInput!){\n        DriverSubscribePlanList(filter: $filter){\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            plan_name\n            plan_company\n            plan_description\n            plan_price\n            plan_duration\n            plan_duration_unit\n            plan_booking_limit\n            plan_status\n          }\n        }\n      }\n    ";
        return this.query(schema, config);
    };
    return SubscriptionPlanModels;
}(BaseModel_1.BaseModel));
var subscriptionPlanModels = new SubscriptionPlanModels();
exports.default = subscriptionPlanModels;
