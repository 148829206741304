"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoutesPoint = void 0;
var RoutesPoint = /** @class */ (function () {
    function RoutesPoint(item) {
        if (item === void 0) { item = {}; }
        var _this = this;
        this.getLatitudLongitud = function () {
            return {
                lat: parseFloat(_this.drop_latitude.toString()),
                lng: parseFloat(_this.drop_longitude.toString()),
            };
        };
        var _id = item._id, drop_latitude = item.drop_latitude, drop_longitude = item.drop_longitude, drop_location = item.drop_location, iataDropoff = item.iataDropoff, cityNameDropoff = item.cityNameDropoff, cityCodeDropoff = item.cityCodeDropoff, postalCodeDropoff = item.postalCodeDropoff, airlineCodeDropoff = item.airlineCodeDropoff, flightNumberDropoff = item.flightNumberDropoff, placeIdDropoff = item.placeIdDropoff, isAirportDropoff = item.isAirportDropoff, flightArrivalTimeDropoff = item.flightArrivalTimeDropoff, flightDepartureTimeDropoff = item.flightDepartureTimeDropoff, internalDescription = item.internalDescription;
        this._id = _id;
        this.drop_latitude = drop_latitude;
        this.drop_longitude = drop_longitude;
        this.drop_location = drop_location;
        this.iataDropoff = iataDropoff;
        this.cityNameDropoff = cityNameDropoff;
        this.cityCodeDropoff = cityCodeDropoff;
        this.postalCodeDropoff = postalCodeDropoff;
        this.airlineCodeDropoff = airlineCodeDropoff;
        this.flightNumberDropoff = flightNumberDropoff;
        this.isAirportDropoff = isAirportDropoff || false;
        this.placeIdDropoff = placeIdDropoff;
        this.flightArrivalTimeDropoff = flightArrivalTimeDropoff || '';
        this.flightDepartureTimeDropoff = flightDepartureTimeDropoff || '';
        this.internalDescription = internalDescription || '';
    }
    return RoutesPoint;
}());
exports.RoutesPoint = RoutesPoint;
