"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyStatusTypeEnum = void 0;
var CompanyStatusTypeEnum;
(function (CompanyStatusTypeEnum) {
    CompanyStatusTypeEnum["ACTIVE"] = "ACTIVE";
    CompanyStatusTypeEnum["BLOCK"] = "BLOCK";
    CompanyStatusTypeEnum["TRASH"] = "TRASH";
    CompanyStatusTypeEnum["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    CompanyStatusTypeEnum["TMP"] = "TMP";
})(CompanyStatusTypeEnum = exports.CompanyStatusTypeEnum || (exports.CompanyStatusTypeEnum = {}));
