"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var moment = require("moment");
var antd_1 = require("antd");
var Provider_1 = require("../Provider/Provider");
require("./VoucherTripDetails.less");
require("../VoucherContainer.less");
var layout_hooks_1 = require("../../../shared/hooks/layout.hooks");
var layout_helpers_1 = require("../../../shared/helpers/layout.helpers");
var semantic_ui_react_1 = require("semantic-ui-react");
var date_helpers_1 = require("../../../shared/helpers/date.helpers");
var Skeleton_1 = require("../../../shared/components/Skeleton/Skeleton");
var voucher_icon_1 = require("../Utils/voucher.icon");
var VoucherTripDetails = (0, react_1.memo)(function () {
    var _a, _b, _c, _d;
    var booking = (0, Provider_1.useVoucherContext)().booking;
    var _e = (0, layout_hooks_1.useResponsive)(), isIphone = _e.isIphone, isResponsive = _e.isResponsive;
    var childrenCount = (0, react_1.useMemo)(function () {
        return (booking === null || booking === void 0 ? void 0 : booking.childrenCategory1) + (booking === null || booking === void 0 ? void 0 : booking.childrenCategory2) + (booking === null || booking === void 0 ? void 0 : booking.childrenCategory3);
    }, [booking]);
    var dropTime = (0, react_1.useMemo)(function () { return (booking === null || booking === void 0 ? void 0 : booking.drop_time) || (booking === null || booking === void 0 ? void 0 : booking.returnTime) || (booking === null || booking === void 0 ? void 0 : booking.scheduled_time); }, [booking]);
    return (react_1.default.createElement(antd_1.Row, null,
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24])),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: 'h3', block: true, inverted: true, className: "rz-details-header" }, "Trip Details")),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([13]), { className: "rz-details-content" }),
            react_1.default.createElement(antd_1.Row, null,
                react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([2, 2, 2, 2, 2]), { className: "rz-details-route-section" }),
                    react_1.default.createElement(semantic_ui_react_1.Label, { className: "rz-label-point rzg-color-99E872", circular: true, empty: true }),
                    react_1.default.createElement("div", { className: "rz-vertical-line" }),
                    react_1.default.createElement(semantic_ui_react_1.Label, { className: "rz-label-point rzg-color-FF5252", circular: true, empty: true })),
                react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([22, 22, 22, 22, 22])),
                    react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                        react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title" }, "Pick-up Date and Time:"),
                        react_1.default.createElement("h3", { className: "rz-title-primary rz-details-title-trip" },
                            " ",
                            (0, date_helpers_1.format)(booking === null || booking === void 0 ? void 0 : booking.pickup_time, date_helpers_1.formatDate1)),
                        react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title" }, "Pick-up Location:"),
                        react_1.default.createElement("h3", { className: "rz-title-primary rz-details-title-trip" }, booking === null || booking === void 0 ? void 0 : booking.current_location)),
                    react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking, top: 180 },
                        react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title rz-details-space" }, "Drop-off Date and Time:"),
                        react_1.default.createElement("h3", { className: "rz-title-primary rz-details-title-trip" }, (0, date_helpers_1.format)(dropTime, date_helpers_1.formatDate1)),
                        react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title" }, "Drop-off Location:"),
                        react_1.default.createElement("h3", { className: "rz-title-primary rz-details-title-trip" }, booking === null || booking === void 0 ? void 0 : booking.drop_location))))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([11]), { className: "rz-details-section-images ".concat(isResponsive ? 'rz-text-center' : '') }),
            react_1.default.createElement(semantic_ui_react_1.Feed, { className: "rz-details-driver-section-space" },
                react_1.default.createElement(semantic_ui_react_1.Feed.Event, null,
                    react_1.default.createElement(Skeleton_1.SkeletonImage, { condition: !!booking },
                        react_1.default.createElement(semantic_ui_react_1.Feed.Label, { className: "rz-details-driver-image", image: (_a = booking === null || booking === void 0 ? void 0 : booking.driver) === null || _a === void 0 ? void 0 : _a.profile_picture })),
                    react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                        react_1.default.createElement(semantic_ui_react_1.Feed.Content, { className: "rz-details-image-driver" },
                            react_1.default.createElement(semantic_ui_react_1.Feed.Date, { className: "rz-details-space-driver-title" },
                                react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title" }, (_b = booking === null || booking === void 0 ? void 0 : booking.driver) === null || _b === void 0 ? void 0 : _b.name)),
                            react_1.default.createElement(semantic_ui_react_1.Feed.Date, { className: "rz-details-space-driver-title rz-min-font-size", content: "Driver" }),
                            react_1.default.createElement(semantic_ui_react_1.Feed.Date, { className: "rz-details-space-driver-title" },
                                react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title" }, (_c = booking === null || booking === void 0 ? void 0 : booking.taxi) === null || _c === void 0 ? void 0 : _c.licence_number)),
                            react_1.default.createElement(semantic_ui_react_1.Feed.Date, { className: "rz-details-space-driver-title rz-min-font-size", content: "Lic. Number" }))))),
            react_1.default.createElement(Skeleton_1.SkeletonImage, { condition: !!booking, width: 275, top: 45, height: 150 },
                react_1.default.createElement("img", { width: isIphone ? 245 : 275, src: (_d = booking === null || booking === void 0 ? void 0 : booking.taxi) === null || _d === void 0 ? void 0 : _d.taxi_image }))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12])),
            react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-column-title" }, "Passenger Details:"),
            react_1.default.createElement(antd_1.Badge, { count: (booking === null || booking === void 0 ? void 0 : booking.peopleNumber) || 0, className: !isResponsive ? "rz-details-icon-spacing" : "rz-details-icon-spacing-r", showZero: true },
                react_1.default.createElement(semantic_ui_react_1.Icon, { className: "rz-black-color rz-details-size-icon", name: "users" })),
            react_1.default.createElement(antd_1.Badge, { count: (booking === null || booking === void 0 ? void 0 : booking.luggage) || 0, className: !isResponsive ? "rz-details-icon-spacing" : "rz-details-icon-spacing-r", showZero: true },
                react_1.default.createElement(voucher_icon_1.SuiteCaseIcon, { className: "rz-custom-icon-position" })),
            react_1.default.createElement(antd_1.Badge, { count: childrenCount || 0, className: !isResponsive ? "rz-details-icon-spacing" : "rz-details-icon-spacing-r", showZero: true },
                react_1.default.createElement(semantic_ui_react_1.Icon, { className: "rz-black-color rz-details-size-icon", name: "child" })),
            react_1.default.createElement(antd_1.Badge, { count: (booking === null || booking === void 0 ? void 0 : booking.animalsNumber) || 0, className: !isResponsive ? "rz-details-icon-spacing" : "rz-details-icon-spacing-r", showZero: true },
                react_1.default.createElement(semantic_ui_react_1.Icon, { className: "rz-black-color rz-details-size-icon", name: "paw" }))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([12]), { className: "".concat(isResponsive ? 'rz-text-start' : 'rz-text-end') }),
            react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking },
                react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-details-total-fare" },
                    "Total Fare: ",
                    react_1.default.createElement("span", { className: isResponsive ? "rz-details-total-fare-number-r" : "rz-details-total-fare-number" },
                        "$", booking === null || booking === void 0 ? void 0 :
                        booking.approx_fare)))),
        react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([24])),
            react_1.default.createElement(antd_1.Row, null,
                react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([8])), (booking === null || booking === void 0 ? void 0 : booking.hasPassengerApp) && [
                    react_1.default.createElement("img", { src: "src/images/apple_store1.jpg", width: 120 }),
                    react_1.default.createElement("img", { src: "src/images/google_play1.jpg", width: 120, className: "rz-details-app-position" })
                ]),
                react_1.default.createElement(antd_1.Col, __assign({}, (0, layout_helpers_1.cl)([16]), { className: "".concat(isResponsive ? 'rz-text-start' : 'rz-text-end') }),
                    react_1.default.createElement(Skeleton_1.SkeletonDetails, { condition: !!booking, className: "rz-details-skeleton-ride" },
                        react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-details-trip-info rz-details-trip-info-text" },
                            "Ride Time: ",
                            (0, date_helpers_1.timeDiffTrip)(moment(dropTime), moment(booking === null || booking === void 0 ? void 0 : booking.pickup_time)),
                            " Taxes and Fees Included.")),
                    react_1.default.createElement(semantic_ui_react_1.Header, { as: "h6", className: "rz-details-trip-info-text" }, "*Cancellation Penalty Applies if cancel 24 hours prior Pick-up time."))))));
});
exports.default = VoucherTripDetails;
