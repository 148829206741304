"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.personInfo = exports.routeInfo = exports.renderPersonColumn = exports.renderCompanyColumn = exports.renderLinkColumns = exports.renderPhoto = exports.renderNemtColumns = exports.renderStatusColumns = exports.formatColumnFilter = exports.renderColumnsNumber = exports.renderColumnsDate = exports.renderColumns = void 0;
var React = __importStar(require("react"));
var antd_1 = require("antd");
var number_helpers_1 = require("./number.helpers");
var string_helpers_1 = require("./string.helpers");
var date_helpers_1 = require("./date.helpers");
var lodash_1 = require("lodash");
var renderColumns = function (item, limit, visibleToolTip) {
    if (limit === void 0) { limit = 20; }
    if (visibleToolTip === void 0) { visibleToolTip = null; }
    var field = (0, string_helpers_1.fieldLimit)(item, limit);
    var tooltipProps = visibleToolTip ? { visible: visibleToolTip } : {};
    return field !== item ? (React.createElement(antd_1.Tooltip, __assign({ title: item, placement: "topLeft" }, tooltipProps), field)) : (item);
};
exports.renderColumns = renderColumns;
var renderColumnsDate = function (date) {
    return !(0, string_helpers_1.isNullOrUndefined)(date) ? (React.createElement("h4", null, (0, exports.renderColumns)((0, date_helpers_1.format)(date, "MM-DD-YYYY HH:mm:ss"), 19))) : ("");
};
exports.renderColumnsDate = renderColumnsDate;
var renderColumnsNumber = function (number, style) {
    return (React.createElement("span", { style: style }, !(0, string_helpers_1.isNullOrUndefinedOrZero)(number) ? "$ ".concat((0, number_helpers_1.twoDecimal)(number)) : "$ 0.00"));
};
exports.renderColumnsNumber = renderColumnsNumber;
var formatColumnFilter = function (columnsList) {
    return columnsList
        ? columnsList.map(function (columns) { return ({
            title: columns.title,
            checked: true,
            value: columns.dataIndex,
            filtered: true,
        }); })
        : [];
};
exports.formatColumnFilter = formatColumnFilter;
var renderStatusColumns = function (status, t) {
    switch (status) {
        case "ACTIVE":
        case "A":
        case "AS":
        case 1:
            return (React.createElement(antd_1.Tooltip, { title: t ? t("status.Active") : "Active", placement: "top" },
                React.createElement(antd_1.Icon, { type: "check-circle", style: { fontSize: "16px", color: "green" } })));
        case "BLOCK":
        case "BUSY":
        case "B":
        case "D":
        case 2:
            return (React.createElement(antd_1.Tooltip, { title: t ? t("status.Block") : "Block", placement: "top" },
                React.createElement(antd_1.Icon, { type: "close-circle", style: { fontSize: "16px", color: "#dd0000" } })));
        case "INACTIVE":
        case "I":
            return (React.createElement(antd_1.Tooltip, { title: t ? t("status.Inactive") : "Inactive", placement: "top" },
                React.createElement(antd_1.Icon, { type: "close-circle", style: { fontSize: "16px", color: "#dd0000" } })));
        case "TRASH":
        case "T":
        case 4:
            return (React.createElement(antd_1.Tooltip, { title: t ? t("status.Trash") : "Trash", placement: "top" },
                React.createElement(antd_1.Icon, { type: "delete", style: { fontSize: "16px", color: "#737171" } })));
        case "PENDING_APPROVAL":
        case "PENDING":
        case "P":
        case 3:
            return (React.createElement(antd_1.Tooltip, { title: t ? t("status.Pending Approval") : "Pending Approval", placement: "top" },
                React.createElement(antd_1.Icon, { type: "clock-circle", style: { fontSize: "16px", color: "rgb(73 152 237)" } })));
        case "UNASSIGNED":
        case "U":
            return (React.createElement(antd_1.Tooltip, { title: t ? t("manage_fleets.Unassigned") : "Unassigned", placement: "top" },
                React.createElement(antd_1.Icon, { type: "stop", style: { fontSize: "16px", color: "rgb(52 107 0)" } })));
    }
};
exports.renderStatusColumns = renderStatusColumns;
var renderNemtColumns = function (isNemt, iconWidth) {
    return (React.createElement("img", { style: iconWidth || { width: "28px" }, src: "src/nemt/nemt_".concat(isNemt ? "active" : "disabled", ".svg") }));
};
exports.renderNemtColumns = renderNemtColumns;
var renderPhoto = function (photo) {
    var iconWidth = { width: "28px" };
    if (photo)
        return React.createElement("img", { style: iconWidth, src: photo });
    else
        return React.createElement("img", { style: iconWidth, src: photo });
};
exports.renderPhoto = renderPhoto;
var renderLinkColumns = function (link, id, name, label) {
    return (React.createElement(antd_1.Row, { style: { textAlign: "start" } },
        React.createElement(antd_1.Col, null,
            React.createElement("label", null,
                label ? label + ": " : "",
                React.createElement("a", { style: { color: "blue" }, href: "".concat(link).concat(id) }, name)))));
};
exports.renderLinkColumns = renderLinkColumns;
var renderCompanyColumn = function (companyName, companyId) {
    return (React.createElement(antd_1.Row, null,
        React.createElement(antd_1.Col, null,
            React.createElement("label", null,
                React.createElement("a", { style: { color: "blue" }, href: "/analytics/companyinfo/".concat(companyId) }, companyName)))));
};
exports.renderCompanyColumn = renderCompanyColumn;
var renderPersonColumn = function (person, renderMoreInfo) {
    return person ? (React.createElement(antd_1.Row, { style: { textAlign: "start" } },
        React.createElement(antd_1.Col, null,
            React.createElement(antd_1.Tooltip, { title: (0, exports.personInfo)(person), placement: "top", overlayStyle: { backgroundColor: "white", color: "black" } },
                React.createElement("label", null, person.urlInfo ? (React.createElement("a", { style: { color: "blue" }, href: "".concat(person.urlInfo).concat((0, lodash_1.get)(person, "id", "")) }, (0, exports.renderColumns)((0, lodash_1.get)(person, "name", ""), 17, false))) : ((0, exports.renderColumns)((0, lodash_1.get)(person, "name", ""), 17, false))))),
        React.createElement(antd_1.Col, null, renderMoreInfo))) : ("");
};
exports.renderPersonColumn = renderPersonColumn;
var routeInfo = function (route, t) {
    return (React.createElement(antd_1.Row, { gutter: 8 },
        React.createElement(antd_1.Col, { span: 24 },
            React.createElement("label", null,
                t ? t("manageRoute.Route Name") : "Route Name",
                ":",
                " ",
                (0, lodash_1.get)(route, "name", "-"))),
        React.createElement(antd_1.Col, { span: 24 },
            React.createElement(antd_1.Icon, { type: "environment" }),
            " ", " ".concat((0, lodash_1.get)(route, "location", "-")))));
};
exports.routeInfo = routeInfo;
var personInfo = function (person) {
    return (React.createElement(antd_1.Row, { gutter: 8 },
        React.createElement(antd_1.Col, { span: 24, style: { textAlign: "center" } },
            React.createElement("img", { style: { width: 65 }, src: (0, lodash_1.get)(person, "photo", "") }),
            React.createElement(antd_1.Divider, { style: { marginBottom: 3, marginTop: 4 } })),
        React.createElement(antd_1.Col, { span: 24 },
            React.createElement("label", null,
                "Name: ",
                (0, lodash_1.get)(person, "name", "-"))),
        React.createElement(antd_1.Col, { span: 24 },
            React.createElement("label", null,
                "Email: ",
                (0, lodash_1.get)(person, "email", "-"))),
        React.createElement(antd_1.Col, { span: 24 },
            React.createElement("label", null,
                "Phone: ",
                (0, lodash_1.get)(person, "phone", "-")))));
};
exports.personInfo = personInfo;
