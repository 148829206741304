"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var antd_1 = require("antd");
var react_i18next_1 = require("react-i18next");
var styles = require('./Number.scss');
var FormItem = antd_1.Form.Item;
var Number = /** @class */ (function (_super) {
    __extends(Number, _super);
    function Number(props) {
        var _this = _super.call(this, props) || this;
        _this.validateNumber = function (rule, value, callback) {
            var _a = _this.props, minFieldLength = _a.minFieldLength, minFieldLengthMsg = _a.minFieldLengthMsg, label = _a.label, re = new RegExp("^\\d{".concat(minFieldLength, "}$"), 'gi');
            var errors = [];
            if (value && minFieldLength && !re.test(value.toString())) {
                errors.push(new Error(minFieldLengthMsg
                    ? minFieldLengthMsg
                    : _this.props.t('validation.The (label) must have (length) number(s)', { label: label, length: length })));
            }
            callback(errors);
        };
        _this.component = {};
        return _this;
    }
    Number.onKeyDown = function (e) {
        var w = e.which;
        //TODO block ctrl+v keys to paste
        if (!(/^\d$/.test(e.key) ||
            w === 8 ||
            w === 9 ||
            w === 46 ||
            (w === 86 && e.ctrlKey) ||
            w === 116 ||
            (w > 36 && w < 41))) {
            e.preventDefault();
        }
    };
    Number.prototype.render = function () {
        var me = this;
        var _a = me.props, form = _a.form, label = _a.label, value = _a.value, propertyName = _a.propertyName, required = _a.required, step = _a.step, min = _a.min, max = _a.max, formItemLayout = _a.formItemLayout, maxLength = _a.maxLength, asLetter = _a.asLetter, size = _a.size, addonBefore = _a.addonBefore, formItemStyle = _a.formItemStyle, itemClassName = _a.itemClassName, formatter = _a.formatter, placeholder = _a.placeholder, disabled = _a.disabled, requiredMsg = _a.requiredMsg, rules = _a.rules, othersProps = __rest(_a, ["form", "label", "value", "propertyName", "required", "step", "min", "max", "formItemLayout", "maxLength", "asLetter", "size", "addonBefore", "formItemStyle", "itemClassName", "formatter", "placeholder", "disabled", "requiredMsg", "rules"]);
        var layout = formItemLayout || {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
                span: 8,
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 10 },
                span: 10,
            },
        };
        var t = this.props.t;
        var getFieldDecorator = form.getFieldDecorator;
        return (React.createElement(FormItem, __assign({ hasFeedback: true, className: itemClassName, label: label }, layout, { style: formItemStyle }), getFieldDecorator(propertyName, {
            initialValue: value,
            labelAlign: 'left',
            rules: __spreadArray([
                {
                    required: required,
                    message: requiredMsg || "".concat(t('validation.Please input'), " ").concat(label || placeholder, "!"),
                },
                {
                    pattern: /^\d+(\.{0,1}\d+)*$/gi,
                    message: t('validation.This field only accepts numbers'),
                },
                {
                    validator: this.validateNumber,
                }
            ], (rules || []), true),
        })(asLetter ? (React.createElement(antd_1.Input, __assign({ onChange: this.props.onChange, maxLength: maxLength || 10, onKeyDown: Number.onKeyDown, className: styles['ant-input-number'], size: size, addonBefore: addonBefore, placeholder: placeholder, disabled: disabled }, othersProps))) : (React.createElement(antd_1.InputNumber, __assign({ onChange: this.props.onChange, maxLength: maxLength || 10, onKeyDown: Number.onKeyDown, step: step || 1, placeholder: placeholder, formatter: formatter, min: min, max: max, className: styles['ant-input-number'], size: size }, othersProps))))));
    };
    return Number;
}(React.Component));
exports.default = (0, react_i18next_1.withNamespaces)('pages')(Number);
