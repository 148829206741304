"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.scalingColumns = void 0;
var antd_1 = require("antd");
var React = __importStar(require("react"));
var Provider_1 = require("../../../../../../shared/context/Provider");
require("../FaresWizardStepFour.less");
var decorator_helpers_1 = require("../../../../../../shared/helpers/decorator.helpers");
var EditableContext = React.createContext({});
var EditableRow = function (_a) {
    var form = _a.form, index = _a.index, props = __rest(_a, ["form", "index"]);
    return (React.createElement(EditableContext.Provider, { value: form },
        React.createElement("tr", __assign({}, props))));
};
var EditableFormRow = antd_1.Form.create()(EditableRow);
var EditableCell = /** @class */ (function (_super) {
    __extends(EditableCell, _super);
    function EditableCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            editing: false,
        };
        _this.toggleEdit = function () {
            var editing = !_this.state.editing;
            _this.setState({ editing: editing }, function () {
                if (editing) {
                    _this.input.focus();
                }
            });
        };
        _this.save = function (e) {
            var _a = _this.props, record = _a.record, handleSave = _a.handleSave;
            _this.form.validateFields(function (error, values) {
                if (error && error[e.currentTarget.id]) {
                    return;
                }
                _this.toggleEdit();
                handleSave(__assign(__assign({}, record), values));
            });
        };
        _this.renderCell = function (form) {
            _this.form = form;
            var _a = _this.props, children = _a.children, dataIndex = _a.dataIndex, record = _a.record, t = _a.t;
            var editing = _this.state.editing;
            return editing ? (React.createElement(antd_1.Form.Item, { style: { margin: -5 } }, form.getFieldDecorator(dataIndex, {
                rules: [
                    {
                        required: true,
                        message: t("validation.Field required"),
                    },
                ],
                initialValue: record[dataIndex],
            })(React.createElement(antd_1.Input, { type: "number", ref: function (node) { return (_this.input = node); }, onPressEnter: _this.save, onBlur: _this.save, min: 0 })))) : (React.createElement(antd_1.Row, { className: "editable-cell-value-wrap", onClick: _this.toggleEdit },
                React.createElement(antd_1.Col, { span: 20 }, children),
                React.createElement(antd_1.Col, { span: 4, className: "editable-table-edit-icon" },
                    React.createElement(antd_1.Tooltip, { title: t("component.Edit"), placement: "top" },
                        React.createElement(antd_1.Icon, { type: "edit" })))));
        };
        return _this;
    }
    EditableCell.prototype.render = function () {
        var _a = this.props, editable = _a.editable, children = _a.children, restProps = __rest(_a, ["editable", "children"]);
        return (React.createElement("td", __assign({}, restProps), editable ? (React.createElement(EditableContext.Consumer, null, this.renderCell)) : (children)));
    };
    EditableCell = __decorate([
        (0, decorator_helpers_1.injectI18n)("pages")
    ], EditableCell);
    return EditableCell;
}(React.Component));
var EditableTable = /** @class */ (function (_super) {
    __extends(EditableTable, _super);
    function EditableTable(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSave = function (row) {
            var fare_scaling_hours = _this.context.state.fare_scaling_hours;
            var newData = fare_scaling_hours.map(function (item) {
                if (row._id === item._id && row.key === item.key)
                    return __assign(__assign({}, item), row);
                return item;
            });
            _this.context.setState({ fare_scaling_hours: newData });
        };
        return _this;
    }
    EditableTable.prototype.render = function () {
        var _this = this;
        var fare_scaling_hours = this.context.state.fare_scaling_hours;
        var t = this.props.t;
        var components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        var columns = (0, exports.scalingColumns)(t).map(function (col) {
            if (!col.editable) {
                return col;
            }
            return __assign(__assign({}, col), { onCell: function (record) { return ({
                    record: record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: _this.handleSave,
                }); } });
        });
        return (React.createElement(antd_1.Table, { components: components, rowClassName: function () { return 'editable-row'; }, size: "small", dataSource: fare_scaling_hours, columns: columns, pagination: false }));
    };
    EditableTable.contextType = Provider_1.CustomContext;
    EditableTable = __decorate([
        (0, decorator_helpers_1.injectI18n)("pages")
    ], EditableTable);
    return EditableTable;
}(React.Component));
exports.default = React.memo(EditableTable);
var scalingColumns = function (t) { return [
    {
        title: t("manageFare.From"),
        dataIndex: 'scaling_hours_from',
        key: 'from',
        editable: true,
        width: '30%',
        render: function (text) { return React.createElement("a", null, text); },
    },
    {
        title: t("manageFare.To"),
        dataIndex: 'scaling_hours_to',
        key: 'to',
        editable: true,
        width: '20%',
    },
    {
        title: t("manageFare.Rate Per Hours ($)"),
        dataIndex: 'scaling_rate_hours',
        key: 'scaling_rate_hours',
        align: "center",
        width: '35%',
        editable: true,
    },
    {
        title: t("common_field.Actions"),
        key: '',
        align: "right",
        render: function (item) { return React.createElement(ActionColumns, { id: (item === null || item === void 0 ? void 0 : item._id) || (item === null || item === void 0 ? void 0 : item.key), t: t }); },
    },
]; };
exports.scalingColumns = scalingColumns;
var ActionColumns = function (_a) {
    var id = _a.id, t = _a.t;
    var _b = (0, Provider_1.useProvidedContext)(), scalingHours = _b.state.fare_scaling_hours, setState = _b.setState;
    return (scalingHours.length >= 1 ? (React.createElement(antd_1.Popconfirm, { title: t("manageFare.Sure to delete"), onConfirm: function () {
            return setState({ fare_scaling_hours: scalingHours.filter(function (scaling) { return (scaling._id !== id && scaling.key !== id); }) });
        } },
        React.createElement("a", { className: "scaling-table-actions" }, t("component.Delete")))) : null);
};
