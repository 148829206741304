"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModel = void 0;
require("whatwg-fetch");
var apollo_boost_1 = require("apollo-boost");
var graphql_helpers_1 = require("../helpers/graphql.helpers");
var API = process.env.API;
var TOKEN = process.env.API_TOKEN;
var REST_API = process.env.REST_API;
var client_id = process.env.client_id;
var client_secret = process.env.client_secret;
var ONBOARDING_KEY = process.env.ONBOARDING_KEY;
var httpLink = new apollo_boost_1.HttpLink({ uri: API });
var httpPublicLink = new apollo_boost_1.HttpLink({ uri: API + '/public' });
var BaseModel = /** @class */ (function () {
    function BaseModel() {
    }
    BaseModel.prototype.login = function (data) {
        var token = data.token, user = data.user;
        if (token) {
            sessionStorage.setItem('jwtDataLPage', JSON.stringify(data));
        }
    };
    BaseModel.prototype.logout = function () {
        sessionStorage.removeItem('jwtDataLPage');
    };
    BaseModel.prototype.getSessionData = function () {
        var jwtDataLPage = sessionStorage.getItem('jwtDataLPage');
        if (jwtDataLPage) {
            return new Promise(function (resolve, reject) {
                resolve(JSON.parse(jwtDataLPage));
            });
        }
        //La variable input se puede cmabiar por REST_API+'/oauth/token'
        return fetch(REST_API + '/oauth/token', {
            credentials: 'same-origin',
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                "grant_type": "client_credentials",
                "client_id": client_id,
                "client_secret": client_secret,
                "scope": "*"
            })
        })
            .then(function (r) {
            var data = r.json();
            return data;
        })
            .then(function (r) {
            if (r && r.access_token) {
                return {
                    token: r.access_token,
                    user: {},
                };
            }
        }).catch(function (c) {
            console.log('error');
        });
    };
    BaseModel.prototype.createClient = function (token) {
        var authLink = new apollo_boost_1.ApolloLink(function (operation, forward) {
            var headers = {};
            // if (token) {
            headers = {
                Authorization: "Bearer ".concat(TOKEN),
            };
            // }
            operation.setContext({
                headers: headers,
            });
            return forward(operation);
        });
        return new apollo_boost_1.ApolloClient({
            link: authLink.concat(httpLink),
            cache: new apollo_boost_1.InMemoryCache(),
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
            },
        });
    };
    BaseModel.prototype.getClient = function () {
        var _this = this;
        return this.getSessionData().then(function (response) {
            var token = response.token;
            var client;
            if (_this.client) {
                client = _this.client;
            }
            else {
                _this.login({ user: {}, token: token });
                client = _this.createClient(token);
            }
            return Promise.resolve(client);
        });
    };
    BaseModel.prototype.request = function (callback) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getSessionData().then(function (response) {
                var token = response.token;
                var client;
                if (_this.client) {
                    client = _this.client;
                }
                else {
                    _this.login({ user: {}, token: token });
                    client = _this.createClient(token);
                }
                resolve(callback(client));
            });
        });
    };
    BaseModel.prototype.query = function (schema, variables) {
        if (variables === void 0) { variables = undefined; }
        return this.request(function (client) {
            return client.query({
                query: (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                    ", "\n                "], ["\n                    ", "\n                "])), schema),
                variables: variables,
            });
        });
    };
    BaseModel.prototype.mutate = function (schema, variables) {
        return this.request(function (client) {
            return client.mutate({
                mutation: (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                    ", "\n                "], ["\n                    ", "\n                "])), schema),
                variables: variables,
            });
        });
    };
    //Onboarding and Landing page Graphql Architecture
    BaseModel.prototype.publicClient = function () {
        return new apollo_boost_1.ApolloClient({
            link: httpPublicLink,
            cache: new apollo_boost_1.InMemoryCache(),
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
            },
        });
    };
    BaseModel.prototype.publicQuery = function (gqlSchema, variables) {
        var _this = this;
        if (variables === void 0) { variables = undefined; }
        var token = localStorage.getItem('jwt-onboarding');
        var schema = (0, apollo_boost_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject([" ", ""], [" ", ""])), gqlSchema);
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var response, _a, error_1, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 8]);
                        return [4 /*yield*/, this.publicClient()
                                .query({ query: schema, variables: __assign({ token: token }, variables) })];
                    case 1:
                        response = _d.sent();
                        _a = resolve;
                        return [4 /*yield*/, this.resolveRequest(response, { schema: schema, variables: variables })];
                    case 2:
                        _a.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 8];
                    case 3:
                        error_1 = _d.sent();
                        if (!((0, graphql_helpers_1.getGraphqlErrorMessage)(error_1) === 'Your token has not been verified.')) return [3 /*break*/, 5];
                        _b = resolve;
                        return [4 /*yield*/, this.resolveRequest(error_1, { schema: schema, variables: variables })];
                    case 4:
                        _b.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 7];
                    case 5:
                        _c = reject;
                        return [4 /*yield*/, this.resolveRequest(error_1, { schema: schema, variables: variables })];
                    case 6:
                        _c.apply(void 0, [_d.sent()]);
                        _d.label = 7;
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); });
    };
    BaseModel.prototype.publicMutation = function (gqlSchema, variables) {
        var _this = this;
        if (variables === void 0) { variables = undefined; }
        var token = localStorage.getItem('jwt-onboarding');
        var schema = (0, apollo_boost_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject([" ", ""], [" ", ""])), gqlSchema);
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var response, _a, error_2, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 8]);
                        return [4 /*yield*/, this.publicClient()
                                .mutate({ mutation: schema, variables: __assign({ token: token }, variables) })];
                    case 1:
                        response = _d.sent();
                        _a = resolve;
                        return [4 /*yield*/, this.resolveRequest(response, { schema: schema, variables: variables })];
                    case 2:
                        _a.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 8];
                    case 3:
                        error_2 = _d.sent();
                        if (!((0, graphql_helpers_1.getGraphqlErrorMessage)(error_2) === 'Your token has not been verified.')) return [3 /*break*/, 5];
                        _b = resolve;
                        return [4 /*yield*/, this.resolveRequest(error_2, { schema: schema, variables: variables })];
                    case 4:
                        _b.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 7];
                    case 5:
                        _c = reject;
                        return [4 /*yield*/, this.resolveRequest(error_2, { schema: schema, variables: variables })];
                    case 6:
                        _c.apply(void 0, [_d.sent()]);
                        _d.label = 7;
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); });
    };
    BaseModel.prototype.onboardingClient = function () {
        var authLink = new apollo_boost_1.ApolloLink(function (operation, forward) {
            var token = localStorage.getItem('jwt-onboarding');
            operation.setContext({
                headers: {
                    'Authorization-Public': token
                },
            });
            return forward(operation);
        });
        return new apollo_boost_1.ApolloClient({
            link: authLink.concat(httpPublicLink),
            cache: new apollo_boost_1.InMemoryCache({
                addTypename: false
            }),
        });
    };
    BaseModel.prototype.onboardingQuery = function (gqlSchema, variables) {
        var _this = this;
        if (variables === void 0) { variables = undefined; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var schema, response, _a, error_3, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        schema = (0, apollo_boost_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject([" ", ""], [" ", ""])), gqlSchema);
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 9]);
                        return [4 /*yield*/, this.onboardingClient()
                                .query({ query: schema, variables: variables, })];
                    case 2:
                        response = _d.sent();
                        _a = resolve;
                        return [4 /*yield*/, this.resolveRequest(response, { schema: schema, variables: variables }, false)];
                    case 3:
                        _a.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 9];
                    case 4:
                        error_3 = _d.sent();
                        if (!((0, graphql_helpers_1.getGraphqlErrorMessage)(error_3) === 'Your token has not been verified.')) return [3 /*break*/, 6];
                        _b = resolve;
                        return [4 /*yield*/, this.resolveRequest(error_3, { schema: schema, variables: variables }, false)];
                    case 5:
                        _b.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 8];
                    case 6:
                        _c = reject;
                        return [4 /*yield*/, this.resolveRequest(error_3, { schema: schema, variables: variables }, false)];
                    case 7:
                        _c.apply(void 0, [_d.sent()]);
                        _d.label = 8;
                    case 8: return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    };
    BaseModel.prototype.onboardingMutation = function (gqlSchema, variables) {
        var _this = this;
        if (variables === void 0) { variables = undefined; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var schema, response, _a, error_4, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        schema = (0, apollo_boost_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject([" ", ""], [" ", ""])), gqlSchema);
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 9]);
                        return [4 /*yield*/, this.onboardingClient()
                                .mutate({ mutation: schema, variables: variables, })];
                    case 2:
                        response = _d.sent();
                        _a = resolve;
                        return [4 /*yield*/, this.resolveRequest(response, { schema: schema, variables: variables }, false)];
                    case 3:
                        _a.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 9];
                    case 4:
                        error_4 = _d.sent();
                        if (!((0, graphql_helpers_1.getGraphqlErrorMessage)(error_4) === 'Your token has not been verified.')) return [3 /*break*/, 6];
                        _b = resolve;
                        return [4 /*yield*/, this.resolveRequest(error_4, { schema: schema, variables: variables }, false)];
                    case 5:
                        _b.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 8];
                    case 6:
                        _c = reject;
                        return [4 /*yield*/, this.resolveRequest(error_4, { schema: schema, variables: variables }, false)];
                    case 7:
                        _c.apply(void 0, [_d.sent()]);
                        _d.label = 8;
                    case 8: return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    };
    BaseModel.prototype.resolveRequest = function (response, gqlSchema, hasInnerToken) {
        if (hasInnerToken === void 0) { hasInnerToken = true; }
        return __awaiter(this, void 0, void 0, function () {
            var token, response_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((0, graphql_helpers_1.getGraphqlErrorMessage)(response) === 'Your token has not been verified.' || (0, graphql_helpers_1.getGraphqlErrorMessage)(response) === 'Your token has expired.')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getIdentificationToken()];
                    case 1:
                        token = (_b.sent()).data.GetIdentificationToken.token;
                        localStorage.setItem('jwt-onboarding', token);
                        return [4 /*yield*/, this[(0, graphql_helpers_1.getSchemaType)(gqlSchema, hasInnerToken).client]()[(0, graphql_helpers_1.getSchemaType)(gqlSchema).functionType]((_a = {},
                                _a[(0, graphql_helpers_1.getSchemaType)(gqlSchema).resolveType] = (0, apollo_boost_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject([" ", ""], [" ", ""])), gqlSchema.schema),
                                _a.variables = hasInnerToken ? __assign({ token: token }, gqlSchema.variables) : gqlSchema.variables,
                                _a))];
                    case 2:
                        response_1 = _b.sent();
                        return [2 /*return*/, response_1];
                    case 3: return [2 /*return*/, response];
                }
            });
        });
    };
    BaseModel.prototype.getIdentificationToken = function () {
        var mutation = (0, apollo_boost_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n            mutation($input: IdentificationTokenInput!){\n                GetIdentificationToken(input: $input){\n                    token\n                }\n            }\n        "], ["\n            mutation($input: IdentificationTokenInput!){\n                GetIdentificationToken(input: $input){\n                    token\n                }\n            }\n        "])));
        return this.publicClient().mutate({ mutation: mutation, variables: { input: { key: ONBOARDING_KEY } } });
    };
    return BaseModel;
}());
exports.BaseModel = BaseModel;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
