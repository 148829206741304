"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./Company.less");
var decorator_helpers_1 = require("../../../../shared/helpers/decorator.helpers");
var lodash_1 = require("lodash");
var CompanyInfo = /** @class */ (function (_super) {
    __extends(CompanyInfo, _super);
    function CompanyInfo(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            data: {}
        };
        return _this;
    }
    CompanyInfo.prototype.render = function () {
        var _this = this;
        var _a = this.props, isXsResponsive = _a.isXsResponsive, loading = _a.loading, _b = _a.company, companydetails = _b.companydetails, _c = _b.contact, email = _c.email, phone = _c.phone, telephone_code = _c.telephone_code, name = _c.name, lastname = _c.lastname;
        return (React.createElement(semantic_ui_react_1.Item.Group, { relaxed: true, divided: true, className: 'info-content' },
            React.createElement(semantic_ui_react_1.Item, null,
                React.createElement(semantic_ui_react_1.Popup, { onClose: function () {
                        _this.setState({ data: null });
                    }, onOpen: function () {
                        _this.setState({ data: null });
                        setTimeout(function () {
                            _this.setState({
                                data: {
                                    title: 'Rezglo',
                                    email: [React.createElement(semantic_ui_react_1.Icon, { name: 'mail' }), 'info@rezglo.com'],
                                    phone: [React.createElement(semantic_ui_react_1.Icon, { name: 'phone' }), '(888) 247-0253'],
                                    address: [React.createElement(semantic_ui_react_1.Icon, { name: 'map marker alternate' }), '1500 NW 89th CT Miami, FL 33172'],
                                }
                            });
                        }, 1000);
                    }, popperDependencies: [!!this.state.data], trigger: React.createElement(semantic_ui_react_1.Item.Image, { src: 'src/branding/frontlogo-black.png', style: { marginTop: 16 } }), wide: true, position: isXsResponsive ? 'bottom center' : 'right center' }, this.state.data === null ? (React.createElement(semantic_ui_react_1.Placeholder, { style: { minWidth: '200px' } },
                    React.createElement(semantic_ui_react_1.Placeholder.Header, null,
                        React.createElement(semantic_ui_react_1.Placeholder.Line, null),
                        React.createElement(semantic_ui_react_1.Placeholder.Line, null)),
                    React.createElement(semantic_ui_react_1.Placeholder.Paragraph, null,
                        React.createElement(semantic_ui_react_1.Placeholder.Line, { length: 'medium' }),
                        React.createElement(semantic_ui_react_1.Placeholder.Line, { length: 'short' })))) : (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Header, { style: { marginBottom: 4 }, as: 'h2', content: this.state.data.title, subheader: this.state.data.email }),
                    React.createElement("p", { style: { marginBottom: 4 } }, this.state.data.phone),
                    React.createElement("p", null, this.state.data.address)))),
                React.createElement(semantic_ui_react_1.Item.Content, null,
                    React.createElement(semantic_ui_react_1.Item.Header, { as: 'a' }, (0, lodash_1.get)(companydetails, 'company_name', '')),
                    React.createElement(semantic_ui_react_1.Item.Description, null,
                        React.createElement(semantic_ui_react_1.Icon, { name: 'user' }),
                        loading ? React.createElement(semantic_ui_react_1.Loader, { size: 'mini', active: true, inline: true }) : "".concat(name, " ").concat(lastname)),
                    React.createElement(semantic_ui_react_1.Item.Meta, null,
                        React.createElement(semantic_ui_react_1.Icon, { name: 'mail' }),
                        loading ? React.createElement(semantic_ui_react_1.Loader, { size: 'mini', active: true, inline: true }) : React.createElement("span", { className: 'cinema' }, email)),
                    React.createElement(semantic_ui_react_1.Item.Description, null,
                        React.createElement(semantic_ui_react_1.Icon, { name: 'phone' }),
                        loading ? React.createElement(semantic_ui_react_1.Loader, { size: 'mini', active: true, inline: true }) : "".concat(telephone_code, " ").concat(phone))))));
    };
    CompanyInfo = __decorate([
        decorator_helpers_1.resize,
        mobx_react_1.observer
    ], CompanyInfo);
    return CompanyInfo;
}(React.Component));
exports.default = CompanyInfo;
