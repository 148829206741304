"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
var GoogleMapsModel = /** @class */ (function (_super) {
    __extends(GoogleMapsModel, _super);
    function GoogleMapsModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GoogleMapsModel.prototype.googleMapsModelDistance = function (config) {
        var schema = "\n      query($input: GoogleMapsDistanceInput!){\n        GoogleMapsDistanceGet(query: $input){\n            distanceMeters\n        }\n      }\n    ";
        return this.query(schema, config);
    };
    // Get all places predictions
    GoogleMapsModel.prototype.googleMapsPlaceByText = function (config) {
        var schema = "\n      query($filter: GoogleMapsPlacesByTextListInput!){\n        GoogleMapsPlacesByTextList: PublicGoogleMapsPlacesByTextList(filter: $filter){\n            googleResults {\n              localization {\n                coordinates\n              }\n              types\n              internalDescription\n              googleDescription\n              iataCode\n              gMapPlaceId\n            }\n            localResults {\n              localization {\n                coordinates\n              }\n              types\n              internalDescription\n              googleDescription\n              iataCode\n              gMapPlaceId\n            }\n        }\n      }\n    ";
        return this.onboardingQuery(schema, config);
    };
    // Get only local places predictions
    GoogleMapsModel.prototype.googleMapsPlaceByTextLocal = function (config) {
        var schema = "\n      query($filter: GoogleMapsInternalPlacesByTextListInput!){\n        PublicGoogleMapsInternalPlacesByTextList(filter: $filter){\n            items {\n              localization {\n                coordinates\n              }\n              types\n              internalDescription\n              googleDescription\n              iataCode\n              gMapPlaceId\n            }\n        }\n      }\n    ";
        return this.onboardingQuery(schema, config);
    };
    // Get only google maps api places predictions
    GoogleMapsModel.prototype.googleMapsPlaceByTextApi = function (config) {
        var schema = "\n      query($filter: GoogleMapsPlacesByTextListInput!){\n        GoogleMapsPlacesByTextList: PublicGoogleMapsPlacesByTextList(filter: $filter){\n            items {\n              localization {\n                coordinates\n              }\n              types\n              internalDescription\n              googleDescription\n              iataCode\n              gMapPlaceId\n            }\n        }\n      }\n    ";
        return this.onboardingQuery(schema, config);
    };
    // Get place details
    GoogleMapsModel.prototype.googleMapsPlaceDetails = function (config) {
        var schema = "\n      query($input: GoogleMapsPlaceGetInput!){\n        PublicGoogleMapsPlaceGet(input: $input){\n            responseJson\n            isOld\n        }\n      }\n    ";
        return this.onboardingQuery(schema, config);
    };
    // Get place details
    GoogleMapsModel.prototype.googleMapsGet = function (config) {
        var schema = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      query($query: GoogleMapsListInput!, $token: String){\n        PublicGoogleMapsGet(query: $query, token: $token){\n            response\n        }\n      }\n    "], ["\n      query($query: GoogleMapsListInput!, $token: String){\n        PublicGoogleMapsGet(query: $query, token: $token){\n            response\n        }\n      }\n    "])));
        return this.publicQuery(schema, config);
    };
    /** Get iata code by latitude longitude
     * @param config: input:{coordinates: [latitude, longitude]}
     */
    GoogleMapsModel.prototype.googleMapsIata = function (config) {
        var schema = "\n      query($input: GoogleMapsGeoLocalizationPointInput!, $token: String){\n        PublicGoogleMapsIataCodeByGeoGet(input: $input, token: $token){\n            code\n            iataCode\n            icaoCode\n        }\n      }\n    ";
        return this.publicQuery(schema, config);
    };
    /** Get iata code by latitude longitude
     * @param config: input:{coordinates: [latitude, longitude]}
     */
    GoogleMapsModel.prototype.googleMapsGetPlaceById = function (config) {
        var schema = "\n      query($filter: GoogleMapsPlaceListInput){\n        GoogleMapsPlaceList(filter: $filter){\n            items {\n              localization {\n                coordinates\n              }\n              gMapPlaceId\n              types\n              internalDescription\n              googleDescription\n              iataCode\n            }\n        }\n      }\n    ";
        return this.query(schema, config);
    };
    return GoogleMapsModel;
}(BaseModel_1.BaseModel));
var googleMapsModel = new GoogleMapsModel();
exports.default = googleMapsModel;
var templateObject_1;
