"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var PassengerModel = /** @class */ (function (_super) {
    __extends(PassengerModel, _super);
    function PassengerModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PassengerModel.prototype.store = function (input) {
        var schema = "\n            mutation($input: PassengerRegisterInput!){\n                PassengerRegister(input: $input){\n                _id\n                wallet_amount \n                url\n                }\n            }\n        ";
        return this.mutate(schema, { input: input });
    };
    return PassengerModel;
}(BaseModel_1.BaseModel));
var passengerModel = new PassengerModel();
exports.default = passengerModel;
