"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyDetails = void 0;
var TimeZone_enum_1 = require("../../enums/TimeZone.enum");
var DateTimeFormat_enum_1 = require("../../enums/DateTimeFormat.enum");
var CompanyCommissionSubscription_enum_1 = require("../../enums/CompanyCommissionSubscription.enum");
var CompanyDetails = /** @class */ (function () {
    function CompanyDetails(item) {
        if (item === void 0) { item = {}; }
        var code = item.code, company_status = item.company_status, company_name = item.company_name, company_address = item.company_address, companyLegalName = item.companyLegalName, accountID = item.accountID, numberTaxId = item.numberTaxId, numberDuns = item.numberDuns, companyImageUrl = item.companyImageUrl, companyLogoUrl = item.companyLogoUrl, cityObj = item.cityObj, countryObj = item.countryObj, stateObj = item.stateObj, isBillingContact = item.isBillingContact, billingFirstName = item.billingFirstName, billingLastName = item.billingLastName, billingEmail = item.billingEmail, billingPhoneCode = item.billingPhoneCode, billingPhoneNumber = item.billingPhoneNumber, user_time_zone = item.user_time_zone, date_time_format = item.date_time_format, commission_subscription = item.commission_subscription;
        this.code = code || '';
        this.company_status = company_status || '';
        this.company_name = company_name || '';
        this.company_address = company_address || '';
        this.company_country = countryObj ? countryObj._id : '';
        this.company_state = stateObj ? stateObj.state_id : '';
        this.company_city = cityObj ? cityObj.city_id : '';
        this.companyLegalName = companyLegalName || '';
        this.accountID = accountID || '';
        this.numberTaxId = numberTaxId || '';
        this.numberDuns = numberDuns || '';
        this.companyImageData = companyImageUrl || '';
        this.companyLogoData = companyLogoUrl || '';
        this.cityObj = cityObj || '';
        this.stateObj = stateObj || '';
        this.cityObj = cityObj || '';
        this.companyImageUrl = companyImageUrl || '';
        this.companyLogoUrl = companyLogoUrl || '';
        this.isBillingContact = isBillingContact || false;
        this.billingFirstName = billingFirstName || '';
        this.billingLastName = billingLastName || '';
        this.billingEmail = billingEmail || '';
        this.billingPhoneCode = billingPhoneCode || '+1';
        this.billingPhoneNumber = billingPhoneNumber || '';
        this.user_time_zone = user_time_zone || TimeZone_enum_1.TimeZoneEnum.AMERICA_NEW_YORK;
        this.date_time_format = date_time_format || DateTimeFormat_enum_1.DateTimeFormatEnum.Y_M_D_H_I_S;
        this.commission_subscription = commission_subscription || CompanyCommissionSubscription_enum_1.CompanyCommissionSubscriptionEnum.COMMISSION;
    }
    return CompanyDetails;
}());
exports.CompanyDetails = CompanyDetails;
