"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FranchiseFragment = void 0;
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
exports.FranchiseFragment = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Franchise on FranchiseOutput {\n    _id\n    first_name\n    last_name\n    legal_name\n    email\n    mobile_number\n    address\n    country\n    state\n    city\n    status\n    billing {\n      first_name\n      last_name\n      email\n      mobile_number\n      address\n      country\n      state\n      city\n    }\n    timezone\n  }\n"], ["\n  fragment Franchise on FranchiseOutput {\n    _id\n    first_name\n    last_name\n    legal_name\n    email\n    mobile_number\n    address\n    country\n    state\n    city\n    status\n    billing {\n      first_name\n      last_name\n      email\n      mobile_number\n      address\n      country\n      state\n      city\n    }\n    timezone\n  }\n"])));
var FranchiseModel = /** @class */ (function (_super) {
    __extends(FranchiseModel, _super);
    function FranchiseModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FranchiseModel.prototype.list = function (input) {
        var schema = (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      query($input: FranchiseListInput!) {\n        FranchiseList(input: $input) {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...Franchise\n          }\n        }\n      }\n      ", "\n    "], ["\n      query($input: FranchiseListInput!) {\n        FranchiseList(input: $input) {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...Franchise\n          }\n        }\n      }\n      ", "\n    "])), exports.FranchiseFragment);
        return this.query(schema, { input: input });
    };
    return FranchiseModel;
}(BaseModel_1.BaseModel));
var franchiseModels = new FranchiseModel();
exports.default = franchiseModels;
var templateObject_1, templateObject_2;
