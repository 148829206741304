"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModel = void 0;
require("whatwg-fetch");
var antd_1 = require("antd");
var apollo_boost_1 = require("apollo-boost");
var API = process.env.API;
var REST_API = process.env.REST_API;
var client_id = process.env.client_id;
var client_secret = process.env.client_secret;
var httpLink = new apollo_boost_1.HttpLink({ uri: API + '/landing_page' });
var httpPublicLink = new apollo_boost_1.HttpLink({ uri: API + '/public' });
var openNotificationWithIcon = function (type, message, description) {
    antd_1.notification[type]({
        message: message,
        description: description,
    });
};
var BaseModel = /** @class */ (function () {
    function BaseModel() {
    }
    BaseModel.prototype.login = function (data) {
        var token = data.token, user = data.user;
        if (token) {
            sessionStorage.setItem('jwtDataLPage', JSON.stringify(data));
        }
    };
    BaseModel.prototype.logout = function () {
        sessionStorage.removeItem('jwtDataLPage');
    };
    BaseModel.prototype.getSessionData = function () {
        var jwtDataLPage = sessionStorage.getItem('jwtDataLPage');
        if (jwtDataLPage) {
            return new Promise(function (resolve, reject) {
                resolve(JSON.parse(jwtDataLPage));
            });
        }
        //La variable input se puede cmabiar por REST_API+'/oauth/token'
        return fetch(REST_API + '/oauth/token', {
            credentials: 'same-origin',
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                "grant_type": "client_credentials",
                "client_id": client_id,
                "client_secret": client_secret,
                "scope": "*"
            })
        })
            .then(function (r) {
            var data = r.json();
            return data;
        })
            .then(function (r) {
            if (r && r.access_token) {
                return {
                    token: r.access_token,
                    user: {},
                };
            }
        }).catch(function (c) {
            console.log('error');
        });
    };
    BaseModel.prototype.createClient = function (token) {
        var authLink = new apollo_boost_1.ApolloLink(function (operation, forward) {
            var headers = {};
            if (token) {
                headers = {
                    Authorization: "Bearer ".concat(token),
                };
            }
            operation.setContext({
                headers: headers,
            });
            return forward(operation);
        });
        return new apollo_boost_1.ApolloClient({
            link: authLink.concat(httpLink),
            cache: new apollo_boost_1.InMemoryCache(),
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
            },
        });
    };
    BaseModel.prototype.getClient = function () {
        var _this = this;
        return this.getSessionData().then(function (response) {
            var token = response.token;
            var client;
            if (_this.client) {
                client = _this.client;
            }
            else {
                _this.login({ user: {}, token: token });
                client = _this.createClient(token);
            }
            return Promise.resolve(client);
        });
    };
    BaseModel.prototype.request = function (callback) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getSessionData().then(function (response) {
                var token = response.token;
                var client;
                if (_this.client) {
                    client = _this.client;
                }
                else {
                    _this.login({ user: {}, token: token });
                    client = _this.createClient(token);
                }
                resolve(callback(client));
            });
        });
    };
    BaseModel.prototype.query = function (schema, variables) {
        if (variables === void 0) { variables = undefined; }
        return this.request(function (client) {
            return client.query({
                query: (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          ", "\n        "], ["\n          ", "\n        "])), schema),
                variables: variables,
            });
        });
    };
    BaseModel.prototype.mutate = function (schema, variables) {
        return this.request(function (client) {
            return client.mutate({
                mutation: (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          ", "\n        "], ["\n          ", "\n        "])), schema),
                variables: variables,
            });
        });
    };
    BaseModel.prototype.createPublicClient = function () {
        return new apollo_boost_1.ApolloClient({
            link: httpPublicLink,
            cache: new apollo_boost_1.InMemoryCache(),
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
            },
        });
    };
    BaseModel.prototype.publicRequest = function (callback) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var client = _this.createPublicClient();
            resolve(callback(client));
        });
    };
    BaseModel.prototype.publiQuery = function (schema, variables) {
        if (variables === void 0) { variables = undefined; }
        return this.publicRequest(function (client) {
            return client.query({
                query: (0, apollo_boost_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          ", "\n        "], ["\n          ", "\n        "])), schema),
                variables: variables,
            });
        });
    };
    return BaseModel;
}());
exports.BaseModel = BaseModel;
var templateObject_1, templateObject_2, templateObject_3;
