"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FleetFragment = void 0;
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
exports.FleetFragment = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Fleet on FleetOutput {\n    _id\n    key: _id\n    franchise {\n      _id\n      legal_name\n    }\n    is_nemt\n    taxi_no\n    taxi_status\n    taxi_company\n    has_assignation\n    company {\n      accountID\n      company_name\n      company_address\n      companyLogoUrl\n    }\n    companyContactId\n    model {\n      _id\n      model_name\n    }\n    driver {\n      _id\n      name\n      email\n      phone\n    }\n    __typename\n    active_assignation {\n      id\n      is_used_for_credit\n    }\n  }\n"], ["\n  fragment Fleet on FleetOutput {\n    _id\n    key: _id\n    franchise {\n      _id\n      legal_name\n    }\n    is_nemt\n    taxi_no\n    taxi_status\n    taxi_company\n    has_assignation\n    company {\n      accountID\n      company_name\n      company_address\n      companyLogoUrl\n    }\n    companyContactId\n    model {\n      _id\n      model_name\n    }\n    driver {\n      _id\n      name\n      email\n      phone\n    }\n    __typename\n    active_assignation {\n      id\n      is_used_for_credit\n    }\n  }\n"])));
var FleetModel = /** @class */ (function (_super) {
    __extends(FleetModel, _super);
    function FleetModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FleetModel.prototype.jobsCarriedOutList = function (config) {
        var schema = "\n    query($filter: FleetJobsCarriedOutListInput){\n      FleetJobsCarriedOutList(filter: $filter){\n        pageNumber\n        pageSize\n        count\n        items{\n          _id\n          driverLogo\n          driverName\n          pickup_location\n          drop_location\n        }\n      }\n    }";
        return this.query(schema, config);
    };
    FleetModel.prototype.export = function (format, filter) {
        var schema = "\n      query($format: CoreDocumentFormatEnum!, $filter:  SearchCompanyInput!){\n        CompanyListExport(format: $format, filter: $filter){\n        url\n        }\n      }\n    ";
        return this.query(schema, { format: format, filter: filter });
    };
    FleetModel.prototype.list = function (filter, franchiseIds) {
        var schema = (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      query($filter: FleetListInput, $franchiseIds: [Int]) {\n        FleetList: PublicFleetList(filter: $filter, franchiseIds: $franchiseIds) {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...Fleet\n          }\n          __typename\n        }\n      }\n      ", "\n    "], ["\n      query($filter: FleetListInput, $franchiseIds: [Int]) {\n        FleetList: PublicFleetList(filter: $filter, franchiseIds: $franchiseIds) {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...Fleet\n          }\n          __typename\n        }\n      }\n      ", "\n    "])), exports.FleetFragment);
        return this.onboardingQuery(schema, { filter: filter, franchiseIds: franchiseIds });
    };
    FleetModel.prototype.get = function (input) {
        var schema = "\n            query($input:JsonParser){\n              PublicFleetGet(input: $input){\n                _id\n                is_nemt\n                max_luggage\n                taxi_city\n                taxi_colour\n                taxi_company\n                taxi_country\n                taxi_image\n                taxi_insurance_expire_date_time\n                taxi_insurance_number\n                taxi_manufacturer\n                taxi_min_speed\n                taxi_model\n                taxi_motor_expire_date\n                taxi_no\n                taxi_owner_name\n                taxi_status\n                taxi_pco_licence_expire_date\n                taxi_pco_licence_number\n                taxi_state\n                wheelchair_lift_ramp\n                allow_transport_animals\n                amenities_default\n                amenities_default_nemt\n                franchise{\n                 _id\n                 legal_name\n                }\n              }\n            }\n    ";
        return this.onboardingQuery(schema, { input: input });
    };
    FleetModel.prototype.updateBulk = function (input) {
        var schema = "\n        mutation ($input: BulkUpdateInput!){\n          UpdateFleetStatusBulk: PublicUpdateFleetStatusBulk(input: $input)\n        }\n      ";
        return this.onboardingMutation(schema, { input: input });
    };
    FleetModel.prototype.create = function (input) {
        var schema = "\n        mutation ($input: FleetInput!){\n            CreateFleet: PublicCreateFleet(input: $input){\n                _id\n            }\n        }\n    ";
        return this.onboardingMutation(schema, { input: input });
    };
    FleetModel.prototype.update = function (input) {
        var schema = "\n        mutation ($input: FleetInput){\n            UpdateFleet: PublicUpdateFleet(input: $input){\n                _id\n            }\n        }\n    ";
        return this.onboardingMutation(schema, { input: input });
    };
    return FleetModel;
}(BaseModel_1.BaseModel));
var fleetModel = new FleetModel();
exports.default = fleetModel;
var templateObject_1, templateObject_2;
