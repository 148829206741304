"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatusEnum = void 0;
var UserStatusEnum;
(function (UserStatusEnum) {
    UserStatusEnum["ACTIVE"] = "ACTIVE";
    UserStatusEnum["BLOCK"] = "BLOCK";
    UserStatusEnum["TRASH"] = "TRASH";
    UserStatusEnum["TMP"] = "TMP";
    UserStatusEnum["PENDING_APPROVAL"] = "PENDING_APPROVAL";
})(UserStatusEnum = exports.UserStatusEnum || (exports.UserStatusEnum = {}));
