"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenseFragment = void 0;
var BaseModel_1 = require("./BaseModel");
var apollo_boost_1 = require("apollo-boost");
var message_helpers_1 = require("../helpers/message.helpers");
var Stripe_enum_1 = require("../enums/Stripe.enum");
exports.LicenseFragment = (0, apollo_boost_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment License on LicenseOutput {\n    _id\n    product_id\n    name\n    description\n    metadata\n    active\n    price {\n      _id\n      price_id\n      unit_amount\n      active\n      billing_scheme\n      currency\n      recurring {\n        interval\n        interval_count\n        usage_type\n      }\n    }\n  }\n"], ["\n  fragment License on LicenseOutput {\n    _id\n    product_id\n    name\n    description\n    metadata\n    active\n    price {\n      _id\n      price_id\n      unit_amount\n      active\n      billing_scheme\n      currency\n      recurring {\n        interval\n        interval_count\n        usage_type\n      }\n    }\n  }\n"])));
var LicenseModel = /** @class */ (function (_super) {
    __extends(LicenseModel, _super);
    function LicenseModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LicenseModel.prototype.list = function (filter) {
        var schema = (0, apollo_boost_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      query($filter: LicenseListInput!) {\n        LicenseList(filter: $filter) {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...License\n          }\n        }\n      }\n      ", "\n    "], ["\n      query($filter: LicenseListInput!) {\n        LicenseList(filter: $filter) {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...License\n          }\n        }\n      }\n      ", "\n    "])), exports.LicenseFragment);
        return this.onboardingQuery(schema, { filter: filter });
    };
    LicenseModel.prototype.listAll = function () {
        var schema = (0, apollo_boost_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      query {\n        LicenseList {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...License\n          }\n        }\n      }\n      ", "\n    "], ["\n      query {\n        LicenseList {\n          pageNumber\n          pageSize\n          count\n          items {\n            ...License\n          }\n        }\n      }\n      ", "\n    "])), exports.LicenseFragment);
        return this.onboardingQuery(schema);
    };
    LicenseModel.prototype.assignLicenseToDriver = function (_a) {
        var user_id = _a.userId, id = _a.assignationId, _b = _a.type, type = _b === void 0 ? Stripe_enum_1.LicenseToDriverEnum.ASSIGNATIONS : _b;
        var schema = "\n            mutation ($user_id: Int, $id: Int!, $type: SubscriptionCreditTypeEnum! ){\n              CreateSubscriptionCredit(user_id: $user_id, id: $id, type: $type )\n            }\n        ";
        return this.onboardingMutation(schema, { user_id: user_id, id: id, type: type });
    };
    LicenseModel.prototype.unassignLicenseToDriver = function (_a) {
        var user_id = _a.userId, id = _a.assignationId, _b = _a.type, type = _b === void 0 ? Stripe_enum_1.LicenseToDriverEnum.ASSIGNATIONS : _b;
        var schema = "\n            mutation ($user_id: Int, $id: Int!, $type: SubscriptionCreditTypeEnum! ){\n              DeleteSubscriptionCredit(user_id: $user_id, id: $id, type: $type )\n            }\n        ";
        return this.onboardingMutation(schema, { user_id: user_id, id: id, type: type });
    };
    LicenseModel.prototype.availabilityOfDriverLicenses = function (userId) {
        var schema = (0, apollo_boost_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      query ($user_id: Int) {\n        SubscriptionCreditStatus (user_id: $user_id){\n          quantity_subscribed\n          credits {\n            name\n            used\n            available\n            exists\n          }\n        }\n      }\n    "], ["\n      query ($user_id: Int) {\n        SubscriptionCreditStatus (user_id: $user_id){\n          quantity_subscribed\n          credits {\n            name\n            used\n            available\n            exists\n          }\n        }\n      }\n    "])));
        return this.onboardingQuery(schema, { user_id: userId });
    };
    LicenseModel.prototype.createLicense = function (input) {
        //todo
        (0, message_helpers_1.openNotificationWithIcon)('info', 'Coming soon', '...');
    };
    LicenseModel.prototype.updateLicense = function (input) {
        var schema = "\n        mutation ($input: LicenseUpdateInput){\n          LicenseUpdate(input: $input){\n            _id\n          }\n        }\n    ";
        return this.onboardingMutation(schema, { input: input });
    };
    LicenseModel.prototype.export = function (format, input) {
        //todo
        (0, message_helpers_1.openNotificationWithIcon)('info', 'Coming soon', '...');
    };
    LicenseModel.prototype.updateBulk = function (format) {
        //todo
        (0, message_helpers_1.openNotificationWithIcon)('info', 'Coming soon', '...');
    };
    return LicenseModel;
}(BaseModel_1.BaseModel));
var licenseModel = new LicenseModel();
exports.default = licenseModel;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
