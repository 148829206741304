"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Route = void 0;
var RoutePoints_enum_1 = require("../../enums/RoutePoints.enum");
var lodash_1 = require("lodash");
var Route = /** @class */ (function () {
    function Route(item) {
        if (item === void 0) { item = {}; }
        var _id = item._id, name = item.name, location = item.location, placeId = item.placeId, _a = item.coordinate, coordinate = _a === void 0 ? [] : _a, status = item.status, isAirport = item.isAirport, iata = item.iata, cityName = item.cityName, cityCode = item.cityCode, postalCode = item.postalCode, franchise = item.franchise;
        this._id = _id || undefined;
        this.name = name || '';
        this.location = location || '';
        this.placeId = placeId || '';
        this.coordinate = coordinate || '';
        this.status = status || RoutePoints_enum_1.RoutePoinstStatus.active;
        this.isAirport = isAirport || false;
        this.iata = iata || '';
        this.cityName = cityName || '';
        this.cityCode = cityCode || '';
        this.postalCode = postalCode || '';
        this.franchise_id = (0, lodash_1.get)(franchise, '_id', '');
        this.company_id = '';
    }
    return Route;
}());
exports.Route = Route;
