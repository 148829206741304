"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FareCategory = exports.FaresTypes = void 0;
var FaresTypes;
(function (FaresTypes) {
    FaresTypes["PER_MILES_KM"] = "PER_MILES";
    FaresTypes["FIXED"] = "FIXED_FARE";
    FaresTypes["PER_HOURS"] = "PER_HOURS";
})(FaresTypes = exports.FaresTypes || (exports.FaresTypes = {}));
var FareCategory;
(function (FareCategory) {
    FareCategory["FARE_PER_TRIP"] = "farePerTrip";
    FareCategory["FARE_PER_PASSENGER"] = "farePerPassenger";
})(FareCategory = exports.FareCategory || (exports.FareCategory = {}));
