"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormatTypes = exports.SortTypes = void 0;
var SortTypes;
(function (SortTypes) {
    SortTypes["ASC"] = "ASC";
    SortTypes["DESC"] = "DESC";
})(SortTypes = exports.SortTypes || (exports.SortTypes = {}));
var FormatTypes;
(function (FormatTypes) {
    FormatTypes["PDF"] = "PDF";
    FormatTypes["XLS"] = "EXCEL";
})(FormatTypes = exports.FormatTypes || (exports.FormatTypes = {}));
