"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerMilesKmFare = void 0;
var PerMilesKmFare = /** @class */ (function () {
    function PerMilesKmFare(item) {
        if (item === void 0) { item = {}; }
        var fareId = item.fareId, _id = item._id, modelId = item.modelId, base_fare = item.base_fare, min_km = item.min_km, min_fare = item.min_fare, cancellation_fare = item.cancellation_fare, km_wise_fare = item.km_wise_fare, additional_fare_per_km = item.additional_fare_per_km, below_above_km = item.below_above_km, below_km = item.below_km, above_km = item.above_km, waiting_time = item.waiting_time, minutes_fare = item.minutes_fare, taxi_speed = item.taxi_speed, taxi_min_speed = item.taxi_min_speed, night_charge = item.night_charge, night_timing_from = item.night_timing_from, night_timing_to = item.night_timing_to, night_fare = item.night_fare, evening_charge = item.evening_charge, evening_timing_from = item.evening_timing_from, evening_timing_to = item.evening_timing_to, evening_fare = item.evening_fare, franchise = item.franchise;
        this.fareId = fareId || undefined;
        this._id = _id || undefined;
        this.modelId = modelId || undefined;
        this.base_fare = base_fare;
        this.min_km = min_km;
        this.min_fare = min_fare;
        this.cancellation_fare = cancellation_fare;
        this.km_wise_fare = km_wise_fare;
        this.additional_fare_per_km = additional_fare_per_km;
        this.below_above_km = below_above_km;
        this.below_km = below_km;
        this.above_km = above_km;
        this.waiting_time = waiting_time;
        this.minutes_fare = minutes_fare;
        this.taxi_speed = taxi_speed;
        this.taxi_min_speed = taxi_min_speed;
        this.night_charge = night_charge;
        this.night_timing_from = night_timing_from;
        this.night_timing_to = night_timing_to;
        this.night_fare = night_fare || '';
        this.evening_charge = evening_charge;
        this.evening_timing_from = evening_timing_from;
        this.evening_timing_to = evening_timing_to;
        this.evening_fare = evening_fare || '';
        this.franchise = franchise;
    }
    return PerMilesKmFare;
}());
exports.PerMilesKmFare = PerMilesKmFare;
