"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseModel_1 = require("./BaseModel");
var BookRideModel = /** @class */ (function (_super) {
    __extends(BookRideModel, _super);
    function BookRideModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BookRideModel.prototype.availabilityList = function (filter) {
        var schema = "\n      query($filter: AvailabilitiesListInput!){\n        AvailabilityList(filter: $filter){\n          pageNumber\n          pageSize\n          count\n          items {\n            _id\n            searchId\n            companyName\n            companyLogo\n            fleetId\n            luggage\n            animalsNumber\n            childrenCategory1\n            childrenCategory2\n            childrenCategory3\n            companyNameType\n            modelImage\n            notesPickup\n            notesRoundTrip\n            approx_fare\n            description\n            peopleNumber\n            maxAmountSuitcases\n            modelSize\n            companyTax\n            approx_duration_sec\n            approx_distance\n            driverName\n            modelName\n            fleetNo\n            fleetSpeed\n            pickup_time\n            company_id\n            modelId\n            currentLatitude\n            currentLongitude\n            driverRating\n            driverStatus\n            isByPerson\n            wheelchairLiftRampAccess\n            meetAndGreet\n            rideTracking\n            roundTripFare\n            minQuantityPersons\n            maxQuantityPersons\n            fleetPickupDistance\n            maxLuggagePerPassenger\n          }\n          companies {\n            _id\n            companydetails {\n              company_name\n            }\n          }\n        }\n      }\n        ";
        return this.query(schema, { filter: filter });
    };
    return BookRideModel;
}(BaseModel_1.BaseModel));
var bookRideModel = new BookRideModel();
exports.default = bookRideModel;
