"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsive = void 0;
var react_1 = require("react");
var useResponsive = function () {
    var _a = (0, react_1.useState)(window.innerWidth), width = _a[0], setWidth = _a[1];
    var _b = (0, react_1.useState)(window.innerHeight), height = _b[0], setHeight = _b[1];
    (0, react_1.useEffect)(function () {
        window.addEventListener('resize', function () {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        });
    });
    return {
        width: width,
        height: height,
        isIphone: width < 321,
        isResponsive: width < 915,
        isMobile: width > 0 && width < 768,
        tablet: width > 768 && width < 1024,
        xxl: width >= 1600,
        xl: width >= 1200,
        lg: width >= 992,
        md: width >= 768,
        sm: width >= 576,
        xs: width < 576,
        computer: width > 1024
    };
};
exports.useResponsive = useResponsive;
