"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyInfo = void 0;
var DefaultUnitType_enum_1 = require("../../enums/DefaultUnitType.enum");
var FareCalculationType_enum_1 = require("../../enums/FareCalculationType.enum");
var TaxSettingStatusType_enum_1 = require("../../enums/TaxSettingStatusType.enum");
var CompanyInfo = /** @class */ (function () {
    function CompanyInfo(item) {
        if (item === void 0) { item = {}; }
        var default_unit = item.default_unit, fare_calculation_type = item.fare_calculation_type, cancellation_fee = item.cancellation_fee, tax_setting = item.tax_setting, company_tax = item.company_tax;
        this.default_unit = default_unit || DefaultUnitType_enum_1.DefaultUnitTypeEnum.MILES;
        this.fare_calculation_type = fare_calculation_type || FareCalculationType_enum_1.FareCalculationTypeEnum.DISTANCE_TIME;
        this.cancellation_fee = cancellation_fee || 0;
        this.tax_setting = tax_setting || TaxSettingStatusType_enum_1.TaxSettingStatusEnum.DISABLE;
        this.company_tax = company_tax;
    }
    return CompanyInfo;
}());
exports.CompanyInfo = CompanyInfo;
