"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleMapsPlaces = void 0;
var GoogleMapsPlaces = /** @class */ (function () {
    function GoogleMapsPlaces(item) {
        if (item === void 0) { item = {}; }
        var localization = item.localization, iataCode = item.iataCode, gMapPlaceId = item.gMapPlaceId, types = item.types, internalDescription = item.internalDescription, googleDescription = item.googleDescription, secondaryNames = item.secondaryNames;
        this.localization = localization ? { coordinates: localization.coordinates } : localization;
        this.iataCode = iataCode;
        this.gMapPlaceId = gMapPlaceId;
        this.types = types || null;
        this.internalDescription = internalDescription;
        this.googleDescription = googleDescription;
        this.secondaryNames = secondaryNames || [];
    }
    return GoogleMapsPlaces;
}());
exports.GoogleMapsPlaces = GoogleMapsPlaces;
