"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var React = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var styles = require('./UploadImage.scss');
var UploadFile = /** @class */ (function (_super) {
    __extends(UploadFile, _super);
    function UploadFile(props) {
        var _this = _super.call(this, props) || this;
        _this.normFile = function (e) {
            if (Array.isArray(e)) {
                return e;
            }
            return e && e.fileList;
        };
        _this.beforeUpload = function (file, list) {
            var me = _this;
            var t = _this.props.t;
            var validFile = false;
            switch (file.type) {
                case 'image/png':
                case 'image/jpeg':
                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/pdf': {
                    validFile = true;
                    me.setState({
                        extension: file.name
                            .toString()
                            .split('.')
                            .pop(),
                        imgType: file.type,
                    });
                    break;
                }
                default: {
                    antd_1.message.error(t
                        ? t('manageDrivers.You can only upload DOC, DOCX, PDF, JPG, JPEG or PNG files only')
                        : 'You can only upload DOC, DOCX, PDF, JPG, JPEG or PNG files only!');
                }
            }
            var isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                antd_1.message.error(t ? t('manage_fleets.Image must smaller than 2MB') : 'Image must smaller than 2MB!');
            }
            return validFile && isLt2M;
        };
        _this.handleChange = function (info) {
            var me = _this;
            if (info.file.status === 'uploading') {
                me.setState({ loading: true });
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                me.getBase64(info.file.originFileObj, function (imageUrl) {
                    //console.log('DONE: ', info.file);
                    me.setState({ name: info.file.name });
                    me.props.handleUpdatePicture(String(imageUrl), String(info.file.name));
                    me.setState({
                        loading: false,
                    });
                });
            }
        };
        _this.validateRequire = function (rule, value, callback) {
            setTimeout(function () {
                if (_this.props.isRequired &&
                    !(_this.props.form.getFieldValue("upload_".concat(_this.props.propertyName)) ||
                        _this.props.form.getFieldValue(_this.props.propertyName))) {
                    callback([new Error(_this.props.t
                            ? _this.props.t('manage_fleets.Please select any jpg, jpeg or png picture')
                            : 'Please select any jpg, jpeg or png picture!')]);
                }
                else {
                    callback();
                }
            }, 2000);
        };
        _this.onOkFileInfo = function () {
            _this.setState({ show: false });
        };
        _this.onClickReference = function () {
            _this.setState({ show: true });
        };
        _this.state = {
            loading: false,
            imageUrl: '',
            extension: '',
            show: false,
            imgType: '',
            name: '',
        };
        return _this;
    }
    UploadFile.prototype.getBase64 = function (img, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };
    UploadFile.prototype.render = function () {
        var me = this;
        var FormItem = antd_1.Form.Item;
        var formItemLayoutUpload = me.props.formItemLayout || {
            labelCol: { span: 16 },
            wrapperCol: { span: 8 },
        };
        var _a = me.state, loading = _a.loading, extension = _a.extension, show = _a.show, imgType = _a.imgType, name = _a.name;
        var _b = me.props, form = _b.form, image = _b.image, isRequired = _b.isRequired, extra = _b.extra, label = _b.label, propertyName = _b.propertyName;
        var getFieldDecorator = form.getFieldDecorator;
        var t = this.props.t;
        var uploadButton = (React.createElement("div", null,
            React.createElement(antd_1.Icon, { type: loading ? 'loading' : 'upload' }),
            t ? t('manageDrivers.Choose File') : 'Choose File'));
        if (!extension && image) {
            setTimeout(function () {
                me.setState({
                    extension: image
                        .toString()
                        .split('.')
                        .pop(),
                    name: image
                        .toString()
                        .split('/')
                        .pop(),
                });
            }, 300);
        }
        return (React.createElement("div", { className: styles[isRequired ? 'upload-avatar' : ''] },
            React.createElement(FormItem, null, getFieldDecorator(propertyName, {
                initialValue: image,
            })(React.createElement(antd_1.Input, { type: "hidden" }))),
            React.createElement(FormItem, __assign({}, formItemLayoutUpload, { className: 'onb-upload-file', label: !isRequired ? label : (React.createElement("label", { className: 'ant-form-item-required' }, label)), extra: extra }),
                getFieldDecorator("upload_".concat(propertyName), {
                    getValueFromEvent: me.normFile,
                    valuePropName: 'fileList',
                    rules: [
                        {
                            validator: me.validateRequire,
                        },
                    ],
                })(React.createElement(antd_1.Upload, { name: propertyName, listType: "picture", beforeUpload: me.beforeUpload, onChange: me.handleChange, showUploadList: false },
                    React.createElement(antd_1.Button, null, uploadButton))),
                React.createElement("div", { style: { marginLeft: '10px', display: 'inline-block' } }, image ? (React.createElement("label", { style: { color: '#1890ff', cursor: 'pointer' }, onClick: me.onClickReference }, name ? name : t ? "\"".concat(t('File'), "\".").concat(extension) : "File.".concat(extension))) : t ? (t('manageDrivers.No file Chosen')) : ('No file Chosen')),
                React.createElement(antd_1.Modal, { title: t ? t('manageDrivers.File info') : 'File info', 
                    //bodyStyle={{width:"100%!important",height:"400px"}}
                    className: 'upload-file', visible: show, closable: false, width: "80%", 
                    //onOk={me.onOkFileInfo}
                    footer: [
                        React.createElement(antd_1.Button, { type: "primary", key: 'okButton', onClick: me.onOkFileInfo }, "OK"),
                    ] },
                    React.createElement("embed", { width: "100%", height: "100%", src: image, type: imgType })))));
    };
    return UploadFile;
}(React.Component));
exports.default = (0, react_i18next_1.withNamespaces)('pages')(UploadFile);
